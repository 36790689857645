<template>
  <div class="main">
    <PageComponent :title_nav="'Usuários'">
      <div slot="slot-pages" class="content-pages">
        <div class="users">
          <div class="users-header">
            <!-- breadcrumb -->
            <b-breadcrumb>
              <b-breadcrumb-item :to="{ name: 'users' }">
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                Listagem de usuários
              </b-breadcrumb-item>
              <b-breadcrumb-item active>Criar novo usuário</b-breadcrumb-item>
            </b-breadcrumb>
            <!-- breadcrumb -->
            <b-row>
              <b-col md="6">
                <div class="title-page">
                  <h2>Criar novo usuário</h2>
                  <p>
                    Preencha o formulário abaixo com as informações do novo usuário:
                  </p>
                </div>
              </b-col>
              <b-col md="6" class="buttons">
                <div class="buttonss">
                  <b-button class="btn-save button-padrao-add" @click="updateUser(dataObj.user.id)" v-if="dataObj.user.id"> Atualizar </b-button>
                  <b-button class="btn-save button-padrao-add" @click="saveUser()" v-else> Salvar </b-button>

                  <b-button class="btn-cancel button-padrao-cancel" :to="{name: 'users'}"> Cancelar </b-button>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <!-- coluna de formulario -->
              <b-col class="col-form">
                <b-form-input
                  v-model="dataObj.user.name"
                  placeholder="Nome Completo"
                ></b-form-input>

                <b-form-input
                  v-model="dataObj.user.email"
                  placeholder="E-mail"
                ></b-form-input>

                <b-form-input
                  v-model="dataObj.user.role"
                  placeholder="Função/Cargo"
                ></b-form-input>

                <b-form-input
                  v-model="dataObj.user.phone"
                  placeholder="Telefone"
                  v-mask="['(##) #####-####', '(##) ####-####']"
                ></b-form-input>

                <b-form-input
                  v-model="dataObj.user.password"
                  placeholder="Senha provisória"
                  type="password"
                ></b-form-input>

                <p>Duração da senha</p>
                <b-form-input
                  class="duration_password"
                  readonly
                  v-model="parameters.time_password"
                ></b-form-input>
                <p>Formato de senha</p>
                <b-form-input
                  class="format_password"
                  v-model="dataObj.user.format_password"
                  readonly
                ></b-form-input>

                <b-form-checkbox
                  id="checkbox-1"
                  v-model="status"
                  name="checkbox-1"
                  value="accepted"
                  unchecked-value="not_accepted"
                >
                  O usuário deve alterar a senha no próximo login
                </b-form-checkbox>
              </b-col>

              <!-- coluna de permissões -->
              <b-col class="col-permission">
                <b-card>
                  <b-form-checkbox
                    id="checkbox-1"
                    v-model="dataObj.user.is_active"
                    name="checkbox-1"
                    value="1"
                    unchecked-value="0"
                    class="checkactive"
                  >
                    Usuário {{ dataObj.user.is_active == 1 ? 'Ativo' : 'Inativo' }}
                  </b-form-checkbox>

                  <h5 class="permissions"><strong> Permissões: </strong></h5>

                  <p><label class="title">Inicio</label> 
                    <label for="v_inicio" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_inicio" v-model="selected" :value="'1'" @change="verificarCheckbox(1)">
                  </label>
                  </p>
                  
                  <p><label class="title">S&OP - Quantidade</label>
                    <label for="v_seop_qtde" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_seop_qtde" v-model="selected" :value="'2'" @change="verificarCheckbox(2)">
                    </label>
                  </p>
                  
                  <p><label class="title">S&OP - Custos</label>
                    <label for="v_seop_cost" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_seop_cost" v-model="selected" :value="'3'" @change="verificarCheckbox(3)">
                    </label>
                  </p>

                  <p><label class="title">S&OP - Configurações</label>
                    <label for="v_seop_confi" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_seop_confi" v-model="selected" :value="'4'" @change="verificarCheckbox(4)">
                    </label>
                    <label for="m_seop_confi" class="checks">
                      Modificar <input type="checkbox" name="" id="m_seop_confi" v-model="selected" :value="'5'" @change="verificarCheckbox(5)">
                    </label>
                  </p>

                  <p><label class="title">Budget e Forecast</label>
                    <label for="v_budget" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_budget" v-model="selected" :value="'6'" @change="verificarCheckbox(6)">
                    </label>
                    <label for="m_budget" class="checks">
                      Modificar <input type="checkbox" name="" id="m_budget" v-model="selected" :value="'7'" @change="verificarCheckbox(7)">
                    </label>
                  </p>

                  <p><label class="title">Parametros</label>
                    <label for="v_parameters" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_parameters" v-model="selected" :value="'8'" @change="verificarCheckbox(8)">
                    </label>
                    <label for="m_parameters" class="checks">
                      Modificar <input type="checkbox" name="" id="m_parameters" v-model="selected" :value="'9'" @change="verificarCheckbox(9)">
                    </label>
                  </p>

                  <p><label class="title">Cadastro Usuários</label>
                    <label for="v_users" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_users" v-model="selected" :value="'10'" @change="verificarCheckbox(10)">
                    </label>
                    <label for="m_users" class="checks">
                      Modificar <input type="checkbox" name="" id="m_users" v-model="selected" :value="'11'" @change="verificarCheckbox(11)">
                    </label>
                  </p>

                  <p><label class="title">Cadastro de Depósito</label>
                    <label for="v_deposit" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_deposit" v-model="selected" :value="'12'" @change="verificarCheckbox(12)">
                    </label>
                    <label for="m_deposit" class="checks">
                      Modificar <input type="checkbox" name="" id="m_deposit" v-model="selected" :value="'13'" @change="verificarCheckbox(13)">
                    </label>
                  </p>

                  <p><label class="title">Agrupador de Estoque</label>
                    <label for="v_stock_grouper" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_stock_grouper" v-model="selected" :value="'14'" @change="verificarCheckbox(14)">
                    </label>
                    <label for="m_stock_grouper" class="checks">
                      MOdificar <input type="checkbox" name="" id="m_stock_grouper" v-model="selected" :value="'15'" @change="verificarCheckbox(15)">
                    </label>
                  </p>

                  <p><label class="title">Produto Agrupador</label>
                    <label for="v_product_grouper" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_product_grouper" v-model="selected" :value="'16'" @change="verificarCheckbox(16)">
                    </label>
                    <label for="m_product_grouper" class="checks">
                      Modificar <input type="checkbox" name="" id="m_product_grouper" v-model="selected" :value="'17'" @change="verificarCheckbox(17)">
                    </label>
                  </p>

                  <p><label class="title">Etapas Lead Time</label>
                    <label for="v_leadTime" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_leadTime" v-model="selected" :value="'18'" @change="verificarCheckbox(18)">
                    </label>
                    <label for="m_leadTime" class="checks">
                      Modificar <input type="checkbox" name="" id="m_leadTime" v-model="selected" :value="'19'" @change="verificarCheckbox(19)">
                    </label>
                  </p>

                  <p><label class="title">Fator</label>
                    <label for="v_factor" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_factor" v-model="selected" :value="'20'" @change="verificarCheckbox(20)">
                    </label>
                    <label for="m_factor" class="checks">
                      Modificar <input type="checkbox" name="" id="m_factor" v-model="selected" :value="'21'" @change="verificarCheckbox(21)">
                    </label>
                  </p>

                  <p><label class="title">Dólar</label>
                    <label for="v_dolar" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_dolar" v-model="selected" :value="'22'" @change="verificarCheckbox(22)">
                    </label>
                    <label for="m_dolar" class="checks">
                      Modificar <input type="checkbox" name="" id="m_dolar" v-model="selected" :value="'23'" @change="verificarCheckbox(23)">
                    </label>
                  </p>

                  <p><label class="title">Integração - Produtos</label>
                    <label for="v_sync_produtos" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_sync_produtos" v-model="selected" :value="'24'" @change="verificarCheckbox(24)">
                    </label>
                    <label for="m_sync_produtos" class="checks">
                      Modificar <input type="checkbox" name="" id="m_sync_produtos" v-model="selected" :value="'25'" @change="verificarCheckbox(25)">
                    </label>
                  </p>

                  <p><label class="title">Integração - Fornecedores</label>
                    <label for="v_sync_supplier" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_sync_supplier" v-model="selected" :value="'26'" @change="verificarCheckbox(26)">
                    </label>
                    <label for="m_sync_supplier" class="checks">
                      Modificar <input type="checkbox" name="" id="m_sync_supplier" v-model="selected" :value="'27'" @change="verificarCheckbox(27)">
                    </label>
                  </p>

                  <p><label class="title">Integração - Programação</label>
                    <label for="v_sync_programming" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_sync_programming" v-model="selected" :value="'28'" @change="verificarCheckbox(28)">
                    </label>
                    <label for="m_sync_programming" class="checks">
                      Modificar <input type="checkbox" name="" id="m_sync_programming" v-model="selected" :value="'29'" @change="verificarCheckbox(29)">
                    </label>
                  </p>

                  <p><label class="title">Integração - Processos em lote</label>
                    <label for="v_sync_process_lote" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_sync_process_lote" v-model="selected" :value="'30'" @change="verificarCheckbox(30)">
                    </label>
                    <label for="m_sync_process_lote" class="checks">
                      Modificar <input type="checkbox" name="" id="m_sync_process_lote" v-model="selected" :value="'31'" @change="verificarCheckbox(31)">
                    </label>
                  </p>

                  <p><label class="title">Cenários e Simulações</label>
                    <label for="v_scenario_simulation" class="checks">
                      Visualizar <input type="checkbox" name="" id="v_scenario_simulation" v-model="selected" :value="'32'" @change="verificarCheckbox(32)">
                    </label>
                    <label for="m_scenario_simulation" class="checks">
                      Modificar <input type="checkbox" name="" id="m_scenario_simulation" v-model="selected" :value="'33'" @change="verificarCheckbox(33)">
                    </label>
                  </p>
                 </b-card>


              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </PageComponent>
  </div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
  name: "UserComponent",
  components: {
    PageComponent,
  },
  data() {
    return {
      status: "accepted",
      permission_user: ["all", "user_create", "stock_edit"],
      dataObj: {
        user: {
          id: null,
          name: "",
          email: "",
          phone: "",
          role: "",
          password: "",
          duration_password: "90 dias",
          format_password: "8 caracteres",
          is_active: 0
        },
      },

      obj: this.$route.params.dataObj,
      parameters: {
        id: "",
        time_password: ""
      },
      permissions: [],
      selected: [], // Must be an array reference!
    };
  },

  mounted() {
    this.getParametersSystem();
    this.getPermissions();
    this.editUser();
  },

  methods: {
    verificarCheckbox(item) {
      if (this.selected.includes(item)) {
        console.log(`Checkbox marcado: ${item}`);
      } else {
        this.removePermission(item);
      }
    },

    async removePermission(permission_id) {
      const formData = new FormData();
      formData.append("permission", permission_id);

      await this.$http
          .post(`/user/permission/delete`, formData)
          .then((response) => {
            if (response.data.error) {
              this.goError(response.data.message);
            } 
          })
          .catch((error) => {
            this.goError(error.response.data.message);
            console.log(error.response.data.message);
          });
    },

    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },

    async saveUser() {
      if (
        this.dataObj.user.name == "" ||
        this.dataObj.user.email == "" ||
        this.dataObj.user.role == "" ||
        this.dataObj.user.phone == "" ||
        this.dataObj.user.password == ""
      ) {
        this.goError("Preencha todos os campos");
      }
      if(this.dataObj.user.password.length < 8){
        this.goError("Senha deve conter 8 caracteres");
      } else {
        const formData = new FormData();
        formData.append("name", this.dataObj.user.name);
        formData.append("email", this.dataObj.user.email);
        formData.append("role", this.dataObj.user.role);
        formData.append("phone", this.dataObj.user.phone);
        formData.append("password", this.dataObj.user.password);
        formData.append("is_active", this.dataObj.user.is_active);

        formData.append(
          "duration_password",
          this.dataObj.user.duration_password
        );
        formData.append("format_password", this.dataObj.user.format_password);

        await this.$http
          .post(`/user/store`, formData)
          .then((response) => {
            if (response.data.error) {
              this.goError(response.data.message);
            } else {
              this.goSuccess(
                response.data.message
              );
              // this.$router.push("/users");
              this.dataObj.user.id = response.data.newUser.id;
              this.selected.forEach((item) => {
                if (this.selected != []) {
                  this.savePermissions(response.data.newUser.id, item);
                }
              });
              this.$router.push("/users");
            }
          })
          .catch((error) => {
            this.goError(error.response.data.message);
            console.log(error.response.data.message);
          });
      }
    },

    async savePermissions(user_id, permission_id){
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("permission_id", permission_id);

      await this.$http
          .post(`/user/permission/store`, formData)
          .then((response) => {
            if (response.data.error) {
              this.goError(response.data.message);
            } 
          })
          .catch((error) => {
            this.goError(error.response.data.message);
            console.log(error.response.data.message);
          });
    },

    editUser(){
      this.dataObj.user = this.obj.dataObj;
      this.getUserPermissions();
    },

    async getUserPermissions() {
      await this.$http
        .get(`/permissions/user/${this.dataObj.user.id}`)
        .then((response) => {
          console.log(response.data.user_permissions)
          this.selected = response.data.user_permissions;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    async getPermissions() {
      await this.$http
        .get(`/permissions`)
        .then((response) => {
          this.permissions = response.data.permissions
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    async getParametersSystem() {
      await this.$http
        .get(`/parameters/system`)
        .then((response) => {
          this.parameters = {
            time_password: response.data.parametersSystem.time_system,
            id: response.data.parametersSystem.id
          }
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    async updateUser(id){
        const formData = new FormData();
        formData.append("name", this.dataObj.user.name);
        formData.append("email", this.dataObj.user.email);
        formData.append("role", this.dataObj.user.role);
        formData.append("phone", this.dataObj.user.phone);
        formData.append("password", this.dataObj.user.password);
        formData.append("is_active", this.dataObj.user.is_active);

        await this.$http
          .post(`/user/update/${id}`, formData)
          .then((response) => {
            if (response.data.error) {
              this.goError(response.data.message);
            } else {
              this.goSuccess(
                response.data.message
              );
              this.selected.forEach((item) => {
                if (this.selected != []) {
                  this.savePermissions(id, item);
                }
              });

              this.$router.push("/users");

            }
          })
          .catch((error) => {
            this.goError(error.response.data.message);
            console.log(error.response.data.message);
          });
      }      
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
