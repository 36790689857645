<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'factor-index' }"> <font-awesome-icon :icon="['fas', 'arrow-left']" /> Listagem de fatores</b-breadcrumb-item>
							<b-breadcrumb-item active>Novo fator</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<h5>Novo fator</h5>

						<b-row class="cols-form">
							<b-col md="6">
							</b-col>

							<b-col md="12" class="col-add-fator"> </b-col>
							<b-col md="4">
								<label for="">Código</label>
								<v-select :options="products" label="code" v-model="dataObj.code" @input="selectProduct(dataObj.code)">
									<template slot="option" slot-scope="option">
										{{ option.code }} - {{ option.name }}
									</template>
								</v-select>
							</b-col>

							<b-col md="4">
								<label class="label-disabled" for="">Produto</label>
								<b-form-input class="input-disabled" disabled v-model="dataObj.product"></b-form-input>
							</b-col>

							<b-col md="4">
								<label class="label-disabled" for="">Linha</label>
								<b-form-input class="input-disabled" v-model="dataObj.line" disabled></b-form-input>
							</b-col>

							<b-col md="4">
								<label for="">Fator</label>
								<b-form-input v-model="dataObj.factor"></b-form-input>
							</b-col>

							<b-col md="4">
								<label for="">Data inicial</label>
								<b-form-datepicker id="example-datepicker1" placeholder="" v-model="dataObj.date_initial" class="mb-2"
									:locale="'pt-BR'"
									:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
							</b-col>

							<b-col md="4">
								<label for="">Data final</label>
								<b-form-datepicker id="example-datepicker2" placeholder="" v-model="dataObj.date_final" class="mb-2"
									:locale="'pt-BR'"
									:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
							</b-col>

							<b-col md="1"></b-col>

							<b-col md="6"></b-col>
						</b-row>

						<b-row class="data-user">
							<b-col md="4">
								<label for="">Usuário</label>
								<b-form-input v-model="dataObj.user" disabled></b-form-input>
							</b-col>

							<b-col md="4" v-if="!this.selectedFactor">
								<label for="">Data</label>
								<b-form-input v-model="dataObj.current_date" disabled></b-form-input>
							</b-col>

							<b-col md="4" v-else>
								<label for="">ùltima edição</label>
								<b-form-input v-model="dataObj.current_date" disabled></b-form-input>
							</b-col>

							<b-col md="12" class="btn-salve">
								<b-button class="button-padrao-cancel" :to="{ name: 'factor-index' }">cancelar</b-button>
								<b-button class="save button-padrao-add" @click="saveFactor" v-if="!this.selectedFactor">Salvar</b-button>
								<b-button class="save button-padrao-add" @click="updateFactor" v-else>Salvar edições</b-button>

							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment"
import vSelect from "vue-select";
export default {
	display: "Transition",
	name: "FactorCreateComponent",
	components: {
		PageComponent,
		vSelect
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			dataObj: {
				id: null,
				code: '',
				product: '',
				line: '',
				factor: '',
				date_initial: '',
				date_final: '',
				user: '',
				current_date: '',
				product_id: '',
				user_id: ''
			},
			products: [],
			selectedFactor: "",
			obj: this.$route.params.dataObj,
		};
	},

	mounted() {
		this.getObjParams();
		this.getProducts();
		this.getData();
		this.products.map(function (b) {
			return b.book_info = b.name + '-' + b.code;
		});
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		getData() {
			if (this.selectedFactor) {
				this.dataObj.user = this.selectedFactor.user_name
			}
			this.dataObj.user = this.userLogged.name
			this.dataObj.current_date = moment().format("DD/MM/YYYY");
		},

		async selectProduct(item) {
			this.dataObj.product = item.name;
			this.dataObj.line = item.line_product;
			this.dataObj.code = item.code;
			this.dataObj.product_id = item.id
		},

		async getProducts() {
			await this.$http
				.get(`/products/allProducts`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.products = response.data.productsFactor;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		getObjParams() {
			if (this.$route.params.dataObj) {
				this.selectedFactor = this.obj.factor;
				this.dataObj.code = this.selectedFactor.code
				this.dataObj.product = this.selectedFactor.name
				this.dataObj.line = this.selectedFactor.line_product
				this.dataObj.factor = this.selectedFactor.factor
				this.dataObj.product_id = this.selectedFactor.product_id
				this.dataObj.date_initial = this.selectedFactor.date_initial
				this.dataObj.date_final = this.selectedFactor.date_final
				this.dataObj.current_date = this.selectedFactor.updated_at
				this.dataObj.user = this.selectedFactor.user_name
				this.dataObj.user_id = this.selectedFactor.user_id
				this.dataObj.id = this.selectedFactor.id
				// this.editCollaborator();
			}
		},

		async saveFactor() {
			if (this.dataObj.code == '' || this.dataObj.factor == '') {
				this.goError("Preencha os campos!");
			} else {
				const formData = new FormData();
				formData.append("code", this.dataObj.code);
				formData.append("product", this.dataObj.product_id);
				formData.append("product_name", this.dataObj.product);

				formData.append("factor", this.dataObj.factor);
				formData.append("date_initial", this.dataObj.date_initial);
				formData.append("date_final", this.dataObj.date_final);
				formData.append("user", this.userLogged.id);

				this.$http
					.post(`/factors/store`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							this.$router.push("/factors");

						}
					})
					.catch((error) => {
						this.goError(error.response.data.message);
						console.log(error.response.data.message);
					});
			}

		},

		async updateFactor() {
			if (this.dataObj.code == '' || this.dataObj.factor == '') {
				this.goError("Preencha os campos!");
			} else {
				const formData = new FormData();
				formData.append("id", this.dataObj.id);
				formData.append("code", this.dataObj.code);
				formData.append("product", this.dataObj.product_id);
				formData.append("factor", this.dataObj.factor);
				formData.append("date_initial", this.dataObj.date_initial);
				formData.append("date_final", this.dataObj.date_final);
				formData.append("user", this.dataObj.user_id);
				formData.append("product_name", this.dataObj.product);

				this.$http
					.post(`/factors/update`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							this.$router.push("/factors");
						}
					})
					.catch((error) => {
						this.goError(error.response.data.message);
						console.log(error.response.data.message);
					});
			}
		}
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
