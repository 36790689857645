<template>
  <div class="main">
    <div class="page">

      <div class="content-page-right">
        <div class="side">
          <SidebarComponent />
        </div>
        <div class="pages w-75">
          <div class="nav">
            <NavbarComponent :title="title_nav" />
          </div>
          <slot name="slot-pages"></slot>
        </div>
        <div class="side"></div>
      </div>

      <FooterComponent />
    </div>
  </div>
</template>

<script>
import NavbarComponent from "../../components/navbar/NavbarComponent";
import SidebarComponent from "../../components/sidebar/SidebarComponent";
import FooterComponent from "../../components/footer/FooterComponent";

export default {
  name: "PageComponent",
  props: {
    title_nav: String,
  },

  components: {
    NavbarComponent,
    SidebarComponent,
    FooterComponent,
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />

