<template>
  <div class="component-tabs ">
    <b-tabs content-class="mt-3 ">

      <b-tab title="Acesso rápido" disabled></b-tab>

      <b-tab active>
        <template #title>
          <button>
            <font-awesome-icon :icon="['fas', 'chart-bar']" />  
          </button> 
         <span>Estoque</span>
        </template>
          <br>
          <component :is="stock">
            <slot />
          </component>
          
      </b-tab>

      <b-tab>
        <template #title>
          <button>
            <font-awesome-icon :icon="['fa', 'shopping-cart']" />
          </button>
         <span>Vendas</span>
        </template>
          <br>
          <component :is="sell">
            <slot />
          </component>
          

      </b-tab>
    </b-tabs>

  </div>
</template>

<script>
export default {
  name: "TabComponent",
  props:{
    stock: {
      type: [String, Object],
      default: 'div',
    },

    sell: {
      type: [String, Object],
      default: 'div',
    },
  },
  data() {
    return {
      userLogged: localStorage.getItem("userLogged")
        ? JSON.parse(localStorage.getItem("userLogged"))
        : null,
    };
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />