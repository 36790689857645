<template>
	<div class="main">
		<PageComponent :title_nav="'Cenários e simulações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'scenarios-simulations-index' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Cenários e simulações
							</b-breadcrumb-item>
							<b-breadcrumb-item active v-if="$route.name == 'scenarios-simulations-edit'">Edtar cenário</b-breadcrumb-item>
							<b-breadcrumb-item active v-else>Novo cenário</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						<!-- <h1>Nome da Rota: {{ $route.name }}</h1> -->

						<b-row class="mt-5">
							<b-col md="12">
								<h5 v-if="$route.name == 'scenarios-simulations-edit'">Editar cenário</h5>
								<h5 v-else>Novo cenário</h5>
							</b-col>
						</b-row>

						<b-row class="mt-5 create-scenario">
							<b-col md="">
								<label for="">Nome</label>
								<b-form-input v-model="dataObj.name" placeholder="Digite aqui"></b-form-input>
								<br />
								<label for="">Usuário</label>
								<b-form-input class="input_user" v-model="userLogged.name" readonly></b-form-input>
							</b-col>

							<b-col md="">
								<label for="">Data de referência</label>
								<b-form-datepicker id="dateRef-datepicker" placeholder="" v-model="dataObj.dateRef"
									locale="pt" :date-format-options="{
										year: 'numeric',
										month: 'numeric',
										day: 'numeric',
									}"></b-form-datepicker>

								<br />
								<label for="">Data de criação</label>
								<b-form-input class="input_date_create" v-model="dataObj.dateCreate"
									readonly></b-form-input>
							</b-col>

							<b-col md="">
								<label for="">Observação</label>
								<b-form-textarea id="textarea" v-model="dataObj.observation" placeholder="Digite aqui"
									rows="5" max-rows="10"></b-form-textarea>
							</b-col>

							<b-col md="12"></b-col>

							<!-- <b-col md="3">
								<label for="">Usuário</label>
								<b-form-input
								class="input_user"
									v-model="userLogged.name"
									readonly
								></b-form-input>
							</b-col>

							<b-col md="2">
								<label for="">Data de criação</label>
								<b-form-input
								class="input_date_create"
									v-model="dateCurrent"
									readonly
								></b-form-input>
							</b-col> -->
						</b-row>

						<b-row class="mt-5">
							<!-- <b-col md="2" class="btn-params" v-if="btnParams"> -->
							<b-col md="2" class="btn-params">
								<b-button class="save button-padrao-add" @click="sendScenario()">
									<!-- <b-button class="save button-padrao-add"> -->
									<font-awesome-icon :icon="['fas', 'fa-sliders-h']" />
									Parâmetros de simulação
								</b-button>
							</b-col>
							<b-col md="12" class="col-table">
								<b-card>
									<b-table :items="scenarios" :fields="fieldsProduct" responsive="" :per-page="perPage"
										:current-page="currentPage" class="table-simulations" sticky-header
										:tbody-tr-class="rowClass">
										<template #cell(name)="data">
											<p>
												{{ data.item.name }}
											</p>
										</template>

										<template #cell(dateRef)="data">
											<p>
												{{ data.item.dateRef | dateFormat }}
											</p>
										</template>

										<template #cell(user)="data">
											<p>
												{{ data.item.user }}
											</p>
										</template>

										<template #cell(comment)="data">
											<p>
												{{ data.item.observation }}
											</p>
										</template>

										<template #cell(is_padrao)="data">
											<p>

												<input type="radio" v-if="data.item.is_padrao == 1" checked>
												<input type="radio" v-else @change="selectSimulation(data.item, data.index)">

												<!-- <b-form-radio @change="selectSimulation(data.item, data.index)"
												v-if="data.item.is_padrao == 1" checked :aria-describedby="ariaDescribedby"
													name="some-radios"></b-form-radio> -->
											</p>
										</template>

										<template #cell(actions)="data">
											<p>
												<b-button @click="editarSimulation(data.item.id)"
													style="text-decoration: none; color: black" >
													<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
												</b-button>

												<b-button @click="deleteScenario(data.item.id)" v-b-tooltip.hover
													:title="'Remover cenário: ' + data.item.name">
													<h6><font-awesome-icon :icon="['fas', 'fa-trash-alt']" /></h6>
												</b-button>

												<b-button style="display:none">
													<h6><font-awesome-icon :icon="['fas', 'fa-play']" /></h6>
												</b-button>

												<b-button @click="cloneScenario(data.item)" v-b-tooltip.hover
													:title="'Duplicar cenário: ' + data.item.name">
													<h6><font-awesome-icon :icon="['fas', 'fa-clone']" /></h6>
												</b-button>
											</p>
										</template>
									</b-table>
								</b-card>
							</b-col>

						</b-row>

						<b-row>
							<b-col md="10" class="btn-cancel">
								<b-button class="button-padrao-cancel" @click="cancelForm()">
									Cancelar
								</b-button>
							</b-col>

							<b-col md="2" class="btn-salve">
								<b-button class="save button-padrao-add" @click="storeScenartio()" v-if="!dataObj.id">
									Salvar
								</b-button>

								<b-button class="save button-padrao-add" @click="updateScenartio()" v-else>
									Atualizar
								</b-button>

							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";
import moment from "moment";

export default {
	name: "ScenariosIndexComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			currentRouteName: "white",
			selectedSort: null,
			search: "",
			scenarios: [],
			options: [
				{ value: null, text: "Ordenar por" },
				{ value: "desc", text: "Mais recentes" },
				{ value: "asc", text: "Mais antigos" },
			],
			dateCurrent: new Date().toLocaleDateString(),
			selectedRow: null,
			btnParams: false,

			dataObj: {
				name: "",
				dateRef: "",
				dateCreate: new Date().toLocaleDateString(),
				user_id: "",
				observation: "",
			},
			obj: this.$route.params.id,

		};
	},

	mounted() {
		this.getScenarios();
		if (this.obj) this.findScenario(this.$route.params.id)
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		noView(item) {
			console.log(item);
		},

		sendEst(item) {
			this.$router.push(`/products/${item.id}/est-seg`);
		},

		sendLeadProduct(item) {
			this.$router.push(`/products/${item.id}/config/lead`);
		},

		async orderProducts() {
			const formData = new FormData();
			formData.append("code", this.filter.code);
			formData.append("derivation", this.filter.derivation);
			formData.append("line", this.filter.line);
			formData.append("und", this.filter.und);
			formData.append("supplier", this.filter.supplier);
			formData.append("order", this.selectedSort);
		},

		selectSimulation(item) {
			console.log(item)
			// alert(item.name)
			this.selectedRow = item;

			const formData = new FormData();
			formData.append("simulation_id", item.id);
			formData.append("id_scenario", item.id_scenario);

			this.$http
				.post(`/scenarios/simulation/padrao`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getScenarios()
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});	

		},

		cancelForm() {
			this.btnParams = false;
			this.selectedRow = "";
			this.scenarios.forEach((item) => {
				if (item !== this.selectedRow) {
					item.name = false;
				}
			});
			this.$router.push("/scenarios/simulations");
		},

		async sendScenario() {

			// const formData = new FormData();
			// formData.append("name", this.dataObj.name);
			// formData.append("observation", this.dataObj.observation);
			// formData.append("user_id", this.userLogged.id);
			// formData.append("dateRef", this.dataObj.dateRef);
			// formData.append("dateCreate", this.dataObj.dateCreate);
			// formData.append("id_scenario", this.obj);

			// this.$http
			// 	.post(`/scenarios/store/simulation`, formData)
			// 	.then((response) => {
			// 		if (response.data.error) {
			// 			this.goError(response.data.message);
			// 		} else {
			// 			this.goSuccess(response.data.message);

			// 			const dataObj = {
			// 				scenario: response.data.id_simulation,
			// 			};

			// 		}
			// 	})
			// 	.catch((error) => {
			// 		this.goError(error.response.data.message);
			// 		console.log(error.response.data.message);
			// 	});		
			const dataObj = {
				scenario: this.obj,
			};
			this.$router.push({
				path: '/scenarios/'+ this.obj + '/create/simulations',
				params: {
					dataObj: { dataObj },
				},
			});
		},

		editarSimulation(id_simulation) {
			const dataObj = {
				scenario: id_simulation,
			};
			this.$router.push({
				path: '/scenarios/'+ this.obj +'/create/simulation/' + id_simulation,
				params: {
					dataObj: { dataObj},
				},
			});
		},

		async storeScenartio() {
			const formData = new FormData();
			formData.append("name", this.dataObj.name);
			formData.append("observation", this.dataObj.observation);
			formData.append("user_id", this.userLogged.id);
			formData.append("dateRef", this.dataObj.dateRef);
			formData.append("dateCreate", this.dataObj.dateCreate);

			this.$http
				.post(`/scenarios/store`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getScenarios();
						this.dataObj = {}
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		},

		async updateScenartio() {
			const formData = new FormData();
			formData.append("id", this.dataObj.id);
			formData.append("name", this.dataObj.name);
			formData.append("observation", this.dataObj.observation);
			formData.append("user_id", this.userLogged.id);
			formData.append("dateRef", this.dataObj.dateRef);
			formData.append("dateCreate", this.dataObj.dateCreate);

			this.$http
				.post(`/scenarios/update`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getScenarios();
						this.dataObj = {}
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		},

		async getScenarios() {
			await this.$http
				.get(`/scenarios/${this.$route.params.id}/simulations`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.scenarios = response.data.simulations;
						this.dataObj.dateCreate = new Date().toLocaleDateString();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async findScenario(id) {
			await this.$http
				.get(`/scenarios/${id}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						// this.scenarios = response.data.scenarios;
						console.log(response.data.scenario)
						let scenario = response.data.scenario;
						this.dataObj.name = scenario.name
						this.dataObj.dateRef = scenario.dateRef
						this.dataObj.dateCreate = moment(scenario.dateCreate).format("DD/MM/YYYY")
						this.dataObj.user_id = scenario.user
						this.dataObj.observation = scenario.observation
						this.dataObj.id = scenario.id
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async deleteScenario(id) {
			const formData = new FormData();
			formData.append("id", id);

			this.$http
				.post(`/scenarios/delete`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getScenarios();
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		},

		async cloneScenario(item) {
			const formData = new FormData();
			formData.append("name", item.name);
			formData.append("observation", item.observation);
			formData.append("user_id", this.userLogged.id);
			formData.append("dateRef", item.dateRef);
			formData.append("dateCreate", moment(item.dateCreate).format("DD/MM/YYYY"));

			this.$http
				.post(`/scenarios/store`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getScenarios();
						this.dataObj = {}
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		}

	},

	computed: {
		filteredList() {
			return this.scenarios.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
		fieldsProduct: function () {
			let fields = [
				{ key: "name", label: "Nome" },
				{ key: "dateRef", label: "Data" },
				{ key: "comment", label: "Comentário" },
				{ key: "is_padrao", label: "Padrão" },
				{ key: "user", label: "Usuário" },
				{ key: "actions", label: "Ações" },
			];
			return fields;
		},

		fields: function () {
			let fields = [
				{
					key: "name",
					label: "Nome",
				},
				{ key: "sort", label: "Ordem" },
				{ key: "observation", label: "Observação" },
				{ key: "action", label: "" },
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.scenarios.slice(start, end);
		},

		rowClass() {
			return (row) => {
				return {
					"table-primary": this.selectedRow === row,
				};
			};
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD/MM/YYYY");
		},
	},

};
</script>

<style lang="scss" src="./style.scss" scoped />
