<template>
	<div class="main">
		<PageComponent :title_nav="'Budget/ForeCast'">
			<div slot="slot-pages" class="content-pages">
				<div class="budget">
					<div class="budget-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						<b-row>

							<b-col md="2" class="col-new-year">
								<b-card>
									<h6>
										<strong> Ano </strong>
										<b-button href="" @click="new_year()" v-if="userLoggedPermission.permissionbudgetm == 1 || userLogged.role == 'Administrador'">Novo + </b-button>
									</h6>
									<p v-for="year in years" :key="year">
										<b-button class="btn-year" @click="changeYear(year)">
											<div :class="getYear.year == year ? 'menu-active' : ''">
												<h6>{{ year }}</h6>
											</div>
										</b-button>
									</p>
								</b-card>
							</b-col>

							<b-col md="10" class="col-data">
								<b-card>
									<b-row>
										<b-col md="2">
											<span> <strong> Budget </strong> </span>
										</b-col>

										<b-col md="1">Ano <br />
											<small>
												<strong> {{ this.year }} </strong>
											</small>

										</b-col>
										<b-col md="2">Usuário <br />
											<small>
												<strong> {{ this.userYear }} </strong>
											</small>

										</b-col>
										<b-col md="2">Criação <br />
											<small>
												<strong> {{ this.dtCreateYear | dateFormat }} </strong>
											</small>
										</b-col>
										<b-col md="2">Revisão <br />
											<small>
												<strong> {{ this.dtUpdateYear | dateFormat }} </strong>
											</small>
										</b-col>

										<b-col md="1" class="line-divide">
											<div></div>
										</b-col>
										<b-col class="icons">
											<!-- <b-button @click="viewBudget(idYear, 0)"><font-awesome-icon :icon="['fas', 'eye']" /></b-button> -->
											<b-button @click="editBudget(idYear, 1)"  v-if="userLoggedPermission.permissionbudgetm == 1 || userLogged.role == 'Administrador'"><font-awesome-icon :icon="['fas', 'eye']" /></b-button>
											<b-button @click="editBudget(idYear, 1)"  v-if="userLoggedPermission.permissionbudgetm == 1 || userLogged.role == 'Administrador'"><font-awesome-icon :icon="['fas', 'edit']" /></b-button>
											<b-button @click="deletBudget(getYear)"  v-if="userLoggedPermission.permissionbudgetm == 1 || userLogged.role == 'Administrador'"><font-awesome-icon
													:icon="['fas', 'trash-alt']" /></b-button>
										</b-col>
									</b-row>
								</b-card>

								<b-row>
									<b-col>
										<h5><strong> ForeCast </strong></h5>
									</b-col>
									<b-col class="btn_new_forecast"  v-if="userLoggedPermission.permissionbudgetm == 1 || userLogged.role == 'Administrador'">
										<!-- <b-button class="btn" :to="{ name: 'forecast-create' }" @click="saveForecast()"> + Novo ForeCast </b-button> -->
										<b-button class="btn" @click="validationMonth()"> + Novo ForeCast </b-button>
									</b-col>
								</b-row>

								<b-card class="card_table">
									<b-table :items="itemsFiltradosPaginados" :fields="fieldsBudget" responsive="" class="table-budget">
										<template #cell(month)="data">
											{{ data.item.month | monthName }}/{{ year }}
										</template>

										<template #cell(date)="data">
											{{ data.item.date | dateFormat }}
										</template>

										<template #cell(actions)="data">
											<b-button  v-if="userLoggedPermission.permissionbudgetm == 1 || userLogged.role == 'Administrador'">
												<font-awesome-icon :icon="['fas', 'eye']" v-if="data.item.visible == 0"
													@click="updateStatus(data.item, 1)" />
												<font-awesome-icon :icon="['fas', 'eye-slash']" v-else @click="updateStatus(data.item, 0)" />
											</b-button>

											<b-button @click="editForecast(data.item)"  v-if="userLoggedPermission.permissionbudgetm == 1 || userLogged.role == 'Administrador'">
												<font-awesome-icon :icon="['fas', 'pen']" />
											</b-button>
											<b-button @click="deleteForecast(data.item)"  v-if="userLoggedPermission.permissionbudgetm == 1 || userLogged.role == 'Administrador'">
												<font-awesome-icon :icon="['fas', 'trash-alt']" />
											</b-button>
										</template>

									</b-table>
								</b-card>
							</b-col>

							<b-col md="12" class="mt-3">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";
import moment from "moment";

export default {
	name: "BudgetComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			perPage: 6,
			currentPage: 1,
			years: "",
			getYear: "",
			currentYear: new Date().getFullYear(),
			idYear: "",
			year: "",
			userYear: "",
			dtCreateYear: "",
			dtUpdateYear: "",
			month: "",
			forecasts: [],
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
			paginaAtual: 1,
			itensPorPagina: 5,
			search: "",

		};
	},

	mounted() {
		// this.validationYear();
		this.getYears();
		this.changeYear(0)
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getProducts() {
			await this.$http
				.get(`/products`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.products = response.data.products;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		new_year() {
			this.$router.push({
				name: "budget-create",
			});
		},

		async getYears() {
			await this.$http
				.get(`/budget/list/years`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.years = response.data.years;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async changeYear(year) {
			await this.$http
				.get(`/budget/year/${year}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.getYear = response.data.budget;
						console.log(this.getYear)
						this.idYear = this.getYear.id
						this.year = this.getYear.year
						this.userYear = this.getYear.user
						this.dtCreateYear = this.getYear.dt_create
						this.dtUpdateYear = this.getYear.dt_revision
						this.getForecast();

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async validationYear() {
			await this.$http
				.get(`/budget/getYear`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.getYear = response.data.yearFirst;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		viewBudget(budget_id) {
			this.$router.push({
				path: `budget/${budget_id}`,
			});
		},

		editBudget(budget_id, operation) {
			this.$router.push({
				path: `budget/${budget_id}/${'create'}`,
				params: {
					budget: { budget_id },
					operation: { operation }
				},
			});
		},

		async deletBudget(budget_id) {
			const formData = new FormData();
			formData.append("id", budget_id.id);
			this.$swal
				.fire({
					title: "Deletar Budget?",
					text: "Tem certeza que deseja deletar o budget com este ano " + budget_id.year + "?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#b4b4b4",
					confirmButtonText: "Deletar",
					cancelButtonText: "Cancelar",
					position: "center",
					timer: 4000,
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$http
							.post(`/budget/delete`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.getYears();
								}
							})
							.catch(function (error) {
								console.log(error);
							});

						this.$swal.fire("Budget removida com sucesso!");
					}
				});
		},

		async validationMonth() {
			// const current = new Date();
			await this.$http
				.get(`/forecast/month/${this.idYear}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.month = response.data.month;
						this.saveForecast()
					}
				})
				.catch(function () {
					console.log("oooooooo")
					this.saveForecast()
				});
		},

		async saveForecast() {
			let formData = new FormData();
			formData.append("user", this.userLogged.id);
			formData.append("budget_id", this.idYear);
			const current = new Date();
			if (this.month == 0) {
				formData.append("month", current.getMonth() + 1);
			} else {
				formData.append("month", moment(this.month, 'M').add(1, 'months').format('M'));
				console.log(moment(this.month, 'M').add(1, 'months').format('M'))
			}

			this.$http
				.post(`/forecast/store`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						let forecast = response.data.forecast;

						this.$router.push({
							path: `forecast/${forecast}/${'create'}`,
						});

					}
				})
				.catch(function (error) {
					console.log(error);
				});

		},

		editForecast(item) {
			this.$router.push({
				path: `forecast/${item.id}/${'create'}`,
			});
		},

		async updateStatus(item, value) {
			let formData = new FormData();
			formData.append("visible", item.visible);
			formData.append("id_forecast", item.id);
			formData.append("visible", value);
			console.log(value)
			this.$http
				.post(`/forecast/update/status`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						// this.goSuccess(response.data.message);
						this.getForecast()
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async deleteForecast(item) {
			const formData = new FormData();
			formData.append("id", item.id);
			this.$swal
				.fire({
					title: "Deletar Forecast?",
					text: "Tem certeza que deseja deletar o forecast ?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#b4b4b4",
					confirmButtonText: "Deletar",
					cancelButtonText: "Cancelar",
					position: "center",
					timer: 4000,
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$http
							.post(`/forecast/delete`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.getForecast()
								}
							})
							.catch(function (error) {
								console.log(error);
							});

						this.$swal.fire("Forecast removido com sucesso!");
					}
				});
		},

		async getForecast() {
			await this.$http
				.get(`/forecast/budget/${this.idYear}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.forecasts = response.data.forecasts;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},


	},

	computed: {
		fieldsBudget: function () {
			let fields = [
				{
					key: "month",
					label: "Mês",
				},

				{
					key: "date",
					label: "Data",
				},

				{
					key: "user",
					label: "Usuário",
				},

				{
					key: "actions",
					label: "Ações",
				},
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.dolars.slice(start, end);
		},

		filteredList() {
			return this.products.filter((product) => {
				return product.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.forecasts.filter(item => item.month.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD/MM/YYYY HH:mm");
		},

		monthName: function (date) {
			return moment(date, 'M').locale('pt-BR').format('MMM');
		}
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
