<template>
  <div class="main">
    <div
      class="bg-login"
    >
      <!-- Imagem pessoa de fundo -->
      <img
        class="login__peaple--bg"
        alt="Imagem"
        src="@/assets/img/foto_login.jpeg"
      />
      <div class="login">
        <div class="content d-flex justify-content-center align-items-center">
          <div class="form">
            <header>
              <img alt="logo" src="@/assets/logo.png" />
            </header>
            <h6 class="title_login">ENTRAR</h6>
            <form @submit.prevent="submit">
              <div class="form-group left">
                <input
                  type="email"
                  class="form-control"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Usuário"
                  v-model="dataObj.login.email"
                  required
                />
              </div>
              <div class="form-group left no-bottom">
                <input
                  type="password"
                  class="form-control"
                  id="password"
                  placeholder="Senha"
                  v-model="dataObj.login.password"
                  required
                />
              </div>
              <!-- <div class="form-group form-check left">

                <a
                  @click.prevent="goForgotPassword"
                  type="button"
                  class="btn btn-link forget sub-title"
                  >Esqueceu sua senha?</a
                >
              </div> -->

              <button type="submit" class="btn button-login center">
                ENTRAR
              </button>

            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment';

export default {
  name: "LoginComponent",

    data() {
      return {
        dataObj: {
            login: {
              email: "",
              password: "",
            },
        },
        userPermission: [],
        permissionAccess: ""
      };
    },
  methods:{
    goForgotPassword() {
      this.$router.push("/forgot-password");
    },
    goLogin(user) {
      if (user) {
        this.$router.push("/dashboard");
      } else{
        alert("Usuário ou senha inválidos!")
      }

    },

    goSuccess(message) {
        this.$swal({
            toast: true,
            position: "center",
            showConfirmButton: false,
            timer: 4000,
            icon: "success",
            title: "Sucesso!",
            text: message ? message : "Cadastro Atualizado.",
        });     
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "top-end",
        showConfirmButton: false,
        timer: 4000,
        icon: "error",
        title: "Ops...",
        text: message ? message : "Usuário ou senha inválidos!",
      });
    },

    async submit() {
      await this.$http
        .post("/auth/login", this.dataObj.login)
        .then((response) => {
          localStorage.setItem(
            "authToken",
            JSON.stringify(response.data.access_token)
          );
          localStorage.setItem(
            "authTokenExpires",
            JSON.stringify(response.data.expires_in)
          );
          localStorage.setItem(
            "userLogged",
            JSON.stringify(response.data.user)
          );
          localStorage.setItem(
            "@vscom:userAvatar",
            JSON.stringify(response.data.user.image)
          );
          this.userPermission = response.data.userPermission;
            let arr= [];
                this.userPermission.forEach((value) => {
                    arr.push(value);
                    this.permissionAccess= value;
                    localStorage.setItem(
                      "userPermission",
                      JSON.stringify(this.permissionAccess)
                    );

                });

          if(response.data.userPermission.length == 0){
            localStorage.setItem(
              "userPermission",
              JSON.stringify(0)
            );
          }

          this.goLogin(response.data.user);

        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.goError(error.response.data.message);

          if(error.response.data.nova_senha){

            this.$router.push({
              name: "recovery-password",
              params: {
                dataObj: error.response.data.nova_senha,
              },
            });

          }
        });
    },  
  },
  mounted(){

  },

};
</script>

<style lang="scss" src="./style.scss" scoped />

