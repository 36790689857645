<template>
  <div class="component-tabs">
    <b-row class="mt-6">
      <b-col md="4" v-for="item in this.itemsFiltradosPaginados" :key="item.id">
        <b-card>
          <span></span>
          <b-row>
            <b-col md="10" class="col-img">
              <b-avatar variant="light" class="fa-border-style"></b-avatar>
            </b-col>

            <b-col md="2" class="btn-options">
              <b-dropdown id="dropdown-offset" toggle-class="text-decoration-none" no-caret v-if="userLoggedPermission.permissionusersm == 1 || userLogged.role == 'Administrador'">
                <template #button-content>
                  <font-awesome-icon :icon="['fas', 'ellipsis-v']" />
                </template>
                <b-dropdown-item href="#" class="text-muted" @click="editUser(item)">Editar</b-dropdown-item>
                <!-- <b-dropdown-item href="#" class="text-muted" @click="method(item.id)">Excluir</b-dropdown-item> -->
              </b-dropdown>
            </b-col>

            <b-col md="12" class="info-user">
              <p class="name">
                {{ item.name }}
              </p>
              <p class="text-muted">
                {{ item.role }}
              </p>
            </b-col>
          </b-row>

          <div class="data-user">
            <p>
              <font-awesome-icon :icon="['fas', 'briefcase']" />
              Administrativo
            </p>

            <p>
              <font-awesome-icon :icon="['fas', 'phone']" />
              {{ item.phone }}
            </p>

            <p>
              <font-awesome-icon :icon="['fas', 'envelope']" />
              {{ item.email }}
            </p>
          </div>

          <!-- {{item}} -->
        </b-card>
      </b-col>
    </b-row>

    <b-row class="paginations-options1 mt-3">
      <!-- <b-col md="6">
        <span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
      </b-col> -->
      <b-col md="12">
        <b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
      </b-col>
    </b-row>
  </div>
</template>
<script>
export default {
  name: "TabCardComponent",
  props: {
    users: Array,
    method: { type: Function },
  },
  data() {
    return {
      userLogged: localStorage.getItem("userLogged")
        ? JSON.parse(localStorage.getItem("userLogged"))
        : null,
      perPage: 6,
      currentPage: 1,
      userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
      
      paginaAtual: 1,
			itensPorPagina: 5,
    };
  },

  computed: {
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;

      return this.users.slice(start, end);
    },
    numeroDePaginas() {
      return Math.ceil(this.users.length / this.perPage);
    },
		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.users;
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},

  },

  methods: {
    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },

    // enviar dados do colaborador selecionado para o formulario para editar
    editUser(user) {
      const dataObj = user;
      console.log(dataObj);
      this.$router.push({
        name: "register",
        params: {
          dataObj: { dataObj },
        },
      });
    },

  },
};
</script>
<style lang="scss" src="./style.scss" scoped />
