<template>
	<div class="main">
		<PageComponent :title_nav="'Integrações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'products-index' }"> <font-awesome-icon :icon="['fas', 'arrow-left']" /> Listagem de produtos</b-breadcrumb-item>
							<b-breadcrumb-item active>Configurações de lead time do produto</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row class="product-leads">
							<b-col md="12">
								<h5>{{dataObj.code}}  - {{dataObj.name}}</h5>

                <span>Informar o tempo em dias para o lead time total do produto <strong> {{dataObj.name}} </strong></span>
							</b-col>
              <b-col md="12">
                <b-table
                  :items="productslead"
                  :fields="fields"
                  responsive=""
                  class="table-leads"
                >
                  <template #cell(name)="data">
                    <p class="name">
                      {{ data.item.name_lead }}
                    </p>
                  </template>
                  <template #cell(days)="data">
                    <input type="text" v-model="data.item.days" @input="atualizarSoma" class="form-control days" placeholder="Inserir números de dias" onkeypress='return event.charCode >= 48 && event.charCode <= 57'>
                  </template>

                <template v-slot:custom-foot>
                  <!-- You can customize this however you want, this is just as an example -->
                  <tr>
                    <td class="bg-white text-dark text-total" :colspan="1">
                      Lead Time total
                    </td>

                    <td class="bg-white text-dark" :colspan="1">
                      <input type="text" v-model="somaValores" class="form-control days" placeholder="Número total" onkeypress='return event.charCode >= 48 && event.charCode <= 57' readonly>
                    </td>
                  </tr>
                </template>

                </b-table>
              </b-col>
							<b-col md="12"></b-col>
							<b-col md="6"></b-col>
							<b-col md="6" class="btn-salve">
								<b-button
									class="save button-padrao-add"
									@click="saveLead"
									>salvar</b-button
								>
								<br />
								<b-button class="button-padrao-cancel" :to="{ name: 'products-index' }"
									>cancelar</b-button
								>
							</b-col>
						</b-row>

					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import data from "./jsondata.json";

export default {
	name: "ConfigLeadProductComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			dragging: false,
			obj: this.$route.id,
			dataObj: {
				name: "",
				code: "",
				qtd: "",
			},
			isDisabled: true,
			selectMode: "single",
			selected: {},
			leadTimes: [],
      products: data.products,
			currentRouteName: "white",
      isView: true,
      isActive: false,
      styleObject:{},
      days_total: [],
			daysLead: "",
			productslead: []
		};
	},

	mounted() {
		this.dataEst();
    this.getLeadTimes();
		this.getProductLead();
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getLeadTimes() {
			await this.$http
				.get(`/leadTime`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.leadTimes = response.data.leadTimes;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		// retorna lista dos leadtimes do produto especifico
		async getProductLead() {
			await this.$http
				.get(`/products/${this.$route.params.id}/leadTimes`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.productslead = response.data.productslead;
						this.atualizarSoma();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		atualizarSoma() {
				this.somaValores = this.productslead.reduce((total, item) => total + parseFloat(item.days || 0), 0);
				console.log(this.productslead)
			},

		async dataEst(){
			await this.$http
				.get(`/products/${this.$route.params.id}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.dataObj = response.data.product;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async saveLead(){
			console.log(this.productslead)
				let formData = new FormData();
					this.productslead.forEach(item => {
						formData.append("id_lead_time", item.id_lead_time);
						formData.append("days", item.days);
						formData.append("id_prod", item.id_prod);
						formData.append("codProd", item.codProd);

						this.$http
							.post(`/products/leadTime/store`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									// this.goSuccess(response.data.message);
									// this.getProductLead();
								}
							})
							.catch(function (error) {
								console.log(error);
							});

          });
			this.goSuccess("LeadTime do produto gravado com sucesso!");
			this.getProductLead();


		},

		showModal() {
			this.$refs["my-modal"].show();
		},

		hideModal() {
			this.$refs["my-modal"].hide();
			this.products = [];
			this.selectedProduct = "";
		},

    view(item){
			if (item) this.currentRouteName = item;
      this.styleObject= {
        color: 'red',
        fontSize: '13px'
      }
      alert(this.currentRouteName)
    },

    noView(item){
      alert(item)
    }

	},

	computed: {
		filteredList() {
			return this.newStock.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
    totalExpenses() {
      return this.productslead.reduce((acc, row) => acc + row.days, 0);
    },

		fields: function () {
			let fields = [
				{
					key: "name",
					label: "Nome",
				},

				{
					key: "days",
					label: "Dias",
				},
			];
			return fields;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
