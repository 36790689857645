<template>
  <div class="main">
    <PageComponent :title_nav="'Cadastros'">
      <div slot="slot-pages" class="content-pages">
        <div class="products">
          <div class="products-header">
            <!-- breadcrumb -->
            <b-breadcrumb>
              <b-breadcrumb-item :to="{ name: 'stock-index' }">
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                Listagem de depósitos
              </b-breadcrumb-item>
              <b-breadcrumb-item active>Cadastro de depósito</b-breadcrumb-item>
            </b-breadcrumb>
            <!-- breadcrumb -->

            <b-row class="row-register">
              <b-col md="4">
                <b-form-group id="input-group-1" label="Código do Depósito:" label-for="input-1">
                  <b-form-input id="input-1" v-model="dataObj.code" type="text" required></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="8">
                <b-form-group id="input-group-1" label="Nome do Depósito:" label-for="input-1">
                  <b-form-input id="input-1" v-model="dataObj.name" type="text" required></b-form-input>
                </b-form-group>
              </b-col>

              <b-col md="4" class="col-status">
                <b-form-group id="input-group-1" label="Status do Depósito:" label-for="input-1">
                  <b-form-select v-model="dataObj.type" :options="options"></b-form-select>
                </b-form-group>
              </b-col>

              <b-col md="12" class="btn-salve">
                <b-button class="button-padrao-cancel" :to="{ name: 'stock-index' }">cancelar</b-button>
                <b-button class="save button-padrao-add" @click="saveStock" v-if="!this.dataObj.id">Cadastrar</b-button>
                <b-button class="save button-padrao-add" @click="updateStock" v-else>Atualizar</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </PageComponent>
  </div>
</template>
<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";

export default {
  name: "StockCreateComponent",
  components: {
    PageComponent,
  },
  data() {
    return {
      userLogged: localStorage.getItem("userLogged")
        ? JSON.parse(localStorage.getItem("userLogged"))
        : null,
      processes: [],
      perPage: 6,
      currentPage: 1,
      search: "",
      dataObj: {
        id: null,
        code: '',
        name: '',
        type: true
      },
      id: this.$route.params.id,
      options: [
        { value: null, text: 'Selecione...' },
        { value: 'true', text: 'Aceito' },
        { value: 'false', text: 'Rejeitado' },
      ]
    };
  },

  mounted() {
    if (this.id) {
      this.findStock(this.id);
    }
  },

  methods: {
    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },
    // SALVAR ESTOQUE
    async saveStock() {
      let formData = new FormData();
      formData.append("code", this.dataObj.code);
      formData.append("name", this.dataObj.name);
      formData.append("type", this.dataObj.type);

      this.$http
        .post(`/stocks/store`, formData)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.goSuccess(response.data.message);
            this.$router.push("/stock");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    //ATUALIZAR ESTOQUE
    async updateStock() {
      let formData = new FormData();
      formData.append("id", this.dataObj.id);
      formData.append("code", this.dataObj.code);
      formData.append("name", this.dataObj.name);
      formData.append("type", this.dataObj.type);

      this.$http
        .post(`/stocks/update`, formData)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.goSuccess(response.data.message);
            this.$router.push("/stock");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async findStock(id) {
      await this.$http
        .get(`/stocks/${id}`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.stock = response.data.stock;
            this.dataObj.id = this.stock.id;
            this.dataObj.code = this.stock.code;
            this.dataObj.name = this.stock.name;
            this.dataObj.type = this.stock.type;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },

  computed: {
    fieldsProduct: function () {
      let fields = [
        {
          key: "type",
          label: "Tipo",
        },

        {
          key: "dt_import",
          label: "Data Importação",
        },

        {
          key: "dt_approved",
          label: "Data aprovação",
        },

        {
          key: "user_name",
          label: "Usuário",
        },
        {
          key: "actions",
          label: "",
        },
      ];
      return fields;
    },

    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;

      return this.processes.slice(start, end);
    },

    numeroDePaginas() {
      return Math.ceil(this.processes.length / this.perPage);
    },
  },

  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
