<template>
  <div class="main">
    <div
      class="bg-login"
    >
      <!-- Imagem pessoa de fundo -->
      <img
        class="login__peaple--bg"
        alt="Imagem"
        src="@/assets/img/foto_login.jpeg"
      />
      <div class="recovery">
        <div class="content d-flex justify-content-center align-items-center">
          <div class="form">
            <header>
              <img alt="logo" src="@/assets/logo.png" />
            </header>

              <div class="form-group left">
                <input
                  type="password"
                  class="form-control form-control-sm"
                  id="password"
                  v-model="dataObj.recoveryPassword.password"
                  required
                  placeholder="Nova Senha"
                />
              </div>
              <div class="form-group left">
                <input
                  type="password"
                  class="form-control form-control-sm"
                  id="confirmPassword"
                  v-model="dataObj.recoveryPassword.confirmPassword"
                  required
                  placeholder="Confirmar Nova Senha"
                />
              </div>
              <button class="btn button-yellow center button-confirm" @click="updatePassword()">
                Confirmar
              </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "RecoveryPasswordComponent",
  data() {
    return {
      dataObj: {
        recoveryPassword: {
          password: "",
          confirmPassword: "",
        },
      },
			obj: this.$route.params.dataObj,

    };
  },
  methods: {
    goLogin() {
      this.$router.push("/");
    },

    goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

    async updatePassword() {
      if(this.dataObj.recoveryPassword.password == '' || this.dataObj.recoveryPassword.confirmPassword == ''){
						this.goError("Os campos devem ser preenchidos!");
      } else {
        const formData = new FormData();
			formData.append("user_id", this.obj.id);
			formData.append("password", this.dataObj.recoveryPassword.password);
			formData.append("confirmPassword", this.dataObj.recoveryPassword.confirmPassword);

      await this.$http
        .post("/auth/recovery-password", formData)
        .then((response) => {
          if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
            this.$router.push("/");
					}
        })
        .catch((error) => {
          console.log(error.response.data.message);
          this.goError(error.response.data.message);

          if(error.response.data.nova_senha){

            this.$router.push({
              name: "recovery-password",
              params: {
                dataObj: error.response.data.nova_senha,
              },
            });

          }
        });
      }

    }, 
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />

