<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dolar-index' }"> <font-awesome-icon :icon="['fas', 'arrow-left']" />Listagem de Dólar</b-breadcrumb-item>
							<b-breadcrumb-item active>Nova taxa de dólar</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						
						<h5>Nova taxa de dólar</h5>

						<b-row class="cols-form">
							<b-col md="4">
								<label for="">Data inicial</label>
								<b-form-datepicker id="example-datepicker1" placeholder="" 
								v-model="dataObj.date_initial" class="mb-2" :locale="'pt-BR'"
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
							</b-col>

							<b-col md="4">
								<label for="">Data final</label>
								<b-form-datepicker id="example-datepicker2" placeholder="" 
								v-model="dataObj.date_final" class="mb-2" :locale="'pt-BR'"
								:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
							</b-col>

							<b-col md="4">
								<label for="">Taxa</label>
								<b-form-input
									v-model="dataObj.tax"
								></b-form-input>
							</b-col>

						</b-row>

						<b-row class="data-user">
							<b-col md="4">
								<label for="">Usuário</label>
								<b-form-input
									v-model="dataObj.user"
									disabled
								></b-form-input>
							</b-col>

							<b-col md="4" v-if="!this.selectedFactor">
								<label for="">Data</label>
								<b-form-input
									v-model="dataObj.current_date"
									disabled
								></b-form-input>
							</b-col>

							<b-col md="4" v-else>
								<label for="">ùltima edição</label>
								<b-form-input
									v-model="dataObj.current_date"
									disabled
								></b-form-input>
							</b-col>

							<b-col md="12" class="btn-salve">
								<b-button class="button-padrao-cancel" :to="{ name: 'dolar-index' }"
									>cancelar</b-button
								>
								<b-button class="save button-padrao-add" @click="saveDolar"
									v-if="!this.selectedFactor">Cadastrar</b-button
								>
								<b-button class="save button-padrao-add" @click="updateDolar"
									v-else>Atualizar</b-button
								>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment"
export default {
	display: "Transition",
	name: "DolarCreateComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
        ? JSON.parse(localStorage.getItem("userLogged"))
        : null,
			dataObj:{
				id: null,
				tax: '',
				date_initial: '',
				date_final: '',
				user: '',
				current_date: '',
				user_id: ''
			},
			selectedFactor: "",
			obj: this.$route.params.dataObj,
		};
	},

	mounted() {
		this.getObjParams();
		this.getData();
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		getData(){
			if(this.selectedFactor) {
				this.dataObj.user = this.selectedFactor.user_name
			}
			this.dataObj.user = this.userLogged.name
			this.dataObj.current_date = moment().format("DD/MM/YYYY"); 
		},

		getObjParams() {
      if (this.$route.params.dataObj) {
        this.selectedFactor = this.obj.factor;
				this.dataObj.tax = this.selectedFactor.tax
				this.dataObj.date_initial = this.selectedFactor.date_initial
				this.dataObj.date_final = this.selectedFactor.date_final
				this.dataObj.current_date = this.selectedFactor.updated_at
				this.dataObj.user = this.selectedFactor.user_name
				this.dataObj.user_id = this.selectedFactor.user_id
				this.dataObj.id = this.selectedFactor.id
      }
		},

		async saveDolar() {
			const formData = new FormData();
			formData.append("tax", this.dataObj.tax);
			formData.append("date_initial", this.dataObj.date_initial);
			formData.append("date_final", this.dataObj.date_final);
			formData.append("user", this.userLogged.id);

			this.$http
				.post(`/dolar/store`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.$router.push("/dolar");

					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		},

		async updateDolar() {
			const formData = new FormData();
			formData.append("id", this.dataObj.id);
			formData.append("tax", this.dataObj.tax);
			formData.append("date_initial", this.dataObj.date_initial);
			formData.append("date_final", this.dataObj.date_final);
			formData.append("user", this.dataObj.user_id);

			this.$http
				.post(`/dolar/update`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.$router.push("/dolar");
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		}
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
