<template>
  <b-navbar toggleable="lg" fixed="" variant="">
    <b-navbar-toggle target="nav-collapse"></b-navbar-toggle>

    <b-collapse id="nav-collapse" is-nav>
      <div class="content-center">
        <div class="navbar-collapse title-nav" id="appnav" v-if="title">
          {{ title }}
        </div>

        <div class="navbar-collapse title-nav" id="appnav" v-else>
          Início
        </div>
      </div>
      <div class="content-right">
        <div class="navbar-collapse justfy-center" id="appnav">
          <!-- search field-->
          <!-- <div class="search_input">
            <form>
              <input type="search" placeholder="Pesquisar" />
              <button type="submit">Pesquisar</button>
            </form>
          </div> -->
          <!-- dropdown alert -->
          <b-dropdown id="alert" class="m-md-2" no-caret>
            <b-dropdown-header class="timer_header">
              Notificações
            </b-dropdown-header>
            <template #button-content>
              <font-awesome-icon :icon="['fas', 'bell']" class="icon fa-border-style green-color text-light"
                style="color: #343332" />
              <span class="icon-button__badge">2</span>
            </template>
          </b-dropdown>

          <b-button v-b-toggle.info_user class="btn-modal">
            <b-avatar variant="light" class="fa-border-style"></b-avatar>
          </b-button>
        </div>
      </div>
    </b-collapse>

    <!-- Perfil usuario -->
    <b-sidebar right id="info_user" title="" backdrop aria-label="Sidebar with custom footer" no-header shadow>
      <template #default="{ hide }">
        <div class="p-3">
          <b-button class="btn-close-modal" block @click="hide">
            <font-awesome-icon :icon="['fas', 'times']" />
          </b-button>

          <div class="img-info">
            <div>
              <b-avatar variant="light" class="fa-border-style"></b-avatar>
            </div>

            <div class="info-profile">
              <p class="name-user">
                {{ userLogged.name }}
              </p>

              <!-- <p class="text-muted">{{ userLogged.role }}</p> -->
              <p class="mt-3">
                <font-awesome-icon :icon="['fas', 'briefcase']" />
                {{ userLogged.role }}
              </p>

              <p>
                <font-awesome-icon :icon="['fas', 'phone']" />
                {{ userLogged.phone }}
              </p>

              <p>
                <font-awesome-icon :icon="['fas', 'envelope']" />
                {{ userLogged.email }}
              </p>
             
            </div>
          </div>

          <div class="div-permissions">
            <p class="title">Permissões do usuário</p>

            <p v-if="selected[0]"><label class="title">Inicio</label>
              <label for="v_inicio" class="checks" v-if="selected[0]">
                Visualizar
              </label>
            </p>

            <p v-if="selected[1]"><label class="title">S&OP - Quantidade</label>
              <label for="v_seop_qtde" class="checks" v-if="selected[1]">
                Visualizar 
              </label>
            </p>

            <p v-if="selected[2]"><label class="title">S&OP - Custos</label>
              <label for="v_seop_cost" class="checks" v-if="selected[2]">
                Visualizar
              </label>
            </p>

            <p v-if="selected[3] || selected[4] "><label class="title">S&OP - Configurações</label>
              <label for="v_seop_confi" class="checks" v-if="selected[3]">
                Visualizar 
              </label>
              <label for="m_seop_confi" class="checks" v-if="selected[4]">
                Modificar 
              </label>
            </p>

            <p v-if="selected[5] || selected[6] "><label class="title">Budget e Forecast</label>
              <label for="v_budget" class="checks" v-if="selected[5]">
                Visualizar 
              </label>
              <label for="m_budget" class="checks" v-if="selected[6]">
                Modificar
              </label>
            </p>

            <p v-if="selected[7] || selected[8] "><label class="title">Parametros</label>
              <label for="v_parameters" class="checks" v-if="selected[7]">
                Visualizar 
              </label>
              <label for="m_parameters" class="checks" v-if="selected[8]">
                Modificar
              </label>
            </p>

            <p v-if="selected[9] || selected[10] "><label class="title">Cadastro Usuários</label>
              <label for="v_users" class="checks" v-if="selected[9]">
                Visualizar 
              </label>
              <label for="m_users" class="checks" v-if="selected[10]">
                Modificar
              </label>
            </p>

            <p v-if="selected[11] || selected[12] "><label class="title">Cadastro de Depósito</label>
              <label for="v_deposit" class="checks" v-if="selected[11]">
                Visualizar 
              </label>
              <label for="m_deposit" class="checks" v-if="selected[12]">
                Modificar
              </label>
            </p>

            <p v-if="selected[13] || selected[14] "><label class="title">Agrupador de Estoque</label>
              <label for="v_stock_grouper" class="checks" v-if="selected[13]">
                Visualizar 
              </label>
              <label for="m_stock_grouper" class="checks" v-if="selected[14]">
                MOdificar
              </label>
            </p>

            <p v-if="selected[15] || selected[16]"><label class="title">Produto Agrupador</label>
              <label for="v_product_grouper" class="checks" v-if="selected[15]">
                Visualizar 
              </label>
              <label for="m_product_grouper" class="checks" v-if="selected[16]">
                Modificar 
              </label>
            </p>

            <p v-if="selected[17] || selected[18]" ><label class="title">Etapas Lead Time</label>
              <label for="v_leadTime" class="checks" v-if="selected[17]">
                Visualizar 
              </label>
              <label for="m_leadTime" class="checks" v-if="selected[18]">
                Modificar 
              </label>
            </p>

            <p v-if="selected[19] || selected[20]" ><label class="title">Fator</label>
              <label for="v_factor" class="checks" v-if="selected[19]">
                Visualizar 
              </label>
              <label for="m_factor" class="checks" v-if="selected[20]">
                Modificar
              </label>
            </p>

            <p v-if="selected[21] || selected[22]" ><label class="title">Dólar</label>
              <label for="v_dolar" class="checks" v-if="selected[21]">
                Visualizar 
              </label>
              <label for="m_dolar" class="checks" v-if="selected[22]">
                Modificar 
              </label>
            </p>

            <p v-if="selected[23] || selected[24]" ><label class="title">Integração - Produtos</label>
              <label for="v_sync_produtos" class="checks" v-if="selected[23]">
                Visualizar 
              </label>
              <label for="m_sync_produtos" class="checks" v-if="selected[24]">
                Modificar
              </label>
            </p>

            <p v-if="selected[25] || selected[26]" ><label class="title">Integração - Fornecedores</label>
              <label for="v_sync_supplier" class="checks" v-if="selected[25]">
                Visualizar 
              </label>
              <label for="m_sync_supplier" class="checks" v-if="selected[26]">
                Modificar 
              </label>
            </p>

            <p v-if="selected[27] || selected[28]" ><label class="title">Integração - Programação</label>
              <label for="v_sync_programming" class="checks" v-if="selected[27]">
                Visualizar 
              </label>
              <label for="m_sync_programming" class="checks" v-if="selected[28]">
                Modificar 
              </label>
            </p>

            <p v-if="selected[29] || selected[30]" ><label class="title">Integração - Processos em lote</label>
              <label for="v_sync_process_lote" class="checks" v-if="selected[29]">
                Visualizar 
              </label>
              <label for="m_sync_process_lote" class="checks" v-if="selected[30]">
                Modificar 
              </label>
            </p>

            <p v-if="selected[31] || selected[32]" ><label class="title">Cenários e Simulações</label>
              <label for="v_scenario_simulation" class="checks" v-if="selected[31]">
                Visualizar 
              </label>
              <label for="m_scenario_simulation" class="checks" v-if="selected[32]">
                Modificar 
              </label>
            </p>

          </div>
        </div>
      </template>
    </b-sidebar>
  </b-navbar>
</template>

<script>
export default {
  name: "NavbarComponent",
  props: {
    title: String,
  },
  data() {
    return {
      userLogged: localStorage.getItem("userLogged")
        ? JSON.parse(localStorage.getItem("userLogged"))
        : null,

      elapsedTime: 0,
      flag_btn_start: false,
      request: false,
      description: "",
      job: {},
      dataObj: {},
      formattedTime: 0,
      messages: "",
      alerts: "",
      variant: "dark",
      selected: [],
      userLoggedPermission: localStorage.getItem("userPermission")
        ? JSON.parse(localStorage.getItem("userPermission"))
        : null,
    };
  },

  computed: {},

  mounted() {
    this.getUserPermissions()

    // this.getAlerts();
  },

  methods: {
    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },
    onClick() {
      this.$bvModal.msgBoxOk("User name: Fred Flintstone", {
        title: "User Info",
        size: "sm",
        buttonSize: "sm",
        okVariant: "success",
        headerClass: "p-2 border-bottom-0",
        footerClass: "p-2 border-top-0",
      });
    },
    // enviar dados do colaborador selecionado para o formulario para editar
    editUser(user) {
      const dataObj = user;
      console.log(dataObj);
      this.$router.push({
        name: "register",
        params: {
          dataObj: { dataObj },
        },
      });
    },
    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },

    //Três ultimas notificações recebidas
    async getAlerts() {
      await this.$http
        .get()
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            console.log();
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async getUserPermissions() {
      await this.$http
        .get(`/permissions/user/${this.userLogged.id}`)
        .then((response) => {
          console.log(response.data.user_permissions)
          this.selected = response.data.user_permissions;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
