<template>
	<div class="main">
		<PageComponent :title_nav="'Parâmetros'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Parâmetros do Sistema</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row>
							<b-col md="6">
								<h5>Parâmetros do Sistema</h5>
							</b-col>

							<b-col md="12" class="field-time">
								<b-form-group id="input-group-1" label="Duração de senha" label-for="input-1"
									description="Insira o tempo para expiração de senha.">
									<b-form-input id="input-1" type="text" placeholder="Duração de senha...." required
										v-model="parameters.time_password" oninput="this.value = this.value.replace(/[^0-9.]/g, '').replace(/(\..*?)\..*/g, '$1');"></b-form-input>
								</b-form-group>
							</b-col>


							<b-col md="6"></b-col>
							<b-col md="12" class="btn-salve">
								<b-button class="save button-padrao-add" @click="saveParameters" v-if="userLoggedPermission.permissionparametersm == 1 || userLogged.role == 'Administrador'">Salvar</b-button>
								<b-button class="button-padrao-cancel" :to="{ name: 'dashboard' }">cancelar</b-button>
							</b-col>

						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";

export default {
	name: "ParametersSystemComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			parameters: {
				time_password: '',
				id: ''
			},
			items_ids: [],
			items_order: [],
			show: true,
			backgroundRed: null,
			editingIndex: -1,
			disabled: false,
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
		};
	},

	mounted() {
		this.getParametersSystem();
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async saveParameters() {
			if(this.parameters.time_password == 0){
				this.goError("A duração de senha deve ser maior que 0");
			} else {
				const formData = new FormData();
				formData.append("time_password", this.parameters.time_password);
				formData.append("id", this.parameters.id);
				formData.append("user_id", this.userLogged.id);

				await this.$http
					.post(`/parameters/system/save`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							this.getParametersSystem();
						}
					})
					.catch((error) => {
						this.goError(error.response.data.message);
						console.log(error.response.data.message);
					});
			}

		},

		async getParametersSystem() {
			await this.$http
				.get(`/parameters/system`)
				.then((response) => {
						this.parameters = {
							time_password: response.data.parametersSystem.time_system,
							id: response.data.parametersSystem.id
					}
				})
				.catch((error) => {
					console.log(error.response);
				});
		}
	},

	computed: {
		dragOptions() {
			return {
				animation: 0,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},

	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
