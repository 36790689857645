<template>
	<div class="main">
		<PageComponent :title_nav="'Integrações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'batch_processes-index' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Listagem de Processos
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Processos em lote</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						<b-row>
							<b-col md="9">
								<h5 class="import">Importar processo</h5>
							</b-col>
							<!-- {{ processItems }} -->

							<div class="col-md-12 card">
								<table class="table-import table" :per-page="perPage" :current-page="currentPage">
									<!-- Table headers -->
									<thead>
										<tr>
											<th>Código do produto</th>
											<th>Nome</th>
											<th>Derivação</th>
											<th v-for="column in leadTimes" :key="column.id">
												{{ column.name }}
											</th>
											<th></th>
											<th></th>
										</tr>
									</thead>

									<!-- Table rows -->
									<tbody>
										<tr v-for="(item, index) in lists" :key="item.id">
											<td>
												{{ item.codPro }}
											</td>
											<td>{{ item.desPro }}</td>
											<td>{{ item.codDer }}</td>

											<td v-for="column in leadTimes" :key="column.id">
												<span v-if="editingIndex !== index">
													{{
														item.leadTimes.find(
															(lt) =>
																lt.codProd === item.codPro && lt.id_lead_time === column.id
														).days
													}}
												</span>

												<input type="text" v-else class="input-leads" v-model="item.leadTimes.find(
															(lt) =>
																lt.codProd === item.codPro && lt.id_lead_time === column.id
														).days
														" />
											</td>

											<td>
												<b-button style="text-decoration: none; color: black"
													@click="editProduct(leadTimes[indexColumn], index, column, item)" v-if="editingIndex !== index">
													<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
												</b-button>

												<b-button class="btn-check-circle" style="text-decoration: none; color: black"
													@click="saveItem(item, index)" v-else>
													<h6><font-awesome-icon :icon="['fas', 'check-circle']" /></h6>
												</b-button>
											</td>

											<td>
												<b-button style="text-decoration: none; color: black" v-if="editingIndex !== index"
													@click="deleteItem(item, index)">
													<h6><font-awesome-icon :icon="['fas', 'trash-alt']" /></h6>
												</b-button>

												<b-button class="btn-circle" style="text-decoration: none; color: black" @click="cancelEdit()"
													v-else>
													<h6><font-awesome-icon :icon="['fas', 'times-circle']" /></h6>
												</b-button>
											</td>
										</tr>
									</tbody>
								</table>

								<p class="line"></p>
							</div>

							<b-col md="2" class="date_import">
								<label for="">Data importação</label>
								<input type="text" v-model="date_import" />
							</b-col>

							<b-col md="2" class="date_import">
								<label for="">Usuário</label>
								<input type="text" v-model="user_import" />
							</b-col>

							<b-col md="12"></b-col>

							<b-col md="2" class="date_import" v-if="disabled">
								<label for="">Data aprovação</label>
								<input type="text" v-model="date_aproved" />
							</b-col>

							<b-col md="2" class="date_import" v-if="disabled">
								<label for="">Usuário</label>
								<input type="text" v-model="user_aproved" />
							</b-col>

							<b-col md="12"></b-col>
							<b-col md="6"></b-col>
						</b-row>

						<b-row class="paginations-options">
							<b-col md="6">
								<span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
							</b-col>
							<b-col md="6">
								<b-pagination pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage">
								</b-pagination>
							</b-col>
						</b-row>

						<b-row>
							<b-col md="6"></b-col>
							<b-col md="6" class="btn-salve">
								<b-button class="button-padrao-cancel" :to="{ name: 'batch_processes-index' }">cancelar</b-button>

								<b-button class="save button-padrao-add" @click="saveStock()">salvar</b-button>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";

export default {
	name: "ImportProcessComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			perPage: 6,
			currentPage: 1,
			search: "",
			processItems: [],

			leadTimes: [],
			disabled: false,
			items: [
				{ id: 1, text: "Item 1" },
				{ id: 2, text: "Item 2" },
				// ...
			],
			editingIndex: -1,
			data: 0,
			campos: ["Código do produto", "Nome", ""],
			currentDate: new Date(),
			date_import: "",
			user_import: "",
			user_aproved: "",
			date_aproved: "",
			obj: this.$route.id,
			saveLead: [],
		};
	},

	mounted() {
		this.getProcessItems();
		this.getLeadTimes();
	},

	methods: {
		editItem(index) {
			this.editingIndex = index;
		},
		saveItem(item) {
			this.editingIndex = -1;

			this.disabled = false;

			this.lists.forEach((linha) => {
				if (item.codPro == linha.codPro) {
					// linha.leadTimes.forEach((leadTime) => {

					for (let i = 0; i < this.lists.length - 1; i++) {
						console.log("aaaaaaaaaaaaaaaa");
						console.log(linha.leadTimes[i].days);

						let formData = new FormData();

						formData.append("id_lead_time", linha.leadTimes[i].id_lead_time);
						formData.append("days", linha.leadTimes[i].days);
						formData.append("codProd", item.codPro);
						formData.append("id_prod", item.prodcode);

						this.$http
							.post(`/products/leadTime/store`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.goSuccess(response.data.message);
									this.getProcessItems();
								}
							})
							.catch(function (error) {
								console.log(error);
							});
					}
				}
			});
		},

		cancelEdit() {
			this.editingIndex = -1;
			this.disabled = false;
		},

		async deleteItem(item, index) {
			if (item == undefined) {
				this.leadTimes.splice(index, 1);
			} else {
				let formData = new FormData();
				formData.append("id", item.id);
				this.$swal
					.fire({
						title: "Remover item do processo?",
						icon: "warning",
						showCancelButton: true,
						confirmButtonText: "SIM, EXCLUIR",
						cancelButtonText: `NÃO EXCLUIR`,
						confirmButtonColor: "#d33",
						cancelButtonColor: "#d33",
						customClass: {
							cancelButton:
								"btn btn-light border border-secondary rounded-pill px-md-5",
							confirmButton: "btn btn-danger rounded-pill px-md-5",
						},
						buttonsStyling: false,
					})
					.then((result) => {
						/* Read more about isConfirmed, isDenied below */
						if (result.isConfirmed) {
							this.$http
								.post(`/products/leadTime/delete`, formData)
								.then((response) => {
									if (response.data.error) {
										this.goError(response.data.message);
									} else {
										this.goSuccess(response.data.message);
										this.lists.splice(index, 1);
										location.reload();
									}
								})
								.catch((error) => {
									this.goError(error.response.data.message);
									console.log(error.response.data.message);
								});
						} else if (result.isDenied) {
							this.$swal.fire("Changes are not saved", "", "info");
						}
					});
			}
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getProcessItems() {
			await this.$http
				.get(`/processes/${this.$route.params.id}/processItems`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.processItems = response.data.processItems;
						this.date_import = moment(this.processItems[0].datImp).format(
							"DD/MM/YYYY HH:mm"
						);
						(this.user_import = this.processItems[0].name_user),
							(this.user_aproved = this.userLogged.name),
							(this.date_aproved = moment(this.currentDate).format(
								"DD/MM/YYYY HH:mm"
							));
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async getLeadTimes() {
			await this.$http
				.get(`/leadTime`)
				.then((response) => {
					console.log(response);
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.leadTimes = response.data.leadTimes;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		editProduct(item, index, column, prod) {
			this.editingIndex = index;
			this.disabled = true;

			console.log(prod);

			this.leadTimes;
		},
	},

	computed: {
		fieldsImportProcess: function () {
			let fields = [
				{
					key: "code_product",
					label: "Código do produto",
				},

				{
					key: "name",
					label: "Nome",
				},

				{
					key: "derivation",
					label: "Derivação",
				},
			];
			return fields;
		},
		lists() {
			const items = this.processItems;
			return items.slice(
				(this.currentPage - 1) * this.perPage,
				this.currentPage * this.perPage
			);
		},
		totalRows() {
			return this.processItems.length;
		},
		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.processItems.slice(start, end);
		},

		numeroDePaginas() {
			return Math.ceil(this.processItems.length / this.perPage);
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD.MM.YYYY HH:mm");
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
