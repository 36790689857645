<template>
	<div class="main">
		<PageComponent :title_nav="'Cenários e simulações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'scenarios-simulations-index' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Cenários e simulações
							</b-breadcrumb-item>
							<b-breadcrumb-item :to="{ name: 'scenarios-simulations-edit' }">Cenário</b-breadcrumb-item>
							<!-- <b-breadcrumb-item :to=" 'scenarios/simulations/edit/'+this.$route.params.id ">Novo cenário</b-breadcrumb-item> -->
							<b-breadcrumb-item active>Parâmetro de simulação</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row class="">
							<b-col md="12">
								<h5>Parâmetro de simulação</h5>
							</b-col>
						</b-row>

						<b-row class="mt-3 create-scenario">
							<b-col md="">
								<label for="">Nome</label>
								<b-form-input v-model="scenario.name" placeholder="Digite aqui"></b-form-input>
								<br />
								<label for="">Usuário</label>
								<b-form-input class="input_user" v-model="scenario.user" readonly></b-form-input>
							</b-col>

							<b-col md="">
								<label for="">Data de referência</label>
								<b-form-datepicker id="dateRef-datepicker" placeholder="" v-model="scenario.dateRef"
									locale="pt" :date-format-options="{
										year: 'numeric',
										month: 'numeric',
										day: 'numeric',
									}"></b-form-datepicker>
								<br />
								<label for="">Data de criação</label>
								<b-form-input class="input_date_create" v-model="scenario.dateCreate"
									readonly></b-form-input>
							</b-col>

							<b-col md="">
								<label for="">Observação</label>
								<b-form-textarea id="textarea" v-model="scenario.observation" placeholder="Digite aqui"
									rows="5" max-rows="10"></b-form-textarea>
							</b-col>

							<b-col md="12" class="mt-3"  style="text-align: right;">
								<b-button v-if="!scenario.id" class="save button-padrao-add" @click="saveSimulation()">
									Salvar
								</b-button>

								<b-button v-else class="save button-padrao-add" @click="updateSimulation()">
									Atuailzar
								</b-button>

							</b-col>
						</b-row>
						<b-row class="mt-5">
							<b-col md="12" class="col-table">

							</b-col>
						</b-row>

						<b-row v-if="visibleCard">
							<b-col>
								<TabSimulationComponent :quantity="quantity" :price="price" :purchase="purchase"
									:sell="sell" :factor="factor" :dolar="dolar" :is_simulation="this.simulation_id"/>
							</b-col>
						</b-row>

					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";
import TabSimulationComponent from "../scenarios/tabs/TabSimulationComponent.vue";
import QuantityComponent from "../scenarios/tabs/QuantityComponent.vue";
import PriceFOBComponent from "../scenarios/tabs/PriceFOBComponent.vue";
import OrderPurchaseComponent from "../scenarios/tabs/OrderPurchaseComponent.vue";
import SellSimulationComponent from "../scenarios/tabs_sell/SellSimulationComponent.vue";
import FactorSimulationComponent from "../scenarios/tabs_factor/FactorSimulationComponent.vue";
import DolarSimulationComponent from "../scenarios/tabs_dolar/DolarSimulationComponent.vue";
import moment from "moment";

export default {
	name: "ParamsSimulationComponent",
	components: {
		PageComponent,
		TabSimulationComponent,
	},
	data() {
		return {
			selected: false,
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			currentRouteName: "white",
			selectedSort: null,
			search: "",

			dateCurrent: new Date().toLocaleDateString(),
			selectedRow: null,
			btnParams: false,
			obj: this.$route.params.id,
			obj_simulation: this.$route.params.id_simulation,
			scenario: {
				id: null,
				name: "",
				dateRef: "",
				observation: "",
				user: "",
				dateCreate: new Date().toLocaleDateString(),
			},
			quantity: QuantityComponent,
			price: PriceFOBComponent,
			purchase: OrderPurchaseComponent,
			sell: SellSimulationComponent,
			factor: FactorSimulationComponent,
			dolar: DolarSimulationComponent,
			visibleCard: false,
			simulation_id: ''
		};
	},

	mounted() {
		// this.getScenario();
		this.scenario.user = this.userLogged.name
		this.findSimulation(this.$route.params.id_simulation)
	},

	methods: {
		noView(item) {
			console.log(item);
		},

		sendEst(item) {
			this.$router.push(`/products/${item.id}/est-seg`);
		},

		sendLeadProduct(item) {
			this.$router.push(`/products/${item.id}/config/lead`);
		},

		async orderProducts() {
			const formData = new FormData();
			formData.append("code", this.filter.code);
			formData.append("derivation", this.filter.derivation);
			formData.append("line", this.filter.line);
			formData.append("und", this.filter.und);
			formData.append("supplier", this.filter.supplier);
			formData.append("order", this.selectedSort);
		},

		async saveSimulation(){
			const formData = new FormData();
			formData.append("name", this.scenario.name);
			formData.append("observation", this.scenario.observation);
			formData.append("user_id", this.userLogged.id);
			formData.append("dateRef", this.scenario.dateRef);
			formData.append("dateCreate", this.scenario.dateCreate);
			formData.append("id_scenario", this.obj);

			this.$http
				.post(`/scenarios/store/simulation`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.visibleCard = true;
						this.simulation_id = response.data.id_simulation;
						this.findSimulation(response.data.id_simulation)

						const dataObj = {
							scenario: response.data.id_simulation,
						};
						this.$router.push({
							path: '/scenarios/'+ this.obj +'/create/simulation/' + response.data.id_simulation,
							params: {
								dataObj: { dataObj},
							},
						});

					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});		
		},

		async updateSimulation(){
			const formData = new FormData();
			formData.append("id", this.scenario.id);
			formData.append("name", this.scenario.name);
			formData.append("observation", this.scenario.observation);
			formData.append("user_id", this.userLogged.id);
			formData.append("dateRef", this.scenario.dateRef);
			formData.append("dateCreate", this.scenario.dateCreate);
			formData.append("id_scenario", this.obj);

			this.$http
				.post(`/scenarios/update/simulation`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});		
		},

		async findSimulation(id_simulation){
			await this.$http
				.get(`/scenarios/${this.$route.params.id}/simulation/${id_simulation}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.scenario.name = response.data.simulation.name;
						this.scenario.user = response.data.simulation.user;
						this.scenario.dateRef = response.data.simulation.dt_refer;
						this.scenario.observation = response.data.simulation.observation;
						this.scenario.dateCreate = moment(response.data.simulation.created_at).format('DD/MM/Y');
						this.scenario.id = response.data.simulation.id;
						this.visibleCard = true;
						this.simulation_id = response.data.id;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

	},

};
</script>

<style lang="scss" src="./style.scss" scoped />
