<template>
	<div class="component-tabs">
		<b-tabs
			content-class="mt-3"
			pills
			card
			active-nav-item-class="font-weight-bold text-uppercase text-light"
		>
			<b-tab active>
				<template #title>
					<span>Compras</span>
				</template>
				<b-tabs
					content-class="mt-3"
					pills
					card
					active-nav-item-class="font-weight-bold text-uppercase tabs-compras"
				>
					<b-tab>
						<template #title>
							<span>Quantidade</span>
						</template>
						<component :is="quantity">
							<slot />
						</component>
					</b-tab>

					<b-tab>
						<template #title>
							<span>Preço FOB</span>
						</template>
						<component :is="price">
							<slot />
						</component>

					</b-tab>

					<b-tab>
						<template #title>
							<span>Ordem de compra</span>
						</template>
						<component :is="purchase">
							<slot />
						</component>
					</b-tab>
				</b-tabs>
			</b-tab>

			<b-tab>
				<template #title>
					<span>Vendas</span>
				</template>
				<br />
				<component :is="sell">
					<slot />
				</component>
			</b-tab>

			<b-tab>
				<template #title>
					<span>Fator</span>
				</template>
				<br />
				<component :is="factor">
					<slot />
				</component>
			</b-tab>

			<b-tab>
				<template #title>
					<span>Dólar</span>
				</template>
				<br />
				<component :is="dolar">
					<slot />
				</component>
			</b-tab>
		</b-tabs>
	</div>
</template>

<script>
export default {
	name: "TabSimulationComponent",
	props: {
		stock: {
			type: [String, Object],
			default: "div",
		},

		quantity: {
			type: [String, Object],
			default: "div",
		},

		price: {
			type: [String, Object],
			default: "div",
		},

		purchase: {
			type: [String, Object],
			default: "div",
		},

		sell: {
			type: [String, Object],
			default: "div",
		},
		factor: {
			type: [String, Object],
			default: "div",
		},
		dolar: {
			type: [String, Object],
			default: "div",
		},

		is_simulation: {
			type: [String, String],
			default: ''
		}
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			openCollapse: null,
		};
	},

	methods: {
		toggleCollapse(collapseNumber) {
			if (this.isCollapseOpen(collapseNumber)) {
				this.openCollapse = null; // Fechar o collapse se já estiver aberto
			} else {
				this.openCollapse = collapseNumber; // Abrir o collapse
			}
		},
		isCollapseOpen(collapseNumber) {
			return this.openCollapse === collapseNumber;
		},

	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
