<template>
  <div class="main">
    <PageComponent :title_nav="'Integrações'">
      <div slot="slot-pages" class="content-pages">
        <div class="products">
          <div class="products-header">
            <!-- breadcrumb -->
            <b-breadcrumb>
              <b-breadcrumb-item :to="{ name: 'supplier-index' }"><font-awesome-icon :icon="['fas', 'arrow-left']" /> Listagem de Fornecedores</b-breadcrumb-item>
              <b-breadcrumb-item active>Detalhes do fornecedor</b-breadcrumb-item>
            </b-breadcrumb>
            <!-- breadcrumb -->

            <b-row class="mt-4">
              <b-col md="8">
                <h5>Detalhes do fornecedor</h5>
                <p class="subtitle"> Abaixo estão as Informações do fornecedor </p>
              </b-col>
              <b-col md="12" class="col-details">
                <b-card>
                  <div class="data-supplier">
                    <h3> {{ supplier.name }} </h3>
                    <p> {{ supplier.codFor }} </p>
                  </div>

                  <p class="form"> Dados da empresa </p>
                  <b-row>
                    <b-col md="6">
                      <b-form-input v-model="supplier.document" placeholder="CNPJ" readonly></b-form-input>
                    </b-col>

                    <b-col md="6">
                      <b-form-input v-model="text" placeholder="Ramo de atividade" readonly></b-form-input>
                    </b-col>

                    <b-col md="12" class="mt-4">
                      <b-form-input v-model="supplier.apeFor" placeholder="Nome fantasia" readonly></b-form-input>
                    </b-col>

                    <b-col md="6" class="mt-4" v-if="supplier.international">
                      <b-form-input v-model="text" placeholder="Internacional" readonly></b-form-input>
                    </b-col>

                    <b-col md="6" class="mt-4" v-if="supplier.international">
                      <b-form-input v-model="text" placeholder="Porto de origem" readonly></b-form-input>
                    </b-col>

                    <b-col md="6" class="mt-4">
                      <b-form-input v-model="supplier.lead_time" placeholder="Lead time"></b-form-input>
                    </b-col>

                    <b-col md="6" class="mt-4">
                      <b-form-input v-model="supplier.transit_time" placeholder="Transit time"></b-form-input>
                    </b-col>
                  </b-row>

                  <p class="form mt-5"> Dados do responsável </p>
                  <b-row>
                    <b-col md="6">
                      <b-form-input v-model="supplier.nomFor" readonly placeholder="Nome do responsável"></b-form-input>
                    </b-col>

                    <b-col md="6">
                      <b-form-input v-model="text" readonly placeholder="Função"></b-form-input>
                    </b-col>

                    <b-col md="6" class="mt-4">
                      <b-form-input v-model="supplier.email" placeholder="Email" readonly></b-form-input>
                    </b-col>

                    <b-col md="6" class="mt-4">
                      <b-form-input v-model="supplier.phone" placeholder="Telefone" readonly></b-form-input>
                    </b-col>

                    <b-col md="12" class="mt-4">
                      <b-form-input v-model="supplier.address" readonly placeholder="Endereço"></b-form-input>
                    </b-col>
                  </b-row>

                  <p class="form mt-5"> Dados Bancários </p>
                  <b-row>
                    <b-col md="6">
                      <b-form-input v-model="text" placeholder="Banco" readonly></b-form-input>
                    </b-col>

                    <b-col md="6">
                      <b-form-input v-model="text" placeholder="Conta" readonly></b-form-input>
                    </b-col>

                    <b-col md="12" class="mt-4">
                      <b-form-textarea v-model="text" readonly placeholder="Informações adicionais"></b-form-textarea>
                    </b-col>
                  </b-row>
                </b-card>
              </b-col>
            </b-row>
            <b-row>
              <b-col class="buttons">
                <b-button class="save button-padrao-add" @click="saveSupplier()" v-if="userLoggedPermission.permissionsyncsupplierm == 1 || userLogged.role == 'Administrador'">Salvar</b-button>
                <b-button class="button-padrao-cancel" :to="{ name: 'supplier-index' }">Voltar</b-button>

              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </PageComponent>
  </div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
  name: "SupplierViewComponent",
  components: {
    PageComponent,
  },
  data() {
    return {
      suppliers: [],
      supplier: {
        transit_time: '',
        lead_time: '',
        name: '',
        email: '',
        codFor: '',
        document: '',
        apeFor: '',
        nomFor: '',
        phone: '',
        address: ''
      },
      obj: this.$route.id,
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
      userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
    };
  },

  mounted() {
    this.getSupplier();
  },

  methods: {
    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },

    async getSupplier() {
      await this.$http
        .get(`/suppliers/${this.$route.params.id}`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.supplier = response.data.supplier;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    async saveSupplier() {
      if (this.supplier.transitTime == "" || this.supplier.leadtTime == "") {
        this.goError("Preencha todos os campos");
      } else {
        let formData = new FormData();
        formData.append("transitTime", this.supplier.transit_time);
        formData.append("leadtTime", this.supplier.lead_time);
        formData.append("id",  this.$route.params.id );
        await this.$http
          .post(`/suppliers/store`, formData)
          .then((response) => {
            if (response.data.error) {
              this.goError(response.data.message);
            } else {
              this.goSuccess("Fornecedor atualizado com successo");
              this.getSupplier();
            }
          })
          .catch(function (error) {
            console.log(error);
          });
      }
    }

  },

  computed: {
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
