<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active
								>Produto agrupador</b-breadcrumb-item
							>
						</b-breadcrumb>
						<!-- breadcrumb -->

            <b-row>
              <b-col md="6">
                <h5>Produto agrupador</h5>
              </b-col>

              <b-col md="3" class="col-search">
									<div class="form">
										<b-form-input placeholder="Procurar" v-model="search"></b-form-input>
										<button type="submit">Search</button>
									</div>

              </b-col>

              <b-col class="col-add" v-if="userLoggedPermission.permissionproductGrouperm == 1 ||  userLogged.role == 'Administrador'">
                <b-button
                  class="button-padrao-add"
                  :to="{ name: 'product-grouper-register' }"
                >
                  <font-awesome-icon :icon="['fas', 'plus']" /> Novo
                  produto agrupador</b-button
                >
              </b-col>

              <b-col md="12" class="col-table">
                <b-card>
                  <b-table
                    :items="itemsFiltradosPaginados"
                    :fields="fieldsProduct"
                    responsive=""
                    class="table-participants"
                  >
                    <template #cell(name)="data">
                      <p>
                        {{ data.item.name }}
                      </p>
                    </template>

                    <template #cell(qtd)="data">
                      <p>
                        {{ data.item.product_count }}
                      </p>
                    </template>

                    <template #cell(actions)="data">
                      <b-button @click="viewProduct(data.item)">
                        <font-awesome-icon :icon="['fas', 'eye']" />
                      </b-button>

                      <b-button @click="editProduct(data.item)" v-if="userLoggedPermission.permissionproductGrouperm == 1 ||  userLogged.role == 'Administrador'">
                        <font-awesome-icon :icon="['fas', 'pen']" />
                      </b-button>
                      <b-button @click="deleteProduct(data.item.id)" v-if="userLoggedPermission.permissionproductGrouperm == 1 ||  userLogged.role == 'Administrador'">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </b-button>
                    </template>
                  </b-table>
                </b-card>
              </b-col>

              <b-col md="12" class="mt-3">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
              </b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>

    <b-modal
      id="modalInfo"
      content-class="your-class"
      ref="my-modal"
      hide-footer
      title=""
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" @click="close()">
          <font-awesome-icon :icon="['fas', 'times']" />
        </b-button>
      </template>

      <div class="d-block text-left">
        <h3>{{ selectedProduct.name }}</h3>
      </div>

      <div class="d-block text-center">
        <b-table
          :items="products"
          :fields="fields"
          responsive=""
          class="table-stocks"
        >
          <template #cell(name)="data">
            <p>
              {{ data.item.product.name }}
            </p>
          </template>
          <template #cell(code)="data">
            <p>
              {{ data.item.product.code }}
            </p>
          </template>

          <template #cell(derivation)="data">
            <p>
              {{ data.item.product.derivation }}
            </p>
          </template>

        </b-table>
      </div>
      <div class="d-block text-center">
        <b-button class="button-padrao-cancel" size="sm" @click="hideModal">Fechar</b-button>
      </div>
    </b-modal>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
	name: "ProdutGrouperIndexComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			dataObj: {
				collator: [],
				name: "",
			},
			selected: [],
			newStock: [],
			isDisableAll: false,
			isDisableOne: false,
			stocks: [],
			searchAdd: "",
			search: "",
			stockCollators: [],
			products: [],
			product_groups: [],
			selectedProduct: [],
      userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
      userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
      paginaAtual: 1,
			itensPorPagina: 5,
		};
	},

	mounted() {
		this.getProductsGroup();
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getProductsGroup() {
      await this.$http
        .get(`/productsGroup`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.product_groups = response.data.product_groups;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
		},

		async getProducts(id) {
      await this.$http
        .get(`/productsGroup/${id}/products`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.products = response.data.products;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
		},

    viewProduct(obj) {
      this.selectedProduct = obj;
      this.showModal();
      this.getProducts(obj.id);
    },

    editProduct(obj) {
      const dataObj = obj;
      this.$router.push({
        name: "product-grouper-register",
        params: {
          dataObj: { dataObj },
        },
      });
    },

		async deleteProduct(id) {
      let formData = new FormData();
        formData.append("id", id);

      await this.$http
        .post(`/productsGroup/delete`, formData)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.goSuccess(response.data.message);
              this.deleteItems(id);
          }
        })
        .catch((error) => {
          this.goError(error.response.data.message);
          console.log(error.response.data.message);
        });
		},

    async deleteItems(product_group) {
      let formData = new FormData();
        formData.append("product_group", product_group);
      await this.$http
        .post(`/productsGroup/item/delete`, formData)
        .then((response) => {

        if (response.data.error) {
          this.goError(response.data.message);
        } else {
          this.goSuccess(
            response.data.message
          );
            this.getProductsGroup();
        }

        })
        .catch(function (error) {
          console.log(error);
        });
    },

    showModal() {
      this.$refs["my-modal"].show();
    },

    hideModal() {
      this.$refs["my-modal"].hide();
      this.products = [];
      this.selectedProduct = "";
    },

	},

	computed: {
		filteredList() {
			return this.newStock.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
		fieldsProduct: function () {
			let fields = [

				{
					key: "name",
					label: "Nome",
				},

				{
					key: "qtd",
					label: "Qtde. de produtos agrupados",
				},

				{
					key: "actions",
					label: "Ações",
				},

			];
			return fields;
		},

    fields: function () {
      let fields = [
        { key: "code", label: "Código" },
        { key: "derivation", label: "Derivação" },

        {
          key: "name",
          label: "Nome",
        },
      ];
      return fields;
    },

    totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.product_groups.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
