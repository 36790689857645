<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="users">
					<div class="users-header create-stock">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'product-grouper-index' }"
								> <font-awesome-icon :icon="['fas', 'arrow-left']" /> Produto agrupador</b-breadcrumb-item
							>
							<b-breadcrumb-item active> Novo produto agrupador</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						<h3>Novo produto agrupador</h3>
						<p class="dados">Dados do agrupador</p>
						<b-card class="card-add-name">
							<b-row>
								<b-col md="12">
									<label for="">Nome</label>
									<b-form-input
										placeholder="Digite aqui"
										v-model="dataObj.name"
									></b-form-input>
								</b-col>

								<b-col md="6" style="display: none;">
									<label for="">Código</label>
									<b-form-input
										placeholder="Digite aqui"
										v-model="dataObj.code"
									></b-form-input>
								</b-col>
							</b-row>
						</b-card>

						<b-row>
							<b-col md="5">
								<b-card class="card-stocks">
									<p>Todos Produtos</p>
									<div class="form">
										<b-form-input
											placeholder="Procurar"
											v-model="searchAdd"
										></b-form-input>
										<button type="submit">Search</button>
									</div>

									<div class="list-stocks">
										<ul class="topics">
											<li v-for="item in filteredListProducts" :key="item.id">
												<input
													type="checkbox"
													:id="item.id"
													:value="item"
													v-model="selected"
												/>
												<label :for="item.id">
													<font-awesome-icon :icon="['fas', 'layer-group']" />
													{{ item.code }} - {{ item.derivation }} - {{ item.name }} </label
												>
											</li>
										</ul>
									</div>
								</b-card>
							</b-col>

							<b-col md="2" class="btn-actions">
								<div>
									<b-button @click="addAll" :disabled="isDisableAll">
										<font-awesome-icon :icon="['fas', 'angle-double-right']" />
									</b-button>
								</div>

								<div>
									<b-button @click="addOne" :disabled="isDisableOne">
										<font-awesome-icon :icon="['fas', 'angle-right']" />
									</b-button>
								</div>

								<div>
									<b-button @click="toGoBackOne">
										<font-awesome-icon :icon="['fas', 'angle-left']" />
									</b-button>
								</div>

								<div>
									<b-button @click="toGoBackAll">
										<font-awesome-icon :icon="['fas', 'angle-double-left']" />
									</b-button>
								</div>
							</b-col>

							<b-col md="5">
								<b-card class="card-stocks">
									<p>Produtos agrupados</p>
									<div class="form">
										<b-form-input placeholder="Procurar" v-model="search"></b-form-input>
										<button type="submit">Search</button>
									</div>
									<small class="text-muted" v-if="this.newProductGroup == ''"
										>Selecione os produtos que deseja agrupar.</small
									>

									<div class="list-stocks">
										<ul class="topics">
											<li v-for="item in filteredList" :key="item.id">
												<input
													type="checkbox"
													:id="item.id"
													:value="item"
													v-model="selected"
												/>
												<label :for="item.id">
													<font-awesome-icon :icon="['fas', 'layer-group']" />
													{{ item.code }} - {{ item.derivation }} - {{ item.name }} </label
												>
											</li>
										</ul>
									</div>
								</b-card>
							</b-col>

							<b-col md="6"></b-col>
							<b-col md="6" class="btn-salve">
								<b-button
									class="save button-padrao-add"
									@click="saveProduct"
									v-if="!this.dataObj.id"
									>salvar</b-button
								>
								<b-button class="save button-padrao-add" @click="updateProduct" v-else
									>Atualizar</b-button
								>
								<br />
								<b-button
									class="button-padrao-cancel"
									:to="{ name: 'product-grouper-index' }"
									>cancelar</b-button
								>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>

		<b-modal
			id="modalInfo"
			content-class="your-class"
			ref="my-modal"
			hide-footer
			title=""
		>
			<template #modal-header="{ close }">
				<!-- Emulate built in modal header close button action -->
				<b-button size="sm" @click="close()">
					<font-awesome-icon :icon="['fas', 'times']" />
				</b-button>
			</template>

			<div class="d-block text-left">
				<h3>{{ selectedStock.name }}</h3>
			</div>

			<div class="d-block text-center">
				<b-table
					:items="stocks"
					:fields="fields"
					responsive=""
					class="table-stocks"
				>
					<template #cell(name)="data">
						<p>
							{{ data.item.name }}
						</p>
					</template>
					<template #cell(code)="data">
						<p>
							{{ data.item.code }}
						</p>
					</template>
				</b-table>
			</div>
			<div class="d-block text-center">
				<b-button class="button-padrao-cancel" size="sm" @click="hideModal"
					>Fechar</b-button
				>
			</div>
		</b-modal>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
	name: "ProdutGrouperCreateComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			dataObj: {
				product: [],
				name: "",
				code: "",
			},
			selected: [],
			newProductGroup: [],
			isDisableAll: false,
			isDisableOne: false,
			stocks: [],
			searchAdd: "",
			search: "",
			stockCollators: [],
			selectedStock: "",
			obj: this.$route.params.dataObj,
			products: [],
		};
	},

	mounted() {
		this.getProducts();
		this.editProduct();
	},

	methods: {
		//TODOS OS AGRUPADORES DISPONIVEIS
		async getProducts() {
			await this.$http
				.get(`/products/allProducts`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.products = response.data.products;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		// SALVAR AGRUPADOR DE ESTOQUE
		async saveProduct() {
			console.log(this.dataObj);
			if (
				this.dataObj.name == "" ||
				this.dataObj.product == []
			) {
				this.goError("Preencha todos os campos");
			} else {
				let formData = new FormData();
				formData.append("name", this.dataObj.name);
				await this.$http
					.post(`/productsGroup/store`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							var product_group_id = response.data.product_group.id;
							this.dataObj.product.map((products) => {
								this.storeItems(products, product_group_id);
							});

						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async storeItems(product, product_group_id) {
			let formData = new FormData();
			formData.append("product_id", product.id);
			formData.append("product_group_id", product_group_id);
			await this.$http
				.post(`/productsGroup/item/store`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.$router.push("/product-grouper");
						this.dataObj.product = [];
						this.dataObj.name = "";
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		// editar dados do totalizador
		editProduct() {
			this.dataObj = this.obj.dataObj;
			console.log(this.dataObj);
			this.getProductGroupId(this.dataObj.id);
		},

		//atualizar totalizador
		async updateProduct() {
			if (
				this.dataObj.name == "" ||
				this.dataObj.product == []
			) {
				this.goError("Preencha todos os campos");
			} else {
				let formData = new FormData();
				formData.append("name", this.dataObj.name);
				formData.append("id", this.dataObj.id);
				await this.$http
					.post(`/productsGroup/update`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							var product_group_id = response.data.product_group.id;

							if(this.newProductGroup != []){
								this.newProductGroup.map((products) => {
									this.storeItems(products, product_group_id);
								});
							}

						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},
		//traz lista de agrupadores
		async getProductGroupId(id) {
			await this.$http
				.get(`/productsGroup/products/${id}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						response.data.product_groups.forEach((a) => {
							this.newProductGroup.push(a.product);
						});

						this.newProductGroup.forEach((a) => {
							this.products = this.products.filter((item) => {
								return item.id !== a.id;
							});
						});
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		// SELECIONAR TODOS OS ESTOQUES
		addAll() {
			this.products.forEach((item) => {
				this.newProductGroup.push(item);
			});
			this.products.forEach((a) => {
				this.products = this.products.filter((item) => {
					return item !== a;
				});
			});
			this.dataObj.product = this.newProductGroup;

			this.isDisableAll = true;
			this.isDisableOne = true;
		},

		// RETORNAR TODOS OS ESTOQUES
		toGoBackAll() {
			this.newProductGroup.forEach((item) => {
				this.products.push(item);
			});
			this.newProductGroup.forEach((a) => {
				this.newProductGroup = this.newProductGroup.filter((item) => {
					return item !== a;
				});
			});

			this.dataObj.product = [];

			this.isDisableOne = false;
			this.isDisableAll = false;
		},

		// SELECIONAR UM ESTOQUE POR VEZ
		addOne() {
			console.log(this.selected);
			// var stockArray = this.selected;
			var stockObjc = this.selected;
			this.selected.map((file) => {
				this.newProductGroup.push(file);
			});
			stockObjc.forEach((a) => {
				this.products = this.products.filter((item) => {
					return item !== a;
				});
			});

			this.dataObj.product = this.newProductGroup;

			this.selected = [];
		},

		// RETORNAR UM ESTOQUE POR VEZ
		toGoBackOne() {
			var stockObjc = this.selected;
			stockObjc.map((file) => {
				this.products.push(file);
			});

			stockObjc.forEach((a) => {
				this.newProductGroup = this.newProductGroup.filter((item) => {
					return item.id !== a.id;
				});
			});

			stockObjc.forEach((a) => {
				this.dataObj.product = this.dataObj.product.filter((item) => {
					return item.id !== a.id;
				});
			});

			this.isDisableOne = false;
			this.isDisableAll = false;
			this.selected = [];
		},

		//visualizar stocks dentro do agrupador
		viewStock(obj) {
			this.products = obj;
			this.showModal();
			this.getStocks(obj.id);
		},
		showModal() {
			this.$refs["my-modal"].show();
		},
		hideModal() {
			this.$refs["my-modal"].hide();
			this.stocks = [];
			this.products = "";
		},

		//visualizar stocks dentro do agrupador
		async getStocks(id) {
			await this.$http
				.get(`/stockCollator/${id}/stocks`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						response.data.stocks.forEach((a) => {
							this.stocks.push(a.stock);
						});
						console.log(this.stocks);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
	},

	computed: {
		filteredList() {
			return this.newProductGroup.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListProducts() {
			return this.products.filter((product) => {
				return product.name.toLowerCase().includes(this.searchAdd.toLowerCase()) || product.code.includes(this.searchAdd);
			});
		},
		fields: function () {
			let fields = [
				{ key: "code", label: "Código" },

				{
					key: "name",
					label: "Nome",
				},
			];
			return fields;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
