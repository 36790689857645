<template>
  <div class="main">
    <PageComponent :title_nav="'Usuários'">
      <div slot="slot-pages" class="content-pages">
        <div class="users">
          <div class="users-header">
            <b-button class="button-padrao-add" :to="(name = 'users/register')" v-if="userLoggedPermission.permissionusersm == 1 || userLogged.role == 'Administrador'">
              <font-awesome-icon :icon="['fas', 'plus']" /> Novo usuário
            </b-button>

            <b-button class="btn-profile button-padrao-borda-azul" v-b-toggle.info_user> Perfil </b-button>

            <b-button
              class="tab-list"
              :class="[inactive, 'my-btn']"
              @click="changeList"
              :disabled="isDisabledCard"
            >
              <font-awesome-icon :icon="['fas', 'border-all']" />
            </b-button>

            <b-button
              class="tab-list"
              :class="[active, 'my-btn']"
              @click="changeList"
              :disabled="isDisabledList"
            >
              <font-awesome-icon :icon="['fas', 'bars']" />
            </b-button>

            <!-- <b-form-select v-model="selected" class="mb-3 select-users">
              <b-form-select-option :value="null"
                >Usuários recentes</b-form-select-option
              >
            </b-form-select> -->
          </div>

          <TabCardComponent :method="deleteUser" :hidden="hiddenCard" :users="users" />
          <TabListComponent
            :method="deleteUser"
            :hidden="hiddenList"
            :users="users"
          />
        </div>
      </div>
    </PageComponent>
  </div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import TabCardComponent from "./card/TabCardComponent.vue";
import TabListComponent from "./list/TabListComponent.vue";

export default {
  name: "UserComponent",
  components: {
    PageComponent,
    TabCardComponent,
    TabListComponent,
  },
  data() {
    return {
      selected: null,
      active: "tab-active",
      inactive: "tab-inactive",
      isDisabledList: true,
      isDisabledCard: false,
      hiddenCard: true,
      hiddenList: false,
      users: "",
      userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
      userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
    };
  },

  mounted() {
    this.getUsers();
  },

  methods: {
    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },

    changeList() {
      if (this.active === "tab-active") {
        this.active = "tab-inactive";
        this.inactive = "tab-active";

        this.isDisabledList = false;
        this.isDisabledCard = true;

        this.hiddenCard = false;
        this.hiddenList = true;
      } else {
        this.active = "tab-active";
        this.inactive = "tab-inactive";

        this.isDisabledCard = false;
        this.isDisabledList = true;

        this.hiddenCard = true;
        this.hiddenList = false;
      }
    },

    async getUsers() {
      await this.$http
        .get(`/user`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.users = response.data.users;
          }
        })
        .catch((error) => {
          this.goError(error.response.data.message);
          console.log(error.response.data.message);
        });
    },

    async deleteUser(user) {
      const formData = new FormData();
      formData.append("id", user);
      await this.$http
        .post(`/user/delete`, formData)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.goSuccess(response.data.message);
            this.getUsers();
          }
        })
        .catch((error) => {
          console.log(error);
        });
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
