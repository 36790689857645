<template>
	<div class="main">
		<PageComponent :title_nav="'Integrações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Listagem de programação</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row>
							<b-col md="9">
								<h5>Listagem de programação</h5>
							</b-col>

							<b-col md="3" class="col-add-programming" v-if="userLoggedPermission.permissionsyncprogrammingm == 1 || userLogged.role == 'Administrador'">
								<b-button class="add-fator" :to="{ name: 'programming-create' }">
									<font-awesome-icon :icon="['fas', 'plus']" class="" />
									Nova programação
								</b-button>
							</b-col>

							<div class="col-md-12 card">
								<b-table :items="programming_masters" :fields="fieldsProduct" responsive="" class="table-programming">
									<template #cell(week)="data">
										{{ data.item.weekday }} - {{ data.item.year }}
									</template>

									<template #cell(user)="data">
										{{ data.item.user_name }}
									</template>

									<template #cell(date_initial)="data">
										{{ data.item.date_initial | dateFormat }}
									</template>

									<template #cell(date_final)="data">
										{{ data.item.date_final | dateFormat }}
									</template>

									<template #cell(actions)="data">
										<b-button @click="editDolar(data.item)" style="text-decoration: none; color: black" v-if="userLoggedPermission.permissionsyncprogrammingm == 1 || userLogged.role == 'Administrador'">
											<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
										</b-button>

										<b-button @click="removeProgramming(data.item)" style="text-decoration: none; color: black" v-if="userLoggedPermission.permissionsyncprogrammingm == 1 || userLogged.role == 'Administrador'">
											<h6><font-awesome-icon :icon="['fas', 'trash-alt']" /></h6>
										</b-button>
									</template>
								</b-table>

								<p class="line"></p>
							</div>

							<b-col md="1"></b-col>

							<b-col md="6"></b-col>
						</b-row>

						<b-row class="paginations-options1 mt-4">
							<b-col md="6">
								<span class="mt-3">Resultados {{ paginatedCards.length }} de {{
									Math.ceil(programming_masters.length) }}</span>
							</b-col>
							<b-col md="6">
								<b-pagination v-model="currentPage" :total-rows="programming_masters.length" :per-page="perPage" style="float: right;">
								</b-pagination>

							</b-col>

						</b-row>

					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";

export default {
	name: "ProgrammingIndexComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			dolars: [],
			checkbox: true,
			perPage: 6,
			currentPage: 1,
			search: '',
			filterSelect: null,
			next: '',
			previous: '',
			optionSelect: null,

			programming_masters: [],
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
		};
	},

	mounted() {
		this.getProgramming();
	},

	methods: {
		toggleCheckbox() {
			this.checkbox = !this.checkbox;
			this.$emit("setCheckboxVal", this.checkbox);
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getDolar() {
			await this.$http
				.get(`/dolar`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.dolars = response.data.dolars;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async getProgramming() {
			await this.$http
				.get(`/programming`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.programming_masters = response.data.programming_masters;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async editDolar(item) {
			console.log(item)
			this.$router.push({
				path: `programming/${item.id}/edit`,
				params: {
					dataObj: { item }
				},
			});

		},
		// REMOVER PROGRAMAÇÃO
		async removeProgramming(item) {
			const formData = new FormData();
			formData.append("id", item.id);

			await this.$swal
				.fire({
					text:
						"Remover Programação",
					title: "Deseja continuar?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "SIM, REMOVER",
					cancelButtonText: `CANCELAR`,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#d33",
					customClass: {
						cancelButton:
							"btn btn-light rounded-pill px-md-5",
						confirmButton: "btn btn-success rounded-pill px-md-5",
					},
					buttonsStyling: false,
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$http
							.post(`/programming/delete`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.goSuccess(response.data.message);
									location.reload();
								}
							})
							.catch((error) => {
								this.goError(error.response.data.message);
								console.log(error.response.data.message);
							});
					}
				});
		}

	},

	computed: {
		fieldsProduct: function () {
			let fields = [
				{
					key: "week",
					label: "Semana",
				},

				{
					key: "date_initial",
					label: "Data Inicial",
				},

				{
					key: "date_final",
					label: "Data final",
				},

				{
					key: "actions",
					label: "",
				},
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.programming_masters.slice(start, end);
		},

		filteredList() {
			return this.programming_masters.filter((dolar) => {
				return dolar.weekday.toLowerCase().includes(this.search.toLowerCase());
			});
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD.MM.YYYY");
		},

		weekFormat: function (date) {
			return moment().locale('br').isoWeekday(date);
		},

	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
