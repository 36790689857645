<template>
  <div class="main">
    <PageComponent :title_nav="'Cadastros'">
      <div slot="slot-pages" class="content-pages">
        <div class="users">
          <div class="users-header cad-collator">
            <!-- breadcrumb -->
            <b-breadcrumb>
              <b-breadcrumb-item :to="{ name: 'dashboard' }">
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                Cadastros
              </b-breadcrumb-item>
              <b-breadcrumb-item active>Totalizador de estoque</b-breadcrumb-item>
            </b-breadcrumb>
            <!-- breadcrumb -->
            <b-row>
              <b-col md="6">
                <h5>Totalizador de estoque</h5>
              </b-col>

              <b-col md="3" class="col-search">
									<div class="form">
										<b-form-input placeholder="Procurar"></b-form-input>
										<button type="submit">Search</button>
									</div>

              </b-col>

              <b-col class="col-add">
                <b-button
                  class="button-padrao-add"
                  :to="{ name: 'stock-totalizer-register' }"
                >
                  <font-awesome-icon :icon="['fas', 'plus']" /> Novo
                  totalizador</b-button
                >
              </b-col>

              <b-col md="12" class="col-table">
                <b-card>
                  <b-table
                    :items="stockCollators"
                    :fields="fieldsStock"
                    responsive=""
                    class="table-participants"
                  >
                    <template #cell(name)="data">
                      <p>
                        {{ data.item.name }}
                      </p>
                    </template>

                    <template #cell(qtd)="data">
                      <p>
                        {{ data.item.qtd }}
                      </p>
                    </template>

                    <template #cell(actions)="data">
                      <b-button @click="viewStock(data.item)">
                        <font-awesome-icon :icon="['fas', 'eye']" />
                      </b-button>

                      <b-button @click="editStock(data.item)">
                        <font-awesome-icon :icon="['fas', 'pen']" />
                      </b-button>
                      <b-button @click="deleteStock(data.item.id)">
                        <font-awesome-icon :icon="['fas', 'trash-alt']" />
                      </b-button>
                    </template>
                  </b-table>
                </b-card>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </PageComponent>
    <b-modal
      id="modalInfo"
      content-class="your-class"
      ref="my-modal"
      hide-footer
      title=""
    >
      <template #modal-header="{ close }">
        <!-- Emulate built in modal header close button action -->
        <b-button size="sm" @click="close()">
          <font-awesome-icon :icon="['fas', 'times']" />
        </b-button>
      </template>

      <div class="d-block text-left">
        <h3>{{ selectedStock.name }}</h3>
      </div>

      <div class="d-block text-center">
        <b-table
          :items="stocks"
          :fields="fields"
          responsive=""
          class="table-stocks"
        >
          <template #cell(name)="data">
            <p>
              {{ data.item.stock_collator.name }}
            </p>
          </template>
          <template #cell(code)="data">
            <p>
              {{ data.item.stock_collator.id }}
            </p>
          </template>
        </b-table>
      </div>
      <div class="d-block text-center">
        <b-button class="button-padrao-cancel" size="sm" @click="hideModal">Fechar</b-button>
      </div>
    </b-modal>
  </div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
  name: "StockTotalizerComponent",
  components: {
    PageComponent,
  },
  data() {
    return {
      stockCollators: [],
      selectedStock: "",
      stocks: [],
    };
  },

  mounted() {
    this.getStockTotalizers();
  },

  methods: {
    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },

    // SALVAR AGRUPADOR DE ESTOQUE
    async getStockTotalizers() {
      await this.$http
        .get(`/stockTotalizer`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.stockCollators = response.data.stock_totalizer;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    viewStock(obj) {
      this.selectedStock = obj;
      this.showModal();
      this.getStocks(obj.id);
    },
    showModal() {
      this.$refs["my-modal"].show();
    },
    hideModal() {
      this.$refs["my-modal"].hide();
      this.stocks = [];
      this.selectedStock = "";
    },

    async getStocks(id) {
      await this.$http
        .get(`/stockTotalizer/${id}/stocksCollators`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.stocks = response.data.stocks;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    // enviar dados selecionado para o formulario para editar
    editStock(obj) {
      const dataObj = obj;
      this.$router.push({
        name: "stock-totalizer-register",
        params: {
          dataObj: { dataObj },
        },
      });
    },

    async deleteStock(id) {
      let formData = new FormData();
        formData.append("id", id);

      await this.$http
        .post(`/stockTotalizer/delete`, formData)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.goSuccess(response.data.message);
            // this.getStockTotalizers();
              this.deleteItems(id);
          }
        })
        .catch((error) => {
          this.goError(error.response.data.message);
          console.log(error.response.data.message);
        });
    },

    async deleteItems(stock_collator) {
      let formData = new FormData();
        formData.append("stock_collator", stock_collator);
      await this.$http
        .post(`/stockTotalizer/item/delete`, formData)
        .then((response) => {

        if (response.data.error) {
          this.goError(response.data.message);
        } else {
          this.goSuccess(
            response.data.message
          );
            this.getStockTotalizers();
        }

        })
        .catch(function (error) {
          console.log(error);
        });
    },

  },

  computed: {
    fieldsStock: function () {
      let fields = [
        { key: "name", label: "Totalizadores" },

        {
          key: "qtd",
          label: "Qtde de agrupadores totalizados",
        },
        {
          key: "actions",
          label: "Ações",
          // variant: "danger",
        },
      ];
      return fields;
    },

    fields: function () {
      let fields = [
        { key: "code", label: "Código" },

        {
          key: "name",
          label: "Nome",
        },
      ];
      return fields;
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
