<template>
  <div class="component-tabs">
    <b-row class="mt-6">
      <b-col md="4" v-for="item in this.itemsFiltradosPaginados" :key="item.id">
        <b-card>
          <span></span>
          <b-row>

            <b-col md="12" class="info-user">
              <p class="name">
                {{ item.name }}
              </p>
            </b-col>

            <b-col md="12" class="info-user">
              <p class="">
                <font-awesome-icon :icon="['fas', 'ellipsis-h']" />
                {{ item.code }}
              </p>
            </b-col>

            <b-col md="12" class="info-user" v-if="item.phone">
              <p class="">
                <font-awesome-icon :icon="['fas', 'phone']" />
                {{ item.phone }}
              </p>
            </b-col>

            <b-col md="12" class="info-user" v-if="item.email">
              <p class="">
                <font-awesome-icon :icon="['fas', 'envelope']" />
                {{ item.email }}
              </p>
            </b-col>

            <b-col class="view-supplier">
              <b-button @click="viewSupplier(item)">
                Visualizar
              </b-button>
            </b-col>
          </b-row>

        </b-card>
      </b-col>
    </b-row>

    <b-rown class="paginations-options1 mt-4" :hidden="hiddenList">
      <!-- <b-col md="6">
        <span class="mt-3">Resultados {{ currentPage }} de {{ Math.ceil(perPage) }}</span>

      </b-col> -->
      <b-col md="12">
        <!-- <b-pagination pills v-model="currentPage" :total-rows="suppliers.length" :per-page="perPage">
        </b-pagination> -->
        <b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>

      </b-col>
    </b-rown>

  </div>
</template>
<script>
export default {
  name: "SupplierIndexCardComponent",
  props: {
    users: Array,
    method: { type: Function },
  },
  data() {
    return {
      userLogged: localStorage.getItem("userLogged")
        ? JSON.parse(localStorage.getItem("userLogged"))
        : null,
      perPage: 10,
      currentPage: 1,
      dataObj: {
        name: "",
        order: "",
        observation: "",
      },
      isDisabled: true,
      selectMode: "single",
      selected: {},
      suppliers: [],

      search: '',
			paginaAtual: 1,
			itensPorPagina: 10,
    };
  },

  computed: {
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;

      return this.users.slice(start, end);
    },

    totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.users.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
  },

  methods: {
    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },

    async getSuppliers() {
      await this.$http
        .get(`/suppliers`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.suppliers = response.data.suppliers;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

    viewSupplier(item) {
      this.$router.push(`/supplier/${item.id}`);
    },

  },
  mounted() {
    this.getSuppliers();
  },

};
</script>
<style lang="scss" src="./style.scss" scoped />
