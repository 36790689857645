<template>
	<div class="component-tabs-purchase">
		<b-row v-if="isOrder == false">
			<b-col md="6" class="col-label-filtro">
				<strong> Filtrar listagem por: </strong>
			</b-col>
			<b-col md="6" class="col-label-filtro limpar-filtro">
				<b-button @click="limparPesquisa()"> Limpar filtros </b-button>
			</b-col>

			<b-col md="12" class="col-label">
				<label> <strong> Lote </strong> </label>
				<div class="campo-filtro">
					<b-form-input placeholder="Procurar" v-model="filter_lote"></b-form-input>
				</div>
			</b-col>

			<b-col md="4" class="col-label">
				<label for="" class="datepicker"> <strong> Fornecedor </strong> </label>

					<select v-model="filter_fornecedor" placeholder="Procurar" class="form-control">
						<option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">{{ supplier.name }}
						</option>
					</select>

			</b-col>

			<b-col md="2" class="col-label ">
				<label for="" class="datepicker"> <strong> Data da orderm </strong> </label>
				<b-form-datepicker id="" placeholder="" v-model="filter_dt_order" locale="pt"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					}"></b-form-datepicker>
			</b-col>

			<b-col md="2" class="col-label ">
				<label for="" class="datepicker"> <strong> ETA </strong> </label>
				<b-form-datepicker id="" placeholder="" v-model="filter_dt_eta" locale="pt"
					:date-format-options="{
						year: 'numeric',
						month: 'numeric',
						day: 'numeric',
					}"></b-form-datepicker>
			</b-col>

			<b-col class="col-search">
				<b-button @click="searchOrders()">Buscar</b-button>
			</b-col>

			<b-col md="12">
				<b-table striped hover :items="orders" :fields="fields" class="table">
					<template #cell(dt_eta)="data">
						<span> {{ data.item.dt_eta | dateFormat }} </span>
					</template>

					<template #cell(dt_order)="data">
						<span> {{ data.item.dt_order | dateFormat }} </span>
					</template>

					<template #cell(active)="data">
						<span> {{ data.item.active == 1 ? 'Ativo' : 'Inativo' }} </span>
					</template>

					<template #cell(actions)="data">
						<b-button class="btn-view" @click="editOrder(data.item)"
							style="text-decoration: none; color: black">
							<font-awesome-icon :icon="['fas', 'edit']" />
						</b-button>
						<b-button class="btn-view" @click="changeActiveOrder(data.item)"
							style="text-decoration: none; color: black">
							<font-awesome-icon :icon="['fas', 'eye']" v-if="data.item.active == 1" />
							<font-awesome-icon :icon="['fas', 'eye-slash']" v-else />
						</b-button>
						<b-button class="btn-view" @click="editDolar(data.item)"
							style="text-decoration: none; color: black">
							<font-awesome-icon :icon="['fas', 'clone']" />
						</b-button>
						<b-button @click="removeDolar(data.item)" style="text-decoration: none; color: black">
							<font-awesome-icon :icon="['fas', 'trash-alt']" />
						</b-button>
					</template>
				</b-table>
			</b-col>

			<b-col md="12" class="row-inserir">
				<b-button @click="inserirOrder()" class="button">
					<font-awesome-icon :icon="['fas', 'plus']" />
					Inserir ordem de compra
				</b-button>
			</b-col>
		</b-row>

		<!-- ADICIONAR NOVA ORDEM DE SERVIÇO -->
		<div class="component-tabs-purchase" v-show="isOrder == true">
			<b-row>
				<b-col md="12" class="col-label-filtro">
					<strong> Inserir nova ordem de compra </strong>
				</b-col>

				<b-col md="4" class="col-label">
					<label> <strong> Lote </strong> </label>
					<div class="campo-filtro">
						<b-form-input v-model="dataObj.num_lote"></b-form-input>
					</div>
				</b-col>

				<b-col md="4" class="col-label">
					<label> <strong> NCO </strong> </label>
					<div class="campo-filtro">
						<b-form-input v-model="dataObj.num_nco"></b-form-input>
					</div>
				</b-col>

				<b-col md="4" class="col-label">
					<label for=""> <strong> Fornecedor </strong> </label>
					<select v-model="dataObj.supplier" id="opcionesSelect" class="form-control">
						<option v-for="supplier in suppliers" :key="supplier.id" :value="supplier.id">{{ supplier.name }}
						</option>
					</select>
				</b-col>

				<b-col md="4" class="col-label">
					<label for=""> <strong> Data da ordem</strong> </label>
					<b-form-datepicker placeholder="Insirir data" v-model="dataObj.dt_order"
						class="mb-2"></b-form-datepicker>
				</b-col>

				<b-col md="4" class="col-label">
					<label for=""> <strong> Data prevista de chegada (ETA) </strong> </label>
					<b-form-datepicker placeholder="Insirir data" v-model="dataObj.dt_eta" class="mb-2"></b-form-datepicker>

				</b-col>

			</b-row>

			<b-row>
				<b-col md="12">
					<b-table striped hover :items="listProducts" :fields="fieldsProduct" class="table">
						<template #cell(actions)="data">
							<b-button class="btn-view" @click="editDolar(data.item)"
								style="text-decoration: none; color: black">
								<font-awesome-icon :icon="['fas', 'eye']" />
							</b-button>
							<b-button @click="removeDolar(data.item)" style="text-decoration: none; color: black">
								<font-awesome-icon :icon="['fas', 'trash-alt']" />
							</b-button>
						</template>
					</b-table>
				</b-col>
			</b-row>

			<b-row class="row-inserir">
				<b-col>
					<b-button class="button" @click="VoltarOrder()" style="margin-right: 5px;">
						Voltar
					</b-button>

					<b-button class="button" @click="saveOrder()" v-if="!dataObj.id">
						Salvar Order de Compra
					</b-button>

					<b-button v-if="this.dataObj.id" @click="showModal" class="button">
						<font-awesome-icon :icon="['fas', 'plus']" />
						Inserir produto
					</b-button>

					<b-button class="button" v-if="dataObj.id" @click="updateProduct(dataObj.id)" style="margin-left: 5px;">
						Atualizar
					</b-button>
				</b-col>

				<b-col>
					<b-modal id="modal_product" ref="modal_product" title="Selecione o Produto" size="lg" hide-footer>
						<b-row>
							<b-col md="12" class="col-label">
								<label for=""> <strong> Código </strong> </label>
								<v-select :options="products" label="code" v-model="dataObj.product"
									@input="selectProduct(dataObj.product)">
									<template slot="option" slot-scope="option">
										{{ option.code }} - {{ option.name }} - {{ option.derivation }}
									</template>
								</v-select>
							</b-col>

							<b-col md="6" class="col-label" style="margin: 10px 0;">
								<label for=""> <strong> Nome </strong> </label>
								<b-form-input v-model="dataObj.product.name"></b-form-input>
							</b-col>

							<b-col md="6" class="col-label" style="margin: 10px 0;">
								<label for=""> <strong> Derivação </strong> </label>
								<b-form-input v-model="dataObj.product.derivation"></b-form-input>
							</b-col>

							<b-col md="6" class="col-label" style="margin: 10px 0;">
								<label for=""> <strong> Quantidade </strong> </label>
								<b-form-input v-model="dataObj.product.quantity" @keyup="validarNumero"></b-form-input>
							</b-col>

							<b-col md="6" class="col-label" style="margin: 10px 0;">
								<label for=""> <strong> Valor FOB </strong> </label>
								<b-form-input v-model="dataObj.product.value_fob" @keyup="validarNumero"></b-form-input>
							</b-col>

							<b-col md="6">
								<b-button class="mt-3" variant="outline-danger" block @click="hideModal">Cancelar</b-button>
							</b-col>

							<b-col md="6" style="text-align: right;">
								<b-button class="mt-3" variant="outline-success" block
									@click="saveProduct()">Salvar</b-button>
							</b-col>

						</b-row>
					</b-modal>
				</b-col>
			</b-row>

		</div>

	</div>
</template>

<script>
import vSelect from "vue-select";
import moment from "moment";

export default {
	name: "OrderPurchaseComponent",
	components: {
		vSelect
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			openCollapse: null,
			currentYear: new Date().getFullYear(),
			view_months: false,
			view_months_btn: true,
			view_months_btn_clear: false,
			productsCode: [],
			optionsPie: [],
			dataAtingimento: [],
			isOrder: false,
			items: [{
				lote: '1235-33',
				nco: '',
				dt_order: '',
				eta: '',
				supplier: '',
				active: '',
				type: '',
				value: '',
			}
			],
			dataObj: {
				id: '',
				num_lote: '',
				num_nco: '',
				supplier: '',
				dt_order: '',
				dt_eta: '',
				product: {
					id: '',
					code: '',
					name: '',
					derivation: ''
				}
			},
			obj: this.$route.params.id,
			suppliers: [],
			orders: [],
			listProducts: [],

			filter_lote: '',
			filter_dt_order: '',
			filter_dt_eta: '',
			filter_fornecedor: ''

		};
	},
	mounted() {
		this.getProductsCode();
		this.getChartAtingimento();
		this.getProducts();
		this.getSuppliers();
		this.getOrderPurchases();
		this.listProducts();
	},
	methods: {
		async searchOrders() {
			const formData = new FormData();
			formData.append("filter_lote", this.filter_lote);
			formData.append("filter_dt_order", this.filter_dt_order);
			formData.append("filter_dt_eta", this.filter_dt_eta);
			formData.append("filter_fornecedor", this.filter_fornecedor);
			formData.append("id_simulation", this.$route.params.id_simulation);

			await this.$http
				.post(`/scenarios/list/orders/filter`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.orders = response.data.searchListOrder;
					}
				})
				.catch(function (error) {
					console.log(error);
				});

		},

		limparPesquisa(){
			this.filter_dt_eta = '';
			this.filter_dt_order = '';
			this.filter_fornecedor = '';
			this.filter_lote = '';
			this.getOrderPurchases()
		},
		validarNumero() {
			// Remove caracteres não numéricos, vírgulas e pontos
			if (this.dataObj.product.quantity != '') {
				this.dataObj.product.quantity = this.dataObj.product.quantity.replace(/[^0-9.,]/g, '');
				this.dataObj.product.quantity = this.dataObj.product.quantity.replace(/([.,])[.,]+/g, '$1');
			}
			if (this.dataObj.product.value_fob != '') {
				this.dataObj.product.value_fob = this.dataObj.product.value_fob.replace(/[^0-9.,]/g, '');
				this.dataObj.product.value_fob = this.dataObj.product.value_fob.replace(/([.,])[.,]+/g, '$1');
			}

		},
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		hideModal() {
			this.$refs['modal_product'].hide()
		},

		showModal() {
			this.dataObj.product.code = null;
			this.dataObj.product.derivation = null;
			this.dataObj.product.quantity = null;
			this.dataObj.product.value_fob = null;
			this.dataObj.product.name = null;
			this.$refs["modal_product"].show();

		},
		async getProducts() {
			await this.$http
				.get(`/products/allProducts`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.products = response.data.productsFactor;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async getSuppliers() {
			await this.$http
				.get(`/suppliers`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.suppliers = response.data.suppliers;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async selectProduct(item) {
			console.log(item)
			this.dataObj.product.name = item.name;
			// this.dataObj.product.derivation = item.derivation;
			this.dataObj.product.code = item.code;
			// this.dataObj.product.id = item.id
		},

		async saveOrder() {
			if (this.dataObj.num_lote == '' || this.dataObj.num_nco == '' || this.dataObj.supplier == '' || this.dataObj.dt_order == '' || this.dataObj.dt_eta == '') {
				this.goError("Deve ser preenchido os campos!");
			} else {
				const formData = new FormData();
				formData.append("id_simulation", this.$route.params.id_simulation);
				formData.append("num_lote", this.dataObj.num_lote);
				formData.append("num_nco", this.dataObj.num_nco);
				formData.append("cod_supplier", this.dataObj.supplier);
				formData.append("dt_order", this.dataObj.dt_order);
				formData.append("dt_eta", this.dataObj.dt_eta);

				// formData.append("cod_product", this.dataObj.product.code);
				// formData.append("derivation", this.dataObj.product.derivation);
				// formData.append("quantity", this.dataObj.product.quantity);
				// formData.append("value_fob", this.dataObj.product.value_fob);

				this.$http
					.post(`/scenarios/store/purchase_order`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response);
						} else {
							this.dataObj.id = response.data.id;
							this.showModal();
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async saveProduct() {
			const formData = new FormData();
			formData.append("id_simulation", this.$route.params.id_simulation);

			formData.append("cod_product", this.dataObj.product.code);
			formData.append("derivation", this.dataObj.product.derivation);
			formData.append("quantity", this.dataObj.product.quantity);
			formData.append("value_fob", this.dataObj.product.value_fob);
			formData.append("id_purchase_order", this.dataObj.id);

			this.$http
				.post(`/scenarios/store/purchase_order/products`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						this.getOrderPurchases();
						this.getProductsOrderPurchase(this.dataObj.id);
						this.hideModal();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async updateProduct(id) {
			if (this.dataObj.num_lote == '' || this.dataObj.num_nco == '' || this.dataObj.supplier == '' || this.dataObj.dt_order == '' || this.dataObj.dt_eta == '') {
				this.goError("Deve ser preenchido os campos!");
			} else {
				const formData = new FormData();
				formData.append("id", id);
				formData.append("num_lote", this.dataObj.num_lote);
				formData.append("num_nco", this.dataObj.num_nco);
				formData.append("cod_supplier", this.dataObj.supplier);
				formData.append("dt_order", this.dataObj.dt_order);
				formData.append("dt_eta", this.dataObj.dt_eta);

				this.$http
					.post(`/scenarios/update/purchase_order`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response);
						} else {
							this.goSuccess('Ordem de compra cadastrada com sucesso!');
							this.getOrderPurchases();
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async getOrderPurchases() {
			await this.$http
				.get(`/scenarios/list/orders/${this.$route.params.id_simulation}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.orders = response.data.listOrder;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async changeActiveOrder(item) {
			const formData = new FormData();
			formData.append("id", item.purchase_order_id);
			if (item.active === "1") {
				formData.append("active", "0");
			} else {
				formData.append("active", "1");
			}

			this.$http
				.post(`/scenarios/update/purchase_order/active`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						this.goSuccess('Ordem de compra atualizada com sucesso!');
						location.reload();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async editOrder(item) {
			this.isOrder = true;
			console.log(item)

			this.dataObj.num_lote = item.num_lote;
			this.dataObj.id = item.id;
			this.dataObj.num_nco = item.num_nco;
			this.dataObj.supplier = item.cod_supplier;
			this.dataObj.dt_order = item.dt_order;
			this.dataObj.dt_eta = item.dt_eta;
			this.getProductsOrderPurchase(item.purchase_order_id);
		},

		async getProductsOrderPurchase(purchase_order_id) {
			await this.$http
				// .get(`/products`)
				.get(`/scenarios/purchase_order/${purchase_order_id}/products`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.listProducts = response.data.listProducts;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		inserirOrder() {
			this.isOrder = true;
			this.dataObj.product.code = '';
			this.dataObj.product.derivation = '';
			this.dataObj.product.quantity = '';
			this.dataObj.product.value_fob = '';
			this.dataObj.product.name = '';
			this.dataObj.id = '';
			this.dataObj.num_lote = '';
			this.dataObj.num_nco = '';
			this.dataObj.supplier = '';
			this.dataObj.dt_eta = '';
			this.dataObj.dt_order = '';
			this.listProducts = []
		},

		VoltarOrder() {
			this.isOrder = false;
			this.getOrderPurchases();
		},

		monthsDisable() {
			if (this.view_months == false) {
				this.view_months = true;
				this.view_months_btn = false;
				this.view_months_btn_clear = true;
			} else {
				this.view_months = false;
				this.view_months_btn = true;
				this.view_months_btn_clear = false;
			}
		},

		async getProductsCode() {
			await this.$http
				// .get(`/products`)
				.get(`/s&op/products`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.productsCode = response.data.products;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		getChartAtingimento() {
			this.dataAtingimento = {
				labels: ["Jan", "Fev", "Mar", "Abr", "Mai", "Jun"],

				datasets: [
					{
						label: "",
						data: [10, 10, 10, 10, 10, 10],
						backgroundColor: "#f87979",
						borderColor: "#f87979",
						fill: false,
						pointStyle: "circle",
					},
				],
			};

			this.optionsPie = {
				responsive: true,
				legend: {
					display: false,
				},
			};
		},
	},
	computed: {
		fields: function () {
			let fields = [
				{
					key: "num_lote",
					label: "Lote",
				},
				{
					key: "num_nco",
					label: "NCO",
				},
				{
					key: "dt_order",
					label: "Data da ordem",
				},
				{
					key: "dt_eta",
					label: "ETA",
				},
				{
					key: "name",
					label: "Fornecedor",
				},
				{
					key: "active",
					label: "Ativo",
				},
				{
					key: "type",
					label: "Tipo",
				},
				{
					key: "value_fob",
					label: "Valor(USD)",
				},
				{
					key: "actions",
					label: "Ações",
				},
			];
			return fields;
		},

		fieldsProduct: function () {
			let fields = [
				{
					key: "code",
					label: "Código",
				},
				{
					key: "derivation",
					label: "Derivação",
				},
				{
					key: "product",
					label: "Produto",
				},
				{
					key: "quantity",
					label: "Quantidade",
				},
				{
					key: "value_fob",
					label: "Valor FOB",
				},
				{
					key: "actions",
					label: "Ações",
				},
			];
			return fields;
		},

	},
	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD/MM/YYYY");
		},
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />
