<template>
    <div class="main">
        <PageComponent :title_nav="'Integrações'">
            <div slot="slot-pages" class="content-pages">
                <div class="products">
                    <div class="products-header">
                        <!-- breadcrumb -->
                        <b-breadcrumb>
                            <b-breadcrumb-item :to="{ name: 'programming-index' }"> <font-awesome-icon :icon="['fas', 'arrow-left']" />  Listagem de
                                programação</b-breadcrumb-item>
                            <b-breadcrumb-item active>Importar programação</b-breadcrumb-item>
                        </b-breadcrumb>
                        <!-- breadcrumb -->
                        <b-row>
                            <b-col md="10" class="col-btns">
                                <b-button class="btn-export" @click="getDownload()" :disabled="disabledImport"
                                    :style="fieldStyle">
                                    <font-awesome-icon :icon="['fas', 'file-download']" /> Download
                                    planilha
                                </b-button>
                            </b-col>
                            <b-col md="2">
                                <div class="input-file-container">
                                    <input type="file" id="csv_file" name="csv_file" :disabled="disabledImport"
                                        :style="fieldStyle" class="form-control input-file btn-import"
                                        @change="handleFileChange" accept=".csv" />
                                    <label for="csv_file" class="control-label input-file-trigger" :style="fieldStyle">
                                        <font-awesome-icon :icon="['fas', 'file-upload']" />Importar
                                        planilha</label>
                                </div>
                            </b-col>
                            <b-col md="12">
                                <b-form-input class="input-search" placeholder="Pesquisar"></b-form-input>
                            </b-col>
                            <b-col md="12">
                                <div class="panel">
                                    <div class=" fix-width">
                                        <table class="table table-responsive">
                                            <thead>
                                                <tr>
                                                    <th>Ações</th>
                                                    <th>Concatenar</th>
                                                    <th>Previsão Chegada VdB</th>
                                                    <th>Fornecedor</th>
                                                    <th>Embarque</th>
                                                    <th>PO </th>
                                                    <th>Cod. produto</th>
                                                    <th>TAM</th>
                                                    <th>Qtd. comprada</th>
                                                    <th>Des. Produto</th>
                                                    <th>OC</th>
                                                    <th>NF</th>
                                                    <th>Tipo de carga</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                <tr v-for="(csv, index) in paginatedItems" :key="index"
                                                    :class="{ active: active === index }" style="cursor: pointer;">

                                                    <td>
                                                        <b-button style="text-decoration: none; color: black"
                                                            @click="editProduct(index, index, column, csv)"
                                                            v-if="editingIndex !== index" class="btn-edit">
                                                            <h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
                                                        </b-button>

                                                        <b-button class="btn-check-circle"
                                                            style="text-decoration: none; color: black"
                                                            @click="saveItem(csv, index)" v-else>
                                                            <h6><font-awesome-icon :icon="['fas', 'check-circle']" /></h6>
                                                        </b-button>

                                                        <b-button class="btn-circle"
                                                            style="text-decoration: none; color: black"
                                                            @click="cancelEdit()" v-if="editingIndex === index">
                                                            <h6><font-awesome-icon :icon="['fas', 'times-circle']" /></h6>
                                                        </b-button>

                                                    </td>

                                                    <td colspan="">
                                                        <label for="">
                                                            <span>{{
                                                                csv["Concatenar"] ?
                                                                csv["Concatenar"] : '0'
                                                            }}</span>
                                                        </label>

                                                    </td>

                                                    <td colspan="">
                                                        <label for="">
                                                            <span v-if="editingIndex !== index">{{
                                                                csv["Previsão Chegada VdB"] ?
                                                                csv["Previsão Chegada VdB"] : '0'
                                                            }}</span>
                                                            <b-form-input v-else
                                                                v-model="csv['Previsão Chegada VdB']"></b-form-input>
                                                        </label>

                                                    </td>
                                                    <td>
                                                        <label for="">
                                                            <span v-if="editingIndex !== index">{{ csv['Fornecedor'] ?
                                                                csv['Fornecedor'] : '0'
                                                            }}</span>
                                                            <b-form-input v-else v-model="csv['Fornecedor']"></b-form-input>
                                                        </label>

                                                    </td>
                                                    <td>
                                                        <label for="">
                                                            <span v-if="editingIndex !== index">{{ csv['Embarque'] ?
                                                                csv['Embarque'] : '0'
                                                            }}</span>
                                                            <b-form-input v-else v-model="csv['Embarque']"></b-form-input>
                                                        </label>
                                                    </td>
                                                    <td>
                                                        <label for=""> <span v-if="editingIndex !== index">{{
                                                            csv['PO'] ?
                                                            csv['PO'] : '0' }}</span>
                                                            <b-form-input v-else v-model="csv['PO']"></b-form-input></label>
                                                    </td>
                                                    <td>
                                                        <label for=""> <span v-if="editingIndex !== index">{{
                                                            csv['Cod. produto'] ?
                                                            csv['Cod. produto'] : '0' }}</span>
                                                            <b-form-input v-else
                                                                v-model="csv['Cod. produto']"></b-form-input></label>
                                                    </td>
                                                    <td>
                                                        <label for=""> <span v-if="editingIndex !== index">{{
                                                            csv['TAM'] ?
                                                            csv['TAM'] : '0' }}</span>
                                                            <b-form-input v-else
                                                                v-model="csv['TAM']"></b-form-input></label>
                                                    </td>
                                                    <td>
                                                        <label for=""> <span v-if="editingIndex !== index">{{
                                                            csv['Qtd. comprada'] ?
                                                            csv['Qtd. comprada'] : '0' }}</span>
                                                            <b-form-input v-else
                                                                v-model="csv['Qtd. comprada']"></b-form-input></label>
                                                    </td>

                                                    <td>
                                                        <label for=""> <span v-if="editingIndex !== index">{{
                                                            csv['Des. produto'] ?
                                                            csv['Des. produto'] : '0' }}</span>
                                                            <b-form-input v-else
                                                                v-model="csv['Des. produto']"></b-form-input></label>

                                                    </td>
                                                    <td>
                                                        <label for=""> <span v-if="editingIndex !== index">{{ csv['OC'] ?
                                                            csv['OC'] : '0' }}</span>
                                                            <b-form-input v-else v-model="csv['OC']"></b-form-input></label>
                                                    </td>
                                                    <td>
                                                        <label for=""> <span v-if="editingIndex !== index">{{
                                                            csv['NF'] ?
                                                            csv['NF'] : '0' }}</span>
                                                            <b-form-input v-else v-model="csv['NF']"></b-form-input></label>
                                                    </td>

                                                    <td>
                                                        <label for=""> <span v-if="editingIndex !== index">{{
                                                            csv['Tipo carga'] ?
                                                            csv['Tipo carga'] : '0' }}</span>
                                                            <b-form-input v-else
                                                                v-model="csv['Tipo carga']"></b-form-input></label>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </div>
                            </b-col>
                            <b-col style="margin-top: 10px;" class="paginations-options">
                                <ul class="pagination">
                                    <li class="page-item" :class="{ 'active': currentPage === page }"
                                        v-for="page in totalPages" :key="page">
                                        <a class="page-link" @click="changePage(page)">{{ page }}</a>
                                    </li>
                                </ul>
                            </b-col>
                        </b-row>
                    </div>
                </div>
            </div>
        </PageComponent>
    </div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from 'moment'

export default {
    name: "ExportBudgetComponent",
    components: {
        PageComponent,
    },
    data() {
        return {
            userLogged: localStorage.getItem("userLogged")
                ? JSON.parse(localStorage.getItem("userLogged"))
                : null,
            perPage: 6,
            currentPage: 1,
            data: "",
            collapsed: true,
            channel_name: "",
            channel_fields: [],
            channel_entries: [],
            parse_header: [],
            parse_csv: [],
            sortOrders: {},
            sortKey: "",
            active: null,
            editingIndex: -1,
            disabled: false,
            openIndex: null,
            budgetId: '',
            itemsPerPage: 5, // Quantidade de itens por página
            result: 0,
            per: 0,
            disabledImport: false,
            budget_id: this.$route.params.id,
            forecast_id: this.$route.params.id,
            forecast: "",
            name_month: "",
            qtde: "",
            qtde_reduzida: "",
            pm: "",
            pm_novo: "",
            pm_lq: "",
            reajuste_preco: "",
            custo_ponderado: "",
            faturamento: "",
            fat_liquido: "",
            custo_total: "",
            margem_bunzl: "",
            percentage: "",
            valueDays: 0,

            id: this.$route.params.id,
            previsao: '',
            chegada: '',
            fornecedor: '',
            embarque: '',
            po: '',
            cod_produto: '',
            tam: '',
            qtd_comprada: '',
            des_produto: '',
            oc: '',
            nf: '',
            tipo_carga: '',


            columns: [],
            rows: [],

            concatenateValue: '',
            concatenateColumnIndex: -1
        }

    },

    mounted() {
        this.getProgramming();
        this.findForecast();
    },
    watch: {
        columns() {
            // You might want to implement logic to select the desired column for concatenation.
            // For simplicity, let's say you always want to concatenate the second column.
            this.concatenateColumnIndex = 1;
        }
    },
    methods: {
        async findForecast() {
            if (this.forecast_id) {
                await this.$http
                    .get(`/forecast/${this.forecast_id}`)
                    .then((response) => {
                        if (response.data.error) {
                            this.goError(response.data.message);
                        } else {
                            this.forecast = response.data.forecast;
                            this.name_month = moment(this.forecast.month, 'M').locale('pt-BR').format('MMMM');
                            this.findDaysMonthBudget(this.forecast.year);
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            }
        },

        async findDaysMonthBudget(forecast) {
            await this.$http
                .get(`/forecast/${forecast}/budget/months`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        console.log("response.data.days_months")
                        let mes = 'n_' + moment(this.forecast.month, 'M').locale('pt-BR').format('MMM')
                        let days_months = response.data.days_months;

                        this.valueDays = days_months[mes]

                        console.log(this.valueDays)
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        itemIsOpen(index) {
            return this.openIndex === index;
        },

        editItem(index) {
            this.editingIndex = index;
        },

        editProduct(item, index, column, prod) {
            this.editingIndex = index;
            this.disabled = true;
            console.log(prod);
        },

        cancelEdit() {
            this.editingIndex = -1;
            this.disabled = false;
        },

        goSuccess(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 4000,
                icon: "success",
                title: "Sucesso!",
                text: message ? message : "Cadastro Atualizado.",
            });
        },

        goError(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "Atenção!",
                text: message ? message : "Já existe um usuário com este e-mail.",
            });
        },

        async getDownload() {
            await this.$http
                .get(`/programming/downloadProgramming`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        // const data = response.data;
                        const dataWithCustomColumns = this.addCustomColumns(response.data);
                        this.downloadCSV(dataWithCustomColumns);

                        console.log(response.data);
                        // this.data = response.data;
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        downloadCSV(data) {
            const csvData = this.convertToCSV(data);
            const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
            const link = document.createElement("a");
            link.setAttribute("href", URL.createObjectURL(blob));
            link.setAttribute("download", "PROGRAMAÇÃO - Planilha para importação.csv");
            document.body.appendChild(link);
            link.click();
            document.body.removeChild(link);
        },

        convertToCSV(data) {
            const csvArray = [];
            const columnHeaders = Object.keys(data[0]);
            csvArray.push(columnHeaders.join(";"));

            data.forEach((item) => {
                const values = columnHeaders.map((header) => item[header]);
                csvArray.push(values.join(";"));
            });

            return csvArray.join("\n");
        },

        addCustomColumns(data) {
            return data.map(() => ({
                "Previsão Chegada VdB": ``,
                "Fornecedor": ``,
                "Embarque": ``,
                "PO": ``,
                "Cod. produto": ``,
                "TAM": ``,
                "Qtd. comprada": ``,
                "Des. produto": ``,
                "OC": ``,
                "NF": ``,
                "Tipo carga": ``,
            }));
        },

        sortBy: function (key) {
            var vm = this;
            vm.sortKey = key;
            vm.sortOrders[key] = vm.sortOrders[key] * -1;
        },

        async handleFileChange(event) {
            const file = event.target.files[0];
            if (!file) return;

            const text = await this.readFile(file);
            const lines = text.split('\n');

            if (lines.length > 0) {
                this.columns = lines[0].split(';');
                this.rows = lines.slice(1).map(line => line.split(';'));

                this.rows.forEach((item) => {
                    item.forEach((rows, inx) => {

                        if (inx == 0) {
                            this.previsao = rows
                        }

                        if (inx == 1) {
                            this.fornecedor = rows
                        }
                        if (inx == 2) {
                            this.embarque = rows
                        }
                        if (inx == 3) {
                            this.po = rows
                        }
                        if (inx == 4) {
                            this.cod_produto = rows
                        }
                        if (inx == 5) {
                            this.tam = rows
                        }
                        if (inx == 6) {
                            this.qtd_comprada = rows
                        }
                        if (inx == 7) {
                            this.des_produto = rows
                        }
                        if (inx == 8) {
                            this.oc = rows
                        }
                        if (inx == 9) {
                            this.nf = rows
                        }
                        if (inx == 10) {
                            this.tipo_carga = rows
                        }
                    })

                    if (item[0] != '' || item[1] != '' || item[2] != '' || item[3] != '' || item[4] != '' || item[5] != '' || item[6] != '' || item[7] != '' || item[8] != '' || item[9] != '' || item[10] != '') {
                        this.parse_csv.push({
                            'Concatenar': '0', 'Previsão Chegada VdB': this.previsao, 'Fornecedor': this.fornecedor, 'Embarque': this.embarque,
                            'PO': this.po, 'Cod. produto': this.cod_produto, 'TAM': this.tam, 'Qtd. comprada': this.qtd_comprada,
                            'Des. produto': this.des_produto, 'OC': this.oc, 'NF': this.nf, 'Tipo carga': this.tipo_carga
                        });
                    }

                    console.log("this.parse_csv--------------")
                    console.log(this.parse_csv)

                })

                if (this.rows) {
                    this.savePlanilha();
                }
            }
        },

        async savePlanilha() {
            this.parse_csv.forEach((item) => {
                this.previsao = item['Previsão Chegada VdB'] ? item['Previsão Chegada VdB'] : '0';
                this.chegada = item['Chegada_VdB'] ? item['Chegada_VdB'] : '0';
                this.fornecedor = item['Fornecedor'] ? item['Fornecedor'] : '0';
                this.embarque = item['Embarque'] ? item['Embarque'] : '0';
                this.po = item['PO'] ? item['PO'] : '0';
                this.cod_produto = item['Cod. produto'] ? item['Cod. produto'] : '0';
                this.tam = item['TAM'] ? item['TAM'] : '0';
                this.qtd_comprada = item['Qtd. comprada'] ? item['Qtd. comprada'] : '0';
                this.des_produto = item['Des. produto'] ? item['Des. produto'] : '0';
                this.oc = item['OC'] ? item['OC'] : '0';
                this.nf = item['NF'] ? item['NF'] : '0';
                this.tipo_carga = item['Tipo carga'] ? item['Tipo carga'] : '0';

                console.log(item)

                let formData = new FormData();

                formData.append("previsao", this.previsao);
                formData.append("chegada", this.chegada);
                formData.append("fornecedor", this.fornecedor);
                formData.append("embarque", this.embarque);
                formData.append("po", this.po);
                formData.append("cod_produto", this.cod_produto);
                formData.append("tam", this.tam);
                formData.append("qtd_comprada", this.qtd_comprada);
                formData.append("des_produto", this.des_produto);
                formData.append("OC", this.oc);
                formData.append("NF", this.nf);
                formData.append("Tipo_carga", this.tipo_carga);
                formData.append("user", this.userLogged.id);
                formData.append("programming_id", this.id);

                this.$http
                    .post(`/programming/store/items`, formData)
                    .then((response) => {
                        if (response.data.error) {
                            this.goError(response.data.message);
                        } else {
                            location.reload();
                            // this.getProgramming();
                        }
                    })
                    .catch(function (error) {
                        console.log(error);
                    });
            })
            this.goSuccess("Planilha importada com sucesso!!");
        },
        readFile(file) {
            return new Promise((resolve, reject) => {
                const reader = new FileReader();
                reader.onload = event => resolve(event.target.result);
                reader.onerror = error => reject(error);
                reader.readAsText(file);
            });
        },

        async saveItem(item) {
            this.editingIndex = -1;

            this.disabled = false;

            console.log(item)
            let formData = new FormData();

            formData.append("id", item.id);
            formData.append("previsao", item['Previsão Chegada VdB'] ? item['Previsão Chegada VdB'] : '0');
            formData.append("chegada", '0');
            formData.append("fornecedor", item['Fornecedor'] ? item['Fornecedor'] : '0');
            formData.append("embarque", item['Embarque'] ? item['Embarque'] : '0');
            formData.append("po", item['PO'] ? item['PO'] : '0');
            formData.append("cod_produto", item['Cod. produto'] ? item['Cod. produto'] : '0');
            formData.append("tam", item['TAM'] ? item['TAM'] : '0');
            formData.append("qtd_comprada", item['Qtd. comprada'] ? item['Qtd. comprada'] : '0');
            formData.append("des_produto", item['Des. produto'] ? item['Des. produto'] : '0');
            formData.append("OC", item['OC'] ? item['OC'] : '0');
            formData.append("NF", item['NF'] ? item['NF'] : '0');
            formData.append("Tipo_carga", item['Tipo carga'] ? item['Tipo carga'] : '0');
            formData.append("user", this.userLogged.id);
            formData.append("programming_id", item.programming_id);

            this.$http
                .post(`/programming/update/items`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.goSuccess(response.data.message);
                        location.reload()
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });

        },

        changePage(page) {
            this.currentPage = page;
        },

        async getProgramming() {
            await this.$http
                .get(`/programming/items/${this.id}`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        if (response.data.items.length > 0) {
                            this.disabledImport = true;
                        } else {
                            this.disabledImport = false;
                        }
                        // this.parse_csv = response.data.items;
                        let result = response.data.items;


                        result.forEach((item) => {
                            console.log(item)
                            this.parse_csv.push({
                                'Concatenar': item.concatenate, 'Previsão Chegada VdB': item.previsao, 'Fornecedor': item.fornecedor, 'Embarque': item.embarque,
                                'PO': item.po, 'Cod. produto': item.cod_produto, 'TAM': item.tam, 'Qtd. comprada': item.qtd_comprada,
                                'Des. produto': item.des_produto, 'OC': item.OC, 'NF': item.NF, 'Tipo carga': item.Tipo_carga, 'id': item.id, 'programming_id': item.programming_id
                            });
                        })

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }

    },

    computed: {
        totalPages() {
            return Math.ceil(this.parse_csv.length / this.itemsPerPage);
        },
        paginatedItems() {
            const startIndex = (this.currentPage - 1) * this.itemsPerPage;
            const endIndex = startIndex + this.itemsPerPage;
            return this.parse_csv.slice(startIndex, endIndex);
        },
        fieldStyle() {
            return this.disabledImport ? { backgroundColor: 'lightgray !important', color: 'gray' } : {};
        },

        fieldsBudget: function () {
            let fields = [
                {
                    key: "code",
                    label: "Código",
                },

                {
                    key: "description",
                    label: "Descrição",
                },

                {
                    key: "forecast",
                    label: "Forecast",
                },

                {
                    key: "jan",
                    label: "Jan",
                },

                {
                    key: "fev",
                    label: "Fev",
                },

                {
                    key: "mar",
                    label: "Mar",
                },

                {
                    key: "abr",
                    label: "Abr",
                },

                {
                    key: "mai",
                    label: "Mai",
                },

                {
                    key: "jun",
                    label: "Jun",
                },

                {
                    key: "jul",
                    label: "Jul",
                },

                {
                    key: "ago",
                    label: "Ago",
                },

                {
                    key: "set",
                    label: "Set",
                },

                {
                    key: "out",
                    label: "Out",
                },

                {
                    key: "nov",
                    label: "Nov",
                },

                {
                    key: "dez",
                    label: "Dez",
                },

                {
                    key: "actions",
                    label: "Ações",
                },
            ];
            return fields;
        },

        colSpan() {
            return this.parse_csv.length + 1;
        },

    },
    filters: {
        capitalize: function (str) {
            return str.charAt(0).toUpperCase() + str.slice(1);
        },
    },
};
</script>

<style lang="scss" src="./style.scss" scoped />
