<template>
  <div class="main">
    <PageComponent :title_nav="'Cadastros'">
      <div slot="slot-pages" class="content-pages">
        <div class="products">
          <div class="products-header">
            <!-- breadcrumb -->
            <b-breadcrumb>
              <b-breadcrumb-item :to="{ name: 'dashboard' }">
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                Início
              </b-breadcrumb-item>
              <b-breadcrumb-item active>Cadastro de depósito</b-breadcrumb-item>
            </b-breadcrumb>
            <!-- breadcrumb -->

            <b-row>
              <b-col md="9">
                <h5>Cadastrar Depósito</h5>
              </b-col>

              <b-col md="12" class="col-add-dolar" v-if="userLoggedPermission.permissiondepositm == 1 || userLogged.role == 'Administrador'">
                <b-button class="add-fator" :to="{ name: 'stock-create' }"> <font-awesome-icon :icon="['fas', 'plus']" />
                  Novo depósito </b-button>
              </b-col>
            </b-row>
            <b-row class="row-stock">
              <b-col>
                <b-card>
                  <b-table :items="itemsFiltradosPaginados" :fields="fieldsProduct" responsive="" :per-page="perPage"
                    :current-page="currentPage" class="table-stock">
                    <template #cell(type)="data">
                      <p>
                        {{ data.item.type == 'true' ? 'Aceito' : 'Rejeitado' }}
                      </p>
                    </template>

                    <template #cell(name)="data">
                      <p>
                        {{ data.item.name }}
                      </p>
                    </template>

                    <template #cell(code)="data">
                      <p>
                        {{ data.item.code }}
                      </p>
                    </template>

                    <template #cell(actions)="data">
                      <p v-if="userLoggedPermission.permissiondepositm == 1 ||  userLogged.role == 'Administrador'">
                        <b-button @click="editStock(data.item)" style="text-decoration: none; color: black">
                          <h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
                        </b-button>

                        <b-button @click="removeStock(data.item)" style="text-decoration: none; color: black">
                          <h6><font-awesome-icon :icon="['fas', 'trash-alt']" /></h6>
                        </b-button>
                      </p>

                    </template>
                  </b-table>

                </b-card>
              </b-col>

              <b-col md="12" class="mt-3">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
              </b-col>

            </b-row>
          </div>
        </div>
      </div>
    </PageComponent>
  </div>
</template>
<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";

export default {
  name: "StockComponent",
  components: {
    PageComponent,
  },
  data() {
    return {
      userLogged: localStorage.getItem("userLogged")
        ? JSON.parse(localStorage.getItem("userLogged"))
        : null,
      processes: [],
      perPage: 6,
      currentPage: 1,
      search: "",
      stock: [],
      userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null,
      paginaAtual: 1,
			itensPorPagina: 5,
    };
  },

  mounted() {
    this.getStocks();
  },

  methods: {
    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },
    // LISTA DE ESTOQUES
    async getStocks() {
      await this.$http
        .get(`/stocks/all`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.stock = response.data.stocks;
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async editStock(item) {
      this.$router.push({
        path: `stock/${item.id}/edit`,
        params: {
          dataObj: { item }
        },
      });

    },
    async removeStock(item) {
      const formData = new FormData();
      formData.append("id", item.id);

      await this.$swal
        .fire({
          text:
            "Remover Estoque",
          title: "Deseja continuar?",
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "SIM, REMOVER",
          cancelButtonText: `CANCELAR`,
          confirmButtonColor: "#d33",
          cancelButtonColor: "#d33",
          customClass: {
            cancelButton:
              "btn btn-light rounded-pill px-md-5",
            confirmButton: "btn btn-success rounded-pill px-md-5",
          },
          buttonsStyling: false,
        })
        .then((result) => {
          if (result.isConfirmed) {
            this.$http
              .post(`/stocks/delete`, formData)
              .then((response) => {
                if (response.data.error) {
                  this.goError(response.data.message);
                } else {
                  this.goSuccess(response.data.message);
                  this.getStocks();
                }
              })
              .catch((error) => {
                this.goError(error.response.data.message);
                console.log(error.response.data.message);
              });
          }
        });
    },
  },

  computed: {
    fieldsProduct: function () {
      let fields = [
        {
          key: "code",
          label: "Código",
        },

        {
          key: "name",
          label: "Nome",
        },

        {
          key: "type",
          label: "Status",
        },

        {
          key: "actions",
          label: "",
        },
      ];
      return fields;
    },

    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;

      return this.processes.slice(start, end);
    },

    numeroDePaginas() {
      return Math.ceil(this.processes.length / this.perPage);
    },

    totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.stock.filter(item => item.code.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
  },

  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD/MM/YYYY");
    },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
