<template>
	<div class="component-tabs-quantity">
		<b-row>
			<b-col md="1" class="col-label">
				<span> Mês </span>
				<br />
				<span> Crescimento % </span>
			</b-col>
			<b-col class="col-months responsive">
				<div class="responsive table-responsive">
					<table class="table-import table responsive">
						<!-- Table headers -->
						<thead>
							<tr>
								<th scope="col" v-for="month in months" :key="month">{{ month }}</th>
							</tr>
						</thead>

						<!-- Table rows -->
						<tbody>
							<tr>
								<td v-for="(factor, index) in orderedFactorsGrowth" :key="index">
										<b-form-input
										v-model="resultado_growth[index].valor"
										@blur="saveGrowth(resultado_growth[index], resultado_growth[index])"
										></b-form-input>
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</b-col>
		</b-row>
		<!-- {{ this.orderedFactorsGrowth }} -->
		<!-- GRAFICO DE CRESCIMENTO -->
		<b-row class="chart">
			<b-col md="4">
				<p> <strong> Tipos de projeção </strong> </p>
			</b-col>

			<b-col md="8">
				<line-chart :chart-data="dataAtingimento" :options="optionsPie" :width="50" :height="15" />
			</b-col>
		</b-row>

		<!-- LISTA DE PRODUTOS E DERIVAÇÕES -->
		<b-row>
			<b-col md="12" class="title">
				<p><strong> Produtos </strong></p>
				<!-- <span style="margin-left: 10px">
					<input type="checkbox" name="" id="" /> Exibir somente produtos em falta
				</span> -->
			</b-col>
		</b-row>
		{{ this.valuesGrowth }}
		<br>
		<!-- {{ resultado_growth }} -->
		<b-row class="table_products">
			<b-col md="12">
				<div v-for="(product, index) in itemsFiltradosPaginados" :key="product.id">
				<div class="responsive table-responsive">
					<label for="" v-b-toggle="'product-scenario-' + product.id" class="label-product"  @click="findProduct(product, index)" >
						<!-- <input type="checkbox" name="" id="" /> -->
						{{ product.name }}
						<font-awesome-icon :icon="['fas', 'arrow-circle-down']" />
					</label>
					<div :id="'product-scenario-' + product.id" class="mt-2 responsive table-responsive item-content" :class="{ 'open': isOpen[index] }">
						<table class="table-products table responsive">
							<thead>
								<tr>
									<th class="cod-der">
										<!-- <strong>
											<p> {{ product.name }} </p>
										</strong> -->
									</th>
									<th class="der"></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<th></th>
									<!-- <th class="view-months" v-if="view_months_btn">
										<b-button @click="monthsDisable"> Ver mais + 6 meses ></b-button>
									</th> -->
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<th v-if="view_months"></th>
									<!-- <th v-if="view_months_btn_clear" class="view-months">
										<b-button @click="monthsDisable"> Ver menos </b-button>
									</th> -->
								</tr>
							</thead>

							<tbody>
								<tr v-for="(item) in product.items" :key="item.id">
									<td class="cod-der">
										{{ product.code }}
									</td>
									<td class="der">{{ item.derivation }}</td>
									
									<td v-for="factor in orderedFactors" :key="factor">

										<span class="span_values" v-if="factor != ''">{{ factor }}</span>
										<span class="span_values" v-else>0</span>
										<!-- Se não houver correspondência, exibe 0 -->
										<!-- <span v-else class="span_values form-control">0</span> -->
										<!-- <span class="span_values form-control">0</span> -->
									</td>
								</tr>
								
							</tbody>
						</table>
					</div>
				</div>
				</div>
			</b-col>
			<b-col>
				<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina"
					style="float: right;"></b-pagination>
			</b-col>
		</b-row>
	</div>
</template>

<script>
import lineChart from "./LinesChart";
import moment from "moment";

export default {
	name: "FactorSimulationComponent",
	components: {
		lineChart,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			openCollapse: null,
			currentYear: new Date().getFullYear(),
			view_months: true,
			view_months_btn: false,
			view_months_btn_clear: false,
			productsCode: [],
			optionsPie: [],
			dataAtingimento: [],
			obj: this.$route.params,
			dataObj: {
				name: "",
				dateRef: "",
				dateCreate: new Date().toLocaleDateString(),
				user_id: "",
				observation: "",
				factor:{
					value_jan: "",
					value_feb: "",
					value_mar: "",
					value_apr: "",
					value_may: "",
					value_jun: "",
					value_jul: "",
					value_aug: "",
					value_sep: "",
					value_oct: "",
					value_nov: "",
					value_dec: "",
					id: "",
					cod_product: "",
					derivation: "",
					year: ""
				},
				growth: {
					value_jan: "",
					value_feb: "",
					value_mar: "",
					value_apr: "",
					value_may: "",
					value_jun: "",
					value_jul: "",
					value_aug: "",
					value_sep: "",
					value_oct: "",
					value_nov: "",
					value_dec: "",
				}
			},
			factors: [],
			editingIndex: -1,
			active: null,
			disabled: false,
			openIndex: null,
			paginaAtual: 1,
			itensPorPagina: 10,
			growth: '',
			values_sell: {},
            isOpen: [],
			months: '',
			months_growth: '',
			date_atual: moment(),
			resultado: {},
			resultado_growth: {},
		};
	},
	mounted() {
		this.getProductsCode();
		this.getFactor();
		this.generateMonthNames();
		this.months = [...Array(12)].map((a, b) =>
				moment(this.date_atual).add(b, 'M').locale('pt').format('MMM, YYYY')
			);

		this.months_growth = [...Array(12)].map((a, b) =>
				moment(this.date_atual).add(b, 'M').format('MMM_YYYY').toLowerCase()
			);

		// this.getChartAtingimento();
			
	},
	methods: {
		generateMonthNames() {
			const currentDate = moment().startOf('month'); // Iniciar no primeiro dia do mês atual
			const numberOfMonths = 12; // Pode ajustar conforme necessário

			for (let i = 0; i < numberOfMonths; i++) {
				const monthYear = currentDate.clone().add(i, 'month').format('MMM_YYYY').toLowerCase();
				const month = currentDate.clone().add(i, 'month').format('MMM').toLowerCase();
				const year = currentDate.clone().add(i, 'month').format('YYYY').toLowerCase();
				this.$set(this.values_sell, monthYear, 0);
				this.$set(this.resultado, monthYear, 0);
				this.$set(this.resultado_growth, monthYear, {valor:0, mes: month, ano: year});
			}
		},
		// toggleItem(index) {
        //     // Verificar se o card atual está aberto
        //     const isOpen = this.isOpen[index];

        //     // Fechar todos os cards se o card atual já estiver aberto
        //     if (isOpen) {
        //         this.isOpen = this.isOpen.map(() => false);
        //     } else {
        //         // Se o card atual não estiver aberto, fechar todos os cards e abrir o atual
        //         this.isOpen = this.isOpen.map(() => false);
        //         this.$set(this.isOpen, index, true);
        //     }
        // },
		async findProduct(product, index){
			const isOpen = this.isOpen[index];

			if (isOpen) {
				this.isOpen = this.isOpen.map(() => false);
			} else {
				this.isOpen = this.isOpen.map(() => false);
				this.$set(this.isOpen, index, true);
			}

			let formData = new FormData();
			// this.$route.params
			formData.append("product_code", product.code);
			formData.append("year", this.currentYear);

			this.$http
				.post(`/scenarios/factor/find_product`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {

						console.log(response.data.findProduct)
						this.values_sell = response.data.findProduct
						// this.getPurchaseQtds();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		async saveGrowth(value, fieldName) {
			let formData = new FormData();

			formData.append("values_months", fieldName);
			formData.append("growth",fieldName.valor);
			formData.append("date", fieldName.mes);
			formData.append("simulation_id", this.$route.params.id_simulation);
			formData.append("year", fieldName.ano);

			// this.getChartAtingimento();

			this.$http
				.post(`/scenarios/store/factor/growth`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.getPurchaseGrowth();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		async getPurchaseGrowth() {
			await this.$http
				// .get(`/products`)
				.get(`/scenarios/${this.$route.params.id_simulation}/factor/growth`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.growth = response.data.factor_growth

						this.getChartAtingimento();

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
		monthsDisable() {
			if (this.view_months == false) {
				this.view_months = true;
				this.view_months_btn = false;
				this.view_months_btn_clear = true;
			} else {
				this.view_months = false;
				this.view_months_btn = true;
				this.view_months_btn_clear = false;
			}
		},

		async getProductsCode() {
			await this.$http
				// .get(`/products`)
				.get(`/s&op/products`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.productsCode = response.data.products;
						this.getPurchaseGrowth();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		getChartAtingimento() {
			this.dataAtingimento = {
				labels: this.months.map((item) => item),

				datasets: [
					{
						label: "",
						data: this.values_chart.map((item)=>item),
						backgroundColor: "#f87979",
						borderColor: "#f87979",
						fill: false,
						pointStyle: "circle",
					},
				],
			};

			this.optionsPie = {
				responsive: true,
				legend: {
					display: false,
				},
			};
		},
        goSuccess(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 4000,
                icon: "success",
                title: "Sucesso!",
                text: message ? message : "Cadastro Atualizado.",
            });
        },

        goError(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "Atenção!",
                text: message ? message : "Já existe um usuário com este e-mail.",
            });
        },

		editFactor(index) {
			this.editingIndex = index;
			this.disabled = true;

			this.dataObj.factor.value_jan = this.factors[index] ? this.factors[index]['factor_jan'] : 0;
			this.dataObj.factor.value_feb = this.factors[index] ? this.factors[index]['factor_feb'] : 0;
			this.dataObj.factor.value_mar = this.factors[index] ? this.factors[index]['factor_mar'] : 0;
			this.dataObj.factor.value_apr = this.factors[index] ? this.factors[index]['factor_apr'] : 0;
			this.dataObj.factor.value_may = this.factors[index] ? this.factors[index]['factor_may'] : 0;
			this.dataObj.factor.value_jun = this.factors[index] ? this.factors[index]['factor_jun'] : 0;
			this.dataObj.factor.value_jul = this.factors[index] ? this.factors[index]['factor_jul'] : 0;
			this.dataObj.factor.value_aug = this.factors[index] ? this.factors[index]['factor_aug'] : 0;
			this.dataObj.factor.value_sep = this.factors[index] ? this.factors[index]['factor_sep'] : 0;
			this.dataObj.factor.value_oct = this.factors[index] ? this.factors[index]['factor_oct'] : 0;
			this.dataObj.factor.value_nov = this.factors[index] ? this.factors[index]['factor_nov'] : 0;
			this.dataObj.factor.value_dec = this.factors[index] ? this.factors[index]['factor_dec'] : 0;
			this.dataObj.factor.id = this.factors[index] ? this.factors[index]['id'] : "";
		},

		cancelEdit() {
			this.editingIndex = -1;
			this.disabled = false;
			this.dataObj.factor = ""
		},

		async saveItem(item){
			this.editingIndex = -1;

			this.disabled = false;

            let formData = new FormData();

            formData.append("simulation_id", this.obj.id);
            formData.append("cod_product", item.code);
            formData.append("derivation", item.derivation);
            formData.append("year", this.currentYear);

            formData.append("factor_jan", this.dataObj.factor.value_jan);
            formData.append("factor_feb", this.dataObj.factor.value_feb);
            formData.append("factor_mar", this.dataObj.factor.value_mar);
            formData.append("factor_apr", this.dataObj.factor.value_apr);
            formData.append("factor_may", this.dataObj.factor.value_may);
            formData.append("factor_jun", this.dataObj.factor.value_jun);
            formData.append("factor_jul", this.dataObj.factor.value_jul);
            formData.append("factor_aug", this.dataObj.factor.value_aug);
            formData.append("factor_sep", this.dataObj.factor.value_sep);
            formData.append("factor_oct", this.dataObj.factor.value_oct);
            formData.append("factor_nov", this.dataObj.factor.value_nov);
            formData.append("factor_dec", this.dataObj.factor.value_dec);

			if(this.dataObj.factor.id){
				formData.append("id", this.dataObj.factor.id);
			}

			this.$http
                .post(`/scenarios/store/factor`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.goSuccess("Fator cadastrado com sucesso!");
						this.cancelEdit();
						this.getFactor();
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
		},

		async getFactor() {
			await this.$http
				.get(`/scenarios/simulation/${this.obj.id}/factor`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						let factor = response.data.factor_growth;
						this.factors = factor
					
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
	},

	computed: {
		getFormattedData() {
			const formattedData = {};

			this.growth.forEach(item => {
				const fieldName = `${item.month_year}`;
				formattedData[fieldName] = {valor: item.growth, mes: item.date, ano: item.year}; // Valor inicial de 0
			})

			return formattedData;
		},

		valuesGrowth(){
			return this.growth.forEach(item => {
				const fieldName = `${item.month_year}`;

				// Verifica se o item está presente em this.resultado_growth
				const resultadoItem = this.resultado_growth[fieldName];

				if (resultadoItem) {
					// Se estiver presente, substitui os valores pelos valores de this.growth
					resultadoItem.valor = item.growth;
					resultadoItem.mes = item.date;
					resultadoItem.ano = item.year;
				} else {
					// Se não estiver presente, adiciona o item de this.growth a this.resultado_growth
					this.$set(this.resultado_growth, fieldName, { valor: item.growth, mes: item.date, ano: item.year });
				}
			});
		},

		totalItensFiltrados() {
			return this.productsCode.length;
		},

		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.productsCode.slice(inicio, fim);
		},
		orderedResultado() {
			const sortedKeys = Object.keys(this.resultado).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado[key];
			});

			return ordered;
		},

		orderedFactors() {
			let factors = 0;
			if(this.values_sell == ''){
				factors = this.resultado
			} else {
				factors = this.values_sell
			}
			const sortedKeys = Object.keys(factors).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = factors[key];
			});

			return ordered;
		},

		orderedFactorsGrowth() {
			const sortedKeys = Object.keys(this.resultado_growth).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo objeto ordenado
			const ordered = {};
			sortedKeys.forEach(key => {
				ordered[key] = this.resultado_growth[key].valor;
			});

			return ordered;
		},

		values_chart() {
			const sortedKeys = Object.keys(this.getFormattedData).sort((a, b) => {
				const dateA = moment(a, 'MMM_YYYY');
				const dateB = moment(b, 'MMM_YYYY');
				return dateA - dateB;
			});

			// Criar um novo array ordenado apenas com os valores
			const orderedValues = sortedKeys.map(key => this.getFormattedData[key].valor);

			return orderedValues;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
