<template>
  <footer>
    <h1>
      Desenvolvido por
      <strong>VSCOM &copy; {{ currentYear }}</strong> - Proibida reprodução
      total ou parcial sem autorização
    </h1>
  </footer>
</template>

<script>
export default {
  name: "FooterComponent",

  data() {
    return {
      currentYear: new Date().getFullYear(),
    };
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />





