<template>
	<div class="main">
		<PageComponent :title_nav="'Budget/ForeCast'">
			<div slot="slot-pages" class="content-pages">
				<div class="budget">
					<div class="budget-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'budget' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Budget e ForeCast
							</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						<b-card>
							<b-row class="row-save">
								<b-col md="3"></b-col>
								<b-col md="6">
									<p><strong> Ano do novo budget </strong></p>
									<b-form-input v-model="dataObj.budget.year" :disabled="isDisableInput"></b-form-input>

									<b-row class="row_labels">
										<b-col md="6"><strong> Dias úteis por mês </strong></b-col>
										<b-col md="6" class="total_year"> Total do ano: {{ sumDays }}</b-col>
									</b-row>
									<b-row class="row_color">
										<b-col md="4">
											<p>Jan</p>
											<b-form-input v-model="dataObj.budget.n_jan" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>

										<b-col md="4">
											<p>Fev</p>
											<b-form-input v-model="dataObj.budget.n_fev" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>

										<b-col md="4">
											<p>Mar</p>
											<b-form-input v-model="dataObj.budget.n_mar" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>
									</b-row>

									<b-row class="row_month">
										<b-col md="4">
											<p>Abr</p>
											<b-form-input v-model="dataObj.budget.n_abr" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>

										<b-col md="4">
											<p>Mai</p>
											<b-form-input v-model="dataObj.budget.n_mai" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>

										<b-col md="4">
											<p>Jun</p>
											<b-form-input v-model="dataObj.budget.n_jun" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>
									</b-row>

									<b-row class="row_color">
										<b-col md="4">
											<p>Jul</p>
											<b-form-input v-model="dataObj.budget.n_jul" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>

										<b-col md="4">
											<p>Ago</p>
											<b-form-input v-model="dataObj.budget.n_ago" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>

										<b-col md="4">
											<p>Set</p>
											<b-form-input v-model="dataObj.budget.n_set" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>
									</b-row>

									<b-row class="row_month">
										<b-col md="4">
											<p>Out</p>
											<b-form-input v-model="dataObj.budget.n_out" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>

										<b-col md="4">
											<p>Nov</p>
											<b-form-input v-model="dataObj.budget.n_nov" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>

										<b-col md="4">
											<p>Dez</p>
											<b-form-input v-model="dataObj.budget.n_dez" @input="atualizarSoma"
												:disabled="isDisableInput"></b-form-input>
										</b-col>
									</b-row>

									<b-row class="btn-buttons">
										<b-col>
											<b-button class="button-padrao-cancel" :to="{ name: 'budget' }">
												CANCELAR
											</b-button>
										</b-col>

										<b-col v-if="!budgetId.operation && !budgetId.id">
											<b-button class="save button-padrao-add" @click="saveBudget()">
												Salvar
											</b-button>
										</b-col>

										<b-col v-if="budgetId.operation || budgetId.id">
											<b-button class="save button-padrao-add" @click="updateBudget()">
												CONTINUAR
											</b-button>
										</b-col>

									</b-row>
								</b-col>
								<b-col md="3"></b-col>
							</b-row>
						</b-card>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
	name: "BudgetCreateComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			perPage: 6,
			currentPage: 1,
			dataObj: {
				budget: {
					id: "",
					year: "",
					n_jan: 20,
					n_fev: 20,
					n_mar: 20,
					n_abr: 20,
					n_mai: 20,
					n_jun: 20,
					n_jul: 20,
					n_ago: 20,
					n_set: 20,
					n_out: 20,
					n_nov: 20,
					n_dez: 20,
				},
			},
			getYear: "",
			sumDays: 0,
			operation: this.$route.params.operation,
			budgetId: this.$route.params,
			isDisableInput: false,
		};
	},

	mounted() {
		this.validationYear();
		if (this.budgetId) {
			this.findBudget();
			this.viewBudget();
		}
		this.atualizarSoma();
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async findBudget() {
			if (this.budgetId) {
				await this.$http
					.get(`/budget/${this.budgetId.id}`)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							let budgetId = response.data.budget;
							this.dataObj.budget.id = budgetId.id
							this.dataObj.budget.year = budgetId.year
							this.dataObj.budget.n_jan = budgetId.n_jan
							this.dataObj.budget.n_fev = budgetId.n_fev
							this.dataObj.budget.n_mar = budgetId.n_mar
							this.dataObj.budget.n_abr = budgetId.n_abr
							this.dataObj.budget.n_mai = budgetId.n_mai
							this.dataObj.budget.n_jun = budgetId.n_jun
							this.dataObj.budget.n_jul = budgetId.n_jul
							this.dataObj.budget.n_ago = budgetId.n_ago
							this.dataObj.budget.n_set = budgetId.n_set
							this.dataObj.budget.n_out = budgetId.n_out
							this.dataObj.budget.n_nov = budgetId.n_nov
							this.dataObj.budget.n_dez = budgetId.n_dez
							this.atualizarSoma();
							this.operation = this.operation.operation
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async validationYear() {
			await this.$http
				.get(`/budget/getYear`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.getYear = response.data.year;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async saveBudget() {
			if (
				this.getYear != this.dataObj.budget.year &&
				this.getYear < this.dataObj.budget.year
			) {
				if (
					this.dataObj.budget.year == "" ||
					this.dataObj.budget.n_jan == "" ||
					this.dataObj.budget.n_fev == "" ||
					this.dataObj.budget.n_mar == "" ||
					this.dataObj.budget.n_abr == "" ||
					this.dataObj.budget.n_mai == "" ||
					this.dataObj.budget.n_jun == "" ||
					this.dataObj.budget.n_jul == "" ||
					this.dataObj.budget.n_ago == "" ||
					this.dataObj.budget.n_set == "" ||
					this.dataObj.budget.n_out == "" ||
					this.dataObj.budget.n_nov == "" ||
					this.dataObj.budget.n_dez == ""
				) {
					this.goError("Preencha todos os campos!");
				} else {
					const formData = new FormData();
					formData.append("year", this.dataObj.budget.year);
					formData.append("n_jan", this.dataObj.budget.n_jan);
					formData.append("n_fev", this.dataObj.budget.n_fev);
					formData.append("n_mar", this.dataObj.budget.n_mar);
					formData.append("n_abr", this.dataObj.budget.n_abr);
					formData.append("n_mai", this.dataObj.budget.n_mai);
					formData.append("n_jun", this.dataObj.budget.n_jun);
					formData.append("n_jul", this.dataObj.budget.n_jul);
					formData.append("n_ago", this.dataObj.budget.n_ago);
					formData.append("n_set", this.dataObj.budget.n_set);
					formData.append("n_out", this.dataObj.budget.n_out);
					formData.append("n_nov", this.dataObj.budget.n_nov);
					formData.append("n_dez", this.dataObj.budget.n_dez);
					formData.append("user_id", this.userLogged.id);

					this.$http
						.post(`/budget/store`, formData)
						.then((response) => {
							if (response.data.error) {
								this.goError(response.data.message);
							} else {
								this.goSuccess(response.data.message);
								// let budget = response.data.budget_id
								// if(this.operation == 1) {
								this.$router.push("/budget");
								// } else {
								// this.$router.push({
								// 	path: `budget/${budget.id}/create/export`,
								// });
								// }
							}
						})
						.catch((error) => {
							console.log(error.response);
						});
				}
			} else {
				this.goError("Não é possível gravar um novo Budget com este ano!");
			}
		},

		async updateBudget() {
			// if (
			// 	this.dataObj.budget.year >= this.getYear
			// ) {
			if (
				this.dataObj.budget.year == "" ||
				this.dataObj.budget.n_jan == "" ||
				this.dataObj.budget.n_fev == "" ||
				this.dataObj.budget.n_mar == "" ||
				this.dataObj.budget.n_abr == "" ||
				this.dataObj.budget.n_mai == "" ||
				this.dataObj.budget.n_jun == "" ||
				this.dataObj.budget.n_jul == "" ||
				this.dataObj.budget.n_ago == "" ||
				this.dataObj.budget.n_set == "" ||
				this.dataObj.budget.n_out == "" ||
				this.dataObj.budget.n_nov == "" ||
				this.dataObj.budget.n_dez == ""
			) {
				this.goError("Preencha todos os campos!");
			} else {
				const formData = new FormData();
				formData.append("year", this.dataObj.budget.year);
				formData.append("n_jan", this.dataObj.budget.n_jan);
				formData.append("n_fev", this.dataObj.budget.n_fev);
				formData.append("n_mar", this.dataObj.budget.n_mar);
				formData.append("n_abr", this.dataObj.budget.n_abr);
				formData.append("n_mai", this.dataObj.budget.n_mai);
				formData.append("n_jun", this.dataObj.budget.n_jun);
				formData.append("n_jul", this.dataObj.budget.n_jul);
				formData.append("n_ago", this.dataObj.budget.n_ago);
				formData.append("n_set", this.dataObj.budget.n_set);
				formData.append("n_out", this.dataObj.budget.n_out);
				formData.append("n_nov", this.dataObj.budget.n_nov);
				formData.append("n_dez", this.dataObj.budget.n_dez);
				formData.append("user_id", this.userLogged.id);
				formData.append("id", this.budgetId.id);

				this.$http
					.post(`/budget/update`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							let budget = response.data.data
							console.log(budget)

							this.$router.push({
								path: `create/export`,
								params: {
									budget: { budget },
								},
							});
						}
					})
					.catch((error) => {
						this.goError(error.response.data.message);
						console.log(error.response.data.message);
					});
			}

		},

		atualizarSoma() {
			let jan = this.dataObj.budget.n_jan ? this.dataObj.budget.n_jan : 0;
			let fev = this.dataObj.budget.n_fev ? this.dataObj.budget.n_fev : 0;
			let mar = this.dataObj.budget.n_mar ? this.dataObj.budget.n_mar : 0;
			let abr = this.dataObj.budget.n_abr ? this.dataObj.budget.n_abr : 0;
			let mai = this.dataObj.budget.n_mai ? this.dataObj.budget.n_mai : 0;
			let jun = this.dataObj.budget.n_jun ? this.dataObj.budget.n_jun : 0;
			let jul = this.dataObj.budget.n_jul ? this.dataObj.budget.n_jul : 0;
			let ago = this.dataObj.budget.n_ago ? this.dataObj.budget.n_ago : 0;
			let set = this.dataObj.budget.n_set ? this.dataObj.budget.n_set : 0;
			let out = this.dataObj.budget.n_out ? this.dataObj.budget.n_out : 0;
			let nov = this.dataObj.budget.n_nov ? this.dataObj.budget.n_nov : 0;
			let dez = this.dataObj.budget.n_dez ? this.dataObj.budget.n_dez : 0;
			this.sumDays =
				parseInt(jan) +
				parseInt(fev) +
				parseInt(mar) +
				parseInt(abr) +
				parseInt(mai) +
				parseInt(jun) +
				parseInt(jul) +
				parseInt(ago) +
				parseInt(set) +
				parseInt(out) +
				parseInt(nov) +
				parseInt(dez);
		},

		async viewBudget() {
			await this.$http
				.get(`/budget/getYear`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.getYear = response.data.year;

						if (this.budgetId.id && !this.budgetId.operation) {
							console.log("visualização");
							this.isDisableInput = true;
						} else {
							this.isDisableInput = false;
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
	},

	computed: {},
};
</script>

<style lang="scss" src="./style.scss" scoped />
