<template>
	<div class="sidebar" id="side">
		<div class="menu">
			<div class="buttom-menu">
				<b-button class="buttom-menu-color" v-b-toggle.sidebar :class="visible ? null : 'collapsed'"
					:aria-expanded="visible ? 'true' : 'false'">
					<font-awesome-icon :icon="['fas', 'ellipsis-v']" />
				</b-button>
			</div>

			<div :class="visible ? 'logo' : 'logo-collapsed'">
				<img alt="Volk" src="@/assets/logo.png" />
			</div>

			<b-collapse v-model="visible" id="sidebar">
				<ul>
					<router-link style="text-decoration: none; color: white" :to="{ name: 'dashboard' }" v-if="userLoggedPermission.permissionInicio == 1 || userLogged.role == 'Administrador'">
						<li :class="{ 'menu-active': currentRouteName == 'dashboard' }">
							<h6><font-awesome-icon :icon="['fas', 'home']" /> Início</h6>
						</li>
					</router-link>

					<!-- <router-link style="text-decoration: none; color: white" :to="{ name: 's&op' }">
						<li :class="{ 'menu-active': currentRouteName == 's&op' }">
							<h6><font-awesome-icon :icon="['fas', 'chart-bar']" /> S&OP</h6>
						</li>
					</router-link> -->

						<div style="text-decoration: none; color: white" v-b-toggle.collapse-3 class="" v-if="userLoggedPermission.permissionseopqtde || userLoggedPermission.permissionseopcost || userLoggedPermission.permissionseopconfigv == 1  || userLogged.role == 'Administrador'">
							<li :class="{ 'menu-active': currentRouteName == 's&op' }">
								<h6><font-awesome-icon :icon="['fas', 'chart-bar']" /> S&OP</h6>
							</li>

							<b-collapse id="collapse-3" class="mt-2 submenu">
								<p>
									<router-link :to="{ name: 's&op' }" style="text-decoration: none; color: black" v-if="userLoggedPermission.permissionseopqtde == 1 || userLogged.role == 'Administrador'">
										<li :class="{ 'menu-active': currentRouteName == 's&op' }">
											<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Quantidade</h6>
										</li>

									</router-link>
								</p>

								<p>
									<router-link :to="{ name: 'cost-index' }" style="text-decoration: none; color: black" v-if="userLoggedPermission.permissionseopcost == 1 || userLogged.role == 'Administrador'">
										<li :class="{ 'menu-active': currentRouteName == 'cost-index' }">
											<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Custo</h6>
										</li>
									</router-link>
								</p>

								<p>
									<router-link :to="{ name: 's&op-parameters' }" style="text-decoration: none; color: black" v-if="userLoggedPermission.permissionseopconfigv == 1 || userLogged.role == 'Administrador'">
										<li :class="{ 'menu-active': currentRouteName == 's&op-parameters' }">
											<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Configurações S&OP</h6>
										</li>
									</router-link>
								</p>

							</b-collapse>
						</div>


					<!-- <router-link style="text-decoration: none; color: white" :to="{ name: 'cost-index' }">
						<li :class="{ 'menu-active': currentRouteName == 'cost-index' }">
							<h6><font-awesome-icon :icon="['fas', 'donate']" /> Custos</h6>
						</li>
					</router-link> -->

					<router-link style="text-decoration: none; color: white" :to="{ name: 'budget' }" v-if="userLoggedPermission.permissionbudgetv == 1 || userLogged.role == 'Administrador'">
						<li :class="{ 'menu-active': currentRouteName == 'budget' }">
							<h6><font-awesome-icon :icon="['fas', 'wallet']" /> Budget/ForeCast</h6>
						</li>
					</router-link>

					<router-link style="text-decoration: none; color: white" :to="{ name: 'parameters' }" v-if="userLoggedPermission.permissionparametersv == 1 || userLogged.role == 'Administrador'">
						<li :class="{ 'menu-active': currentRouteName == 'parameters' }">
							<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Parâmetros</h6>
						</li>
					</router-link>

					<div style="text-decoration: none; color: white" v-b-toggle.collapse-1 class="" v-if="userLoggedPermission.permissionusersv || userLoggedPermission.permissiondepositv || userLoggedPermission.permissionstockGrouperv 
					|| userLoggedPermission.permissionproductGrouperv || userLoggedPermission.permissionleadTimev || userLoggedPermission.permissionfactorv || userLoggedPermission.permissiondolarv == 1 || userLogged.role == 'Administrador'">
						<li :class="{ 'menu-active': currentRouteName == 'users' }">
							<h6><font-awesome-icon :icon="['fas', 'file']" /> Cadastros</h6>
						</li>

						<b-collapse id="collapse-1" class="mt-2 submenu">
							<p v-if="userLoggedPermission.permissionusersv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'users' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'users' }">
										<h6><font-awesome-icon :icon="['fas', 'users']" /> Usuários</h6>
									</li>

								</router-link>
							</p>

							<p v-if="userLoggedPermission.permissiondepositv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'stock-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'stock-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Cadastro de Depósito</h6>
									</li>
								</router-link>
							</p>

							<p v-if="userLoggedPermission.permissionstockGrouperv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'stock-collator-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'stock-collator-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Agrupador de Estoque</h6>
									</li>
								</router-link>
							</p>

							<!-- <p>
								<router-link :to="{ name: 'stock-totalizer-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'stock-totalizer-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Totalizador de Estoque</h6>
									</li>
								</router-link>
							</p> -->

							<p v-if="userLoggedPermission.permissionproductGrouperv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'product-grouper-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'product-grouper-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Produto agrupador</h6>
									</li>
								</router-link>
							</p>

							<p v-if="userLoggedPermission.permissionleadTimev == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'lead-time-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'lead-time-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Etapas lead time</h6>
									</li>
								</router-link>
							</p>

							<p v-if="userLoggedPermission.permissionfactorv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'factor-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'factor-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Fator</h6>
									</li>
								</router-link>
							</p>

							<p v-if="userLoggedPermission.permissiondolarv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'dolar-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'dolar-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Dólar</h6>
									</li>
								</router-link>
							</p>

						</b-collapse>
					</div>

					<div style="text-decoration: none; color: white" v-b-toggle.collapse-2 class="" v-if="userLoggedPermission.permissionsyncproductv || userLoggedPermission.permissionsyncsupplierv || userLoggedPermission.permissionsyncprogrammingv || userLoggedPermission.permissionsyncprocessv == 1 || userLogged.role == 'Administrador'">
						<li :class="{ 'menu-active': currentRouteName == 'integrations' }">
							<h6><font-awesome-icon :icon="['fas', 'cloud']" /> Integrações</h6>
						</li>

						<b-collapse id="collapse-2" class="mt-2 submenu">
							<p v-if="userLoggedPermission.permissionsyncproductv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'products-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'products-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Produtos</h6>
									</li>

								</router-link>
							</p>

							<p v-if="userLoggedPermission.permissionsyncsupplierv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'supplier-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'supplier-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Fornecedores</h6>
									</li>

								</router-link>
							</p>

							<p v-if="userLoggedPermission.permissionsyncprogrammingv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'programming-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'programming-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Programação</h6>
									</li>

								</router-link>
							</p>

							<p v-if="userLoggedPermission.permissionsyncprocessv == 1 || userLogged.role == 'Administrador'">
								<router-link :to="{ name: 'batch_processes-index' }" style="text-decoration: none; color: black">
									<li :class="{ 'menu-active': currentRouteName == 'batch_processes-index' }">
										<h6><font-awesome-icon :icon="['fas', 'th-large']" /> Processos em lote</h6>
									</li>

								</router-link>
							</p>
						</b-collapse>
					</div>

					<router-link style="text-decoration: none; color: white" :to="{ name: 'scenarios-simulations-index' }" v-if="userLoggedPermission.permissionscenariov == 1 || userLogged.role == 'Administrador'" >
						<li :class="{ 'menu-active': currentRouteName == 'scenarios-simulations-index' }">
							<h6><font-awesome-icon :icon="['fas', 'sync-alt']" /> Cenários <br> e simulações</h6>
						</li>
					</router-link>

					<b-button style="text-decoration: none; color: white; width: 100%" @click.prevent="logout">
						<li>
							<h6>
								<font-awesome-icon :icon="['fas', 'sign-out-alt']" />
								SAIR
							</h6>
						</li>
					</b-button>

					<div class="div-footer">
						<p class="footer-sidebar-title">Volk Brasil S&OP</p>
						<p class="footer-sidebar">@2023 All Rights Reserved</p>
						<p class="footer-sidebar">Feito por Gebit</p>
					</div>
				</ul>
			</b-collapse>
		</div>
	</div>
</template>

<style>
.dropdown-menu {
	transition: 300ms;
}

svg.rotate-90 {
	transform: rotate(90deg);
}
</style>
<script>
export default {
	name: "SidebarComponent",
	data() {
		return {
			visible: true,
			winWidth: 0,
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,

			userLogout: localStorage.getItem("userLogout")
				? JSON.parse(localStorage.getItem("userLogout"))
				: null,
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
		};
	},

	computed: {
		currentRouteName() {
			return this.$route.name;
		},
	},
	mounted() {
		window.addEventListener("resize", this.onResize);
		if (!this.userLogged) {
			this.goLogin();
		}
		this.$root.$on('bv::toggle::collapse', (id, state) => {
			if (id === 'sidebar') {
				this.$refs.sidebar[state ? 'show' : 'hide']();
			}
		});

	},

	beforeDestroy() {
		window.removeEventListener("resize", this.onResize);
	},

	methods: {
		onResize() {
			this.winWidth = window.innerWidth;
			if (this.winWidth <= 700) {
				this.visible = false;
			} else this.visible = true;
		},

		goLogin() {
			this.$router.push("/");
		},

		logout() {
			localStorage.removeItem("authToken");
			localStorage.removeItem("authTokenExpires");
			localStorage.removeItem("userLogged");
			localStorage.removeItem("@vscom:userAvatar");          
			localStorage.removeItem("userPermission");
			localStorage.removeItem("userLogout");
			this.goLogin();
		},
	},
	created() {
		return this.onResize();
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
