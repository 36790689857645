<template>
	<div class="main">
		<PageComponent :title_nav="'Integrações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Produtos</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row class="mt-5">
							<b-col md="5">
								<h5>Produtos</h5>
							</b-col>
							<b-col md="" class="col-search">
								<div class="form">
									<b-form-input placeholder="Procurar" v-model="search"></b-form-input>
									<button type="submit">Search</button>
								</div>
							</b-col>

							<b-col class="icon-sort" md="1">
								<b-button v-b-toggle.filters>
									<font-awesome-icon :icon="['fas', 'filter']" />
								</b-button>
							</b-col>

							<b-col md="">
								<b-form-select @change="orderProducts" class="select-sort" v-model="selectedSort"
									:options="options"></b-form-select>
							</b-col>

							<b-col md="12" class="col-add" v-if="userLoggedPermission.permissionsyncproductm == 1 ||  userLogged.role == 'Administrador'">
								<b-button class="button-padrao-add" @click="showModal()">
									Importar Produtos
								</b-button>
							</b-col>

							<b-col md="12" class="col-table">
								<b-card>
									<b-table :items="itemsFiltradosPaginados" :fields="fieldsProduct" responsive="" :per-page="perPage"
										:current-page="currentPage" class="table-participants">
										<!-- <template #cell(view)="data">
											<p>
												<font-awesome-icon :icon="['fas', 'eye']" @click="view(data.item.id)"
													:class="{ 'menu-active1': currentRouteName == data.item.id }" />
											</p>
										</template> -->

										<template #cell(code)="data">
											<p>
												{{ data.item.code }}
											</p>
										</template>


										<template #cell(name)="data">
											<p>
												{{ data.item.name }}
											</p>
										</template>


										<template #cell(derivation)="data">
											<p>
												{{ data.item.derivation != "undefined" ? data.item.derivation : "-" }}
											</p>
										</template>


										<template #cell(line_product)="data">
											<p>
												{{ data.item.desGru != '[object Object]' ? data.item.desGru : data.item.line_product }}
											</p>
										</template>

											<template #cell(codOri)="data">
												<p>
													{{ data.item.codOri == '100' ? 'Revenda' : data.item.codOri == '2' ? 'Insumo' : data.item.codOri == '300' ? 'Matéria Prima'
													:  'Produto Acabado p/ venda' }}
												</p>
											</template>

										<template #cell(active_product)="data">
												<div class="form-check form-switch" v-if="userLoggedPermission.permissionsyncproductm == 1 || userLogged.role == 'Administrador'">
													<input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked" @input="inactiveProduct(data.item)"
													v-model="data.item.is_active" v-b-tooltip.hover :title="data.item.is_active ? 'Ativo' : 'Inativo'">
												</div>
												<div class="form-check form-switch" v-else>
													<input class="form-check-input" type="checkbox" role="switch" id="flexSwitchCheckChecked"
													v-model="data.item.is_active" v-b-tooltip.hover :title="data.item.is_active ? 'Ativo' : 'Inativo'">
												</div>
										</template>

										<template #cell(actions)="data">
											<p v-if="userLoggedPermission.permissionsyncproductm == 1 || userLogged.role == 'Administrador'">
												<b-button @click="sendEst(data.item)" style="text-decoration: none; color: black">
													<h6><font-awesome-icon :icon="['fas', 'hourglass']" /></h6>
												</b-button>

												<b-button @click="sendLeadProduct(data.item)" style="text-decoration: none; color: black">
													<h6><font-awesome-icon :icon="['fas', 'history']" /></h6>
												</b-button>
											</p>

										</template>
									</b-table>
								</b-card>
							</b-col>

						</b-row>

						<!-- <b-row class="paginations-options">

							<b-col style="margin-top: 10px;" class="paginations-options">
								<ul class="pagination">
									<li class="page-item" :class="{ 'active': currentPage === page }" v-for="page in totalPages"
										:key="page">
										<a class="page-link" @click="changePage(page)">{{ page }}</a>
									</li>
								</ul>
							</b-col>

						</b-row> -->

						<b-rown class="paginations-options1 mt-4">
							<!-- <b-col>
								<span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
							</b-col> -->
							<b-col md="12 mt-4">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
							</b-col>
						</b-rown>


						<b-modal ref="my-modal" hide-footer title="Filtrar importação" hide-header-close>
							<b-row>
								<!-- <b-col md="12">
									<b-form-group label="Filtrar" v-slot="{ ariaDescribedby }">
										<b-form-radio-group id="radio-group-2" v-model="integration" :aria-describedby="ariaDescribedby" plain
											@change="integrationDate">
											<b-form-radio value="T">Todos</b-form-radio>
											<b-form-radio value="P">Somente Pendentes de Integração</b-form-radio>
										</b-form-radio-group>
									</b-form-group>
								</b-col> -->

								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Período</label>
									<b-form-datepicker placeholder="" id="example-datepicker" v-model="date" class="mb-2" locale="pt"
										:date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
								</b-col>

								<b-col md="6" class="mt-3">
									<b-form-group id="input-group-1" label="Código da Empresa:" label-for="input-1">
										<b-form-input v-model="codeCompany" placeholder="Código da Empresa"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col md="12">
								</b-col>

								<b-col md="6">
									<b-button class="mt-3" variant="outline-danger" block @click="hideModal">Cancelar</b-button>
								</b-col>

								<b-col md="6">
									<b-button class="mt-3" variant="outline-success" @click="sincronizedProducts()"
										style="float: right">Importar</b-button>
								</b-col>
							</b-row>
						</b-modal>

						<b-modal ref="import-sell-progress" hide-footer title="Download" hide-header-close id="ModalProgress">
							<!-- <div v-if="progresso < 100"> -->
							<div>
								<p> <span class="loader"></span> Processando...</p>
								<div class="progress-bar" style="background-color:rgb(156, 157, 160)">
									<div :style="{ width: progresso + '%' }" style="background-color:rgb(75, 114, 221)"> <span> {{ progresso
									}}% </span> </div>
								</div>
							</div>
						</b-modal>
					</div>
				</div>
			</div>
		</PageComponent>
		<b-sidebar right id="filters" title="" ref="filters" backdrop aria-label="Sidebar with custom footer" no-header
			shadow>
			<template #default="{ hide }">
				<div class="p-3">
					<b-button class="btn-close-modal" block @click="hide">
						<font-awesome-icon :icon="['fas', 'times']" />
					</b-button>

					<div class="img-info">
						<div class="info-profile">
							<h3 class="name-user">
								Filtrar lista de produtos
							</h3>

							<p>
								<input type="text" v-model="filter.code" class="form-control" placeholder="Código do produto">
							</p>

							<p>
								<input type="text" v-model="filter.derivation" class="form-control" placeholder="Derivação">
							</p>

							<p>
								<input type="text" v-model="filter.line" class="form-control" placeholder="Linha do produto">
							</p>

							<p>
								<input type="text" v-model="filter.und" disabled class="form-control" placeholder="Unidade de estoque">
							</p>

							<p>
								<input type="text" v-model="filter.supplier" disabled class="form-control"
									placeholder="Código do fornecedor">
							</p>

							<b-button class="filter" @click="filterProduct">
								Filtrar
							</b-button>
							<b-button class="clear" @click="clearFilter">
								Limpar
							</b-button>
						</div>
					</div>

				</div>
			</template>
		</b-sidebar>
	</div>
</template>
<style>
.loader {
	width: 20px !important;
	height: 20px !important;
	border: 5px solid rgb(105, 68, 240) !important;
	border-bottom-color: rgb(243, 243, 243) !important;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

/* Estilo para a barra de progresso */
.progress-bar {
	width: 100%;
	height: 20px;
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 10px;
}

/* Estilo para a parte preenchida da barra de progresso */
.progress-bar div {
	height: 100%;
	background-color: #007bff;
	border-radius: 5px;
	transition: width 0.3s ease-in-out;
	color: white;
}

.import_manual {
	/* padding: 5px !important; */
	width: 100%;
	cursor: pointer !important;
}

#import_stock {
	/* padding: 15px 10px; */
	/* padding: 5px !important; */
	cursor: pointer !important;
	border-radius: 5px;
	border: 1px solid #198754;
	width: 100%;
	color: #198754;
	padding: 6px 2px;
}

#file-small {
	display: none;
	padding: 5px 4px;
	cursor: pointer !important;
}

.custom-file-input {
	cursor: pointer !important;
}

.form-file-text {
	cursor: pointer !important;
	pointer-events: visible !important;
}

.label_import {
	padding: 6px 10px;
	cursor: pointer;
	border-radius: 5px;
	border: 1px solid #198754;
	color: #198754;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
	name: "ProductIndexComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			dragging: false,
			dataObj: {
				name: "",
				order: "",
				observation: "",
			},
			isDisabled: true,
			selectMode: "single",
			selected: {},
			leadTimes: [],
			products: [],
			currentRouteName: "white",
			isView: true,
			isActive: false,
			styleObject: {},
			selectedSort: null,
			options: [
				{ value: null, text: 'Ordenar por' },
				{ value: 'desc', text: 'Mais recentes' },
				{ value: 'asc', text: 'Mais antigos' },
			],
			perPage: 10,
			currentPage: 1,
			search: '',
			filter: {
				code: '',
				derivation: '',
				line: '',
				und: '',
				supplier: ''
			},
			teste: [],
			codeCompany: 10,
			typeIntegration: false,
			integration: "",
			date: "",
			progresso: 0,
			itensProcessados: [],
			processando: false,
			importId: '',
			itemsPerPage: 10,
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,		
			paginaAtual: 1,
			itensPorPagina: 10,	
		};
	},

	mounted() {
		this.getProducts();
	},

	methods: {
		changePage(page) {
			this.currentPage = page;
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		showModal() {
			this.$refs["my-modal"].show();
		},

		hideModal() {
			this.$refs["my-modal"].hide();
			this.selectedProduct = "";
		},

		showModalSellImpo() {
			this.$refs["import-sell-progress"].show();
		},

		view(item) {
			if (item) this.currentRouteName = item;
			this.styleObject = {
				color: 'red',
				fontSize: '13px'
			}
		},

		integrationDate() {
			if (this.integration == 'P') {
				this.typeIntegration = true
			}

			if (this.integration == 'T') {
				this.typeIntegration = false
			}
		},

		noView(item) {
			console.log(item)
		},

		sendEst(item) {
			this.$router.push(`/products/${item.id}/est-seg`);
		},

		async sendLeadProduct(item) {
			const formData = new FormData();
			formData.append("product_id", item.id);
			await this.$http
				.post(`/products/store/leadTimes`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.$router.push(`/products/${item.id}/config/lead`);
					}
				})
				.catch(function (error) {
					console.log(error);
				});

		},

		async getProducts() {
			await this.$http
				.get(`/products/allProducts`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.products = response.data.products;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async filterProduct() {
			const formData = new FormData();
			formData.append("code", this.filter.code ? this.filter.code : '');
			formData.append("derivation", this.filter.derivation ? this.filter.derivation : '');
			formData.append("line", this.filter.line ? this.filter.line : '');
			formData.append("und", this.filter.und ? this.filter.und : '');
			formData.append("supplier", this.filter.supplier ? this.filter.supplier : '');
			formData.append("order", this.selectedSort);

			this.$http
				.post(`/products/filter`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.products = response.data.products;
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		},

		clearFilter() {
			this.filter = {
				code: '',
				derivation: '',
				line: '',
				und: '',
				supplier: '',
			}
			this.getProducts();
			this.$refs["filters"].hide();
		},

		async orderProducts() {
			const formData = new FormData();
			formData.append("code", this.filter.code);
			formData.append("derivation", this.filter.derivation);
			formData.append("line", this.filter.line);
			formData.append("und", this.filter.und);
			formData.append("supplier", this.filter.supplier);
			formData.append("order", this.selectedSort);

			this.$http
				.post(`/products/filter`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.products = response.data.products;
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		},

		async sincronizedProducts() {
			this.hideModal();
			this.showModalSellImpo();

			const formData = new FormData();
			formData.append("codEmp", this.codeCompany);
			formData.append("tipoIntegracao", this.integration);
			formData.append("datBas", this.date);

			this.$http
				.post(`/products/sincronized/produtos`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						this.sincronizedProduct = response.data.Body.ExportarProdutoResponse.result.produto;
						// this.downloadProducts(response.data.Body.ExportarProdutoResponse.result.produto)
						console.log(response.data.Body.ExportarProdutoResponse.result.produto)
						// this.$refs["my-modal"].hide();
						this.$swal.hideLoading();
						let mensagem = response.data.Body.ExportarProdutoResponse.result.tipoRetorno;
						if (mensagem == '0') {
							this.progresso = 0;
							this.itensProcessados = [];
							this.processando = true;

							const totalItens = this.sincronizedProduct.length;

							const processarItem = (index) => {
								if (index >= totalItens) {
									this.progresso = 100;
									this.processando = false;
									return;
								}

								setTimeout(() => {
									this.itensProcessados.push(this.sincronizedProduct[index]);
									this.progresso = Math.round(((index + 1) / totalItens) * 100);
									processarItem(index + 1);
								}, 1000);

								if (this.progresso == 100) {
									this.showModalSellImpo();
									this.goSuccess("Download concluido com sucesso!")
									this.getProducts();
								}
							};

							processarItem(0);


							// for (let i = 0; i < this.sincronizedProduct.length; i++) {
							// 	// for (let i = 0; i < 5; i++) {
							// 	const sell = this.sincronizedProduct[i];
							// 	this.downloadProducts(sell);
							// }

							this.sincronizedProduct.map((value) => {
								if (value.codOri == 100 || value.codOri == 2 || value.codOri == 300 || value.codOri == 400) {
									if (value.derivacao.length > 0) {
										value.derivacao.map((derivacao, index) => {
											setTimeout(() => {
												this.downloadProducts(value, derivacao);
											}, index * 200);
												
										});
									}
								}
							});

							// location.reload();
							// this.getProducts();
						}
					}
				})
				.catch(function (error) {
					console.log(error);
				});

		},

		async downloadProducts(products,derivacao) {
			const formData = new FormData();
			// for (let i = 0; i < products.derivacao.length; i++) {
				let codDer = 0;
				let desCpl = 0;
				let desDer = 0;
				let pesBru = 0;
				let pesLiq = 0;
				let sitDer = 0;

			// 	if (products.codOri == 100 || products.codOri == 2 || products.codOri == 300 || products.codOri == 400) {
					if (derivacao.codDer) codDer = derivacao.codDer; else codDer = '-';
					if (derivacao.desCpl) desCpl = derivacao.desCpl; else desCpl = '-';
					if (derivacao.desDer) desDer = derivacao.desDer; else desDer = '-';
					if (derivacao.pesBru) pesBru = derivacao.pesBru; else pesBru = '-';
					if (derivacao.pesLiq) pesLiq = derivacao.pesLiq; else pesLiq = '-';
					if (derivacao.sitDer) sitDer = derivacao.sitDer; else sitDer = '-';

					formData.append("cplPro", products.cplPro ? products.cplPro : '-');

					formData.append("derivacao", codDer  ? codDer : '-');
					formData.append("desCpl", desCpl  ? desCpl : '-');
					formData.append("desDer", desDer  ? desDer : '-');
					formData.append("pesBru", pesBru  ? pesBru : '-');
					formData.append("pesLiq", pesLiq  ? pesLiq : '-');
					formData.append("sitDer", sitDer  ? sitDer : '-');
					
					formData.append("desFam", products.desFam != "[object Object]" ? products.desFam : '-');
					formData.append("seqInt", products.seqInt != "[object Object]" ? products.seqInt : '-');
					formData.append("codGru", products.codGru != "[object Object]" ? products.codGru : '-');
					formData.append("desGru", products.desGru != "[object Object]" ? products.desGru : '-');
					formData.append("codSgr", products.codSgr != "[object Object]" ? products.codSgr : '-');
					formData.append("desSgr", products.desSgr != "[object Object]" ? products.desSgr : '-');
					formData.append("sitPro", products.sitPro != "[object Object]" ? products.sitPro : '-');

					formData.append("desPro", products.desPro ? products.desPro : '-');
					formData.append("codOri", products.codOri ? products.codOri : '-');
					formData.append("codPro", products.codPro ? products.codPro : '-');

			// 	}

				await this.$http
					.post(`/products/download/produtos`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response);
						} else {
							console.log(response)
						}
					})
					.catch(function (error) {
						console.log(error);
					});
		},
		// ATUALIZA O NOME DOS PRODUTOS QUANDO ESTIVEREM VARIAS
		async updateNameProduct() {
			this.$http
				.get(`/products/update/name_product`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						console.log(response)
						this.getProducts();
						this.$refs["my-modal"].hide();
						this.$swal.hideLoading();
						location.reload();
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async inactiveProduct(product) {
			let is_active = 1
			if (product.is_active == 1) {
				is_active = 0;
			} else {
				is_active = 1;
			}
			const formData = new FormData();
			formData.append("id", product.id);
			formData.append("is_active", is_active);

			this.$http
				.post(`/products/update`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						console.log(response)
						this.getProducts();
						this.goSuccess(response.data.message);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		}

	},

	computed: {
		filteredList() {
			return this.products.filter((product) => {
				const nameMatch = product.name.toLowerCase().includes(this.search.toLowerCase());
				const codeMatch = product.code.includes(this.search.toLowerCase());
				// Se você deseja filtrar por ambos name e code, use && (E lógico)
				// Se você deseja filtrar por name OU code, use || (OU lógico)
				return nameMatch || codeMatch;
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
		fieldsProduct: function () {
			let fields = [
				{ key: "view", label: "" },

				{ key: "code", label: "Código" },

				{
					key: "name",
					label: "Nome",
				},

				{
					key: "derivation",
					label: "Derivação",
				},

				{
					key: "line_product",
					label: "Linha",
				},

				{
					key: "codOri",
					label: "Origem",
				},

				{
					key: "active_product",
					label: "Ativo",
				},

				{
					key: "actions",
					label: "",
				},
			];
			return fields;
		},

		fields: function () {
			let fields = [
				{
					key: "name",
					label: "Nome",
				},
				{ key: "sort", label: "Ordem" },
				{ key: "observation", label: "Observação" },
				{ key: "action", label: "" },
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.products.slice(start, end);
		},
		totalPages() {
			return Math.ceil(this.products.length / this.itemsPerPage);
		},
		numeroDePaginas() {
			return Math.ceil(this.products.length / this.perPage);
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.products.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()) || item.code.includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
