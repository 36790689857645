<template>
  <div class="main">
    <div
      class="bg-login"
    >
      <!-- Imagem pessoa de fundo -->
      <img
        class="login__peaple--bg"
        alt="Imagem"
        src="@/assets/img/foto_login.jpeg"
      />

      <div class="forgot">
        <div class="content d-flex justify-content-center align-items-center">
          <div class="form">
            <header>
              <img alt="logo" src="@/assets/logo.png" />
            </header>
            <form @submit.prevent="submit">
              <div class="form-group left">
                <input
                  type="email"
                  class="form-control form-control-sm"
                  id="email"
                  aria-describedby="emailHelp"
                  placeholder="Email"
                  v-model="dataObj.forgotPassword.email"
                  required
                />
              </div>

              <button type="submit" class="btn button-login center">
                RECUPERAR
              </button>

              <div class="login__line"><span>OU</span></div>

              <button
                type="buttom"
                class="btn button-yellow center font_6rem"
                @click.prevent="goRecovery"
              >
                JÁ TENHO UM CÓDIGO
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ForgotPasswordComponent",
  data() {
    return {
      dataObj: {
        forgotPassword: {
          email: "",
        },
      },
    };
  },
  methods: {
    goRecovery() {
      this.$router.push("/recovery-password");
    },
    // goError() {
    //   this.$swal({
    //     toast: true,
    //     position: "top-end",
    //     showConfirmButton: false,
    //     timer: 4000,
    //     icon: "error",
    //     title: "Ops...",
    //     text: "Não encontramos um usuário para este e-mail.",
    //   });
    // },
    // async submit() {
    //   await this.$http
    //     .post("/auth/forgot-password", this.dataObj)
    //     .then(() => {
    //       this.$swal({
    //         toast: true,
    //         position: "top-end",
    //         showConfirmButton: false,
    //         timer: 4000,
    //         icon: "info",
    //         title: "Atenção",
    //         text: "Seu Código de verificação, foi encaminhado por e-mail com sucesso.",
    //       });
    //       this.goRecovery();
    //     })
    //     .catch((error) => {
    //       console.log(error);
    //       this.goError();
    //     });
    // },
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />

