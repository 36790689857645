<template>
  <div class="sell">
    <!-- PRIMEIRO CARD COM GRÁFICO -->
    <b-card class="card-chart1">
      <b-row>
        <b-col class="title">
          <p>Atingimento</p>
          <!-- <p class="text-muted subtitle">Total de vendas x Forecast</p> -->
        </b-col>
        <b-col class="values">
          <p>{{porcentagemDiasPassados}}% de {{monthCurrenty}}</p>
          <p class="text-muted subtitle">{{ this.diasUteisAteDataAtual }} de {{ this.diasUteisNoMes }} dias úteis</p>

        </b-col>
      </b-row>

      <div class="outer">
        <doughnutChart
          :chart-data="dataAtingimento"
          :options="optionsPie"
          width="50px"
          height="15px"
        />
      </div>
    </b-card>

    <!-- SEGUNDO CARD COM GRÁFICO -->
    <b-card class="card-chart2">
      <!-- <b-row>
        <b-col class="values">
          <p class="sell-title">Vendas real</p>
          <p class="text-muted sell">88%</p>
        </b-col>

        <b-col class="values">
          <p class="plan-title">Plano de vendas</p>
          <p class="text-muted plan">92%</p>
        </b-col>
      </b-row> -->

      <ChartSell
        :chart-data="dataVendas"
        :options="optionsVendas"
        width="10px"
        height="5px"
      />
    </b-card>

    <!-- TERCEIRO CARD COM GRÁFICO -->
    <b-card class="card-chart3">
      <b-row>
        <b-col class="select-filter" md="8">
          <h4>Variações de vendas</h4>
          <b-form-group class="option">
            <b-form-radio-group
              v-model="selectedRadio"
              :options="optionsRadio"
              :aria-describedby="ariaDescribedby"
              name="radio-inline"
            ></b-form-radio-group>
          </b-form-group>
        </b-col>
        <b-col class="select-filter" md="">
          <b-form-input id="type-date" class="datepicker" type="month" v-model="selected" placeholder="" @change="selectDate()"></b-form-input>
        </b-col>

        <b-col>
          <b-form-input type="text" v-model="filtro" placeholder="Digite para filtrar"></b-form-input>
        </b-col>
      </b-row>

      <hr />

      <b-row>
        <b-col class="products-up">

          <div>
            <div id="menu-list" v-for="item in itemsFiltradosPaginados" :key="item.id">
              <p class="name-product">{{ item.name }}</p>
              <p class="description-product text-muted">Cód. {{ item.code }}</p>
              <p class="description-product text-muted">
                Variação {{ item.derivation }}
              </p>
              <p class="value-product">{{ item.totalQuantitySold }}</p>

              <hr>
            </div>
          </div>

          <b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
        </b-col>
      </b-row>
    </b-card>
  </div>
</template>

<script>
import doughnutChart from "./charts/LinesChart";
import ChartSell from "./charts/Scatter";
import dataVariations from "./jsons/variacoes";
import moment from "moment";
import { format } from 'date-fns'; // Importe as funções necessárias do date-fns

export default {
  name: "SellComponent",
  components: {
    doughnutChart,
    ChartSell,
  },

  data() {
    return {
      optionsPie: [],
      dataAtingimento: [],
      selected: format(new Date(), 'yyyy-MM'),
      options: [{ value: null, text: "Dezembro, 2022" }],

      optionsVendas: [],
      dataVendas: [],
      selectedRadio: "qtde",
      optionsRadio: [
        { text: "Quantidade", value: "qtde" },
      ],

      variations: [],
      diasUteisNoMes: 0,
      diasUteisAteDataAtual: 0,
      porcentagemDiasPassados: 0,
      monthCurrenty: '',
      months: [],
      resultados: [],
      paginaAtual: 1,
      itensPorPagina: 5,
      filtro: '',
      achievement: []
    };
  },

  mounted() {
    this.calcularDiasUteis();
    this.calcularDiasUteisAteDataAtual();

    this.getChartAchievement();
    this.getChartVariacao();
    // this.getVariationsSell();
    this.generateMonths();
    this.getQtdSellsChart()
  },

  methods: {
    calcularDiasUteis() {
      const dataAtual = new Date();
      const ultimoDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth() + 1, 0).getDate();

      let diasUteis = 0;

      for (let dia = 1; dia <= ultimoDiaDoMes; dia++) {
        const dataDoDia = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), dia);

        if (dataDoDia.getDay() !== 0 && dataDoDia.getDay() !== 6) {
          diasUteis++;
        }
      }

      this.diasUteisNoMes = diasUteis;
      moment.locale('pt-br');
      this.monthCurrenty = moment().format('MMM/YY');
    },
    calcularDiasUteisAteDataAtual() {
      const dataAtual = new Date();
      const primeiroDiaDoMes = new Date(dataAtual.getFullYear(), dataAtual.getMonth(), 1);

      let diasUteis = 0;
      let dataCorrente = new Date(primeiroDiaDoMes);

      while (dataCorrente <= dataAtual && diasUteis < this.diasUteisNoMes) {
        if (dataCorrente.getDay() !== 0 && dataCorrente.getDay() !== 6) {
          diasUteis++;
        }

        dataCorrente.setDate(dataCorrente.getDate() + 1); // Avança para o próximo dia
      }

      this.diasUteisAteDataAtual = diasUteis;
      this.porcentagemDiasPassados = ((this.diasUteisAteDataAtual / this.diasUteisNoMes) * 100).toFixed(2); // Fixando 2 casas decimais

    },

    generateMonths() {
      const threeMonthsAgo = moment().subtract(3, 'months');
      const tenMonthsLater = moment().add(10, 'months');

      let currentDate = threeMonthsAgo.clone();
      const months = [];

      while (currentDate.isBefore(tenMonthsLater)) {
        months.push(currentDate.clone());
        currentDate.add(1, 'month');
      }

      this.months = months;
    },

    async getQtdSellsChart(){
      await this.$http
        .get(`/processes/chart/sell/quantity`)
        .then((response) => {
          this.resultados = response.data.resultados;
          this.getChartVendas();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getChartVendas() {
      this.dataVendas = {
        labels: this.resultados.map((month) => month.mes),
        datasets: [
          {
            type: "bar",
            backgroundColor: [
              "#343C87",
              "#343C87",
              "#343C87",
              "#F6AC33",
              "#42C6BE",
              "#42C6BE",
              "#42C6BE",
              "#42C6BE",
              "#42C6BE",
              "#42C6BE",
              "#9747FF",
              "#9747FF",
              "#9747FF",
              "#9747FF",
              "#9747FF",
              "#9747FF",
            ],
            borderWidth: 1,
            label: "Quantidade Vendida",
            data: this.resultados.map((month) => month.total_sell),
            yAxisCLASS: "y-axis-gravity",
          },

          {
            type: "bar",
            backgroundColor: [
              "#AEB3E7",
              "#AEB3E7",
              "#AEB3E7",
              "#FFD896",
              "#98E6E1",
              "#98E6E1",
              "#98E6E1",
              "#98E6E1",
              "#98E6E1",
              "#98E6E1",
              "#D6B7FF",
              "#D6B7FF",
              "#D6B7FF",
              "#D6B7FF",
              "#D6B7FF",
              "#D6B7FF",
            ],
            borderWidth: 1,
            label: "Ordens de compra",
            data: this.resultados.map((month) => month.total_purchase),
            yAxisID: "y-axis-density",
          },

          {
            type: "line",
            label: "Estoque final",
            data: this.resultados.map((month) => month.total),
            lineTension: 0,
            fill: false,
            borderColor: ["#ED7D31"],
          },
        ],
      };

      this.optionsVendas = {
        legend: {
          position: "bottom",
        },
        scales: {
          xAxes: [
            {
              barPercentage: 1,
              categoryPercentage: 0.6,
            },
          ],
          yAxes: [
            {
              class: "y-axis-gravity",
              id: "y-axis-density",
              gridLines: {
                drawBorder: false,
              },
            },
          ],
        },
      };
    },

    // CARD COM VARIAÇÕES DE VENDAS
    getVariationsSell() {
      this.variations = dataVariations;
    },

    async getChartVariacao(){
      let formData = new FormData();
      formData.append("date", this.selected);

      await this.$http
        .post(`/processes/chart/sell/variation`, formData)
        .then((response) => {
          this.variations = response.data.variations;
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    async getChartAchievement(){
      let formData = new FormData();
      formData.append("date", this.selected);

      await this.$http
        .post(`/processes/chart/sell/achievement`, formData)
        .then((response) => {
          this.achievement = response.data.achievement;
          this.getChartAtingimento();
        })
        .catch((error) => {
          console.log(error.response);
        });
    },

    getChartAtingimento() {
      this.dataAtingimento = {
        labels: this.achievement.map((item) => moment(item.data).format("DD/MM")  ),

        datasets: [
          {
            label: "Vendas real + total de pedidos",
            data: this.achievement.map((item) => item.valores),
            backgroundColor: ["#FF5658"],
            borderColor: "#FF5658",
            fill: false,
            pointStyle: "circle",
            pointRadius: 5,
          },
          {
            label: "Vendas brutas real",
            data: this.achievement.map((item) => item.sellsLiquidado),
            backgroundColor: ["#565656"],
            borderColor: "#565656",
            fill: false,
            pointStyle: "circle",
            pointRadius: 5,
          },
        ],
      };

      this.optionsPie = {
        responsive: true,
        legend: {
          position: "bottom",
          labels: {
            usePointStyle: true,
          },
        },

        scales: {
          y: {
            type: "linear",
            display: true,
            position: "left",
          },
          y1: {
            type: "linear",
            display: true,
            position: "right",
          },
        },

        tooltips: {
          backgroundColor: "#FFF",
          bodyFontColor: "#000",
          bodyFontSize: 14,
          displayColors: false,
        },
      };
    },

    paginaMudada(pagina) {
      // Lidar com a mudança de página
      console.log('Página mudada para:', pagina);
    },

    selectDate(){
      this.getChartVariacao();
    }
  },

  computed: {
    totalItensFiltrados() {
      return this.itemsFiltrados.length;
    },
    itemsFiltrados() {
      // Filtrar a lista de itens com base no texto inserido no campo de filtro
      return this.variations.filter(item => item.name.toLowerCase().includes(this.filtro.toLowerCase()) || item.code.toLowerCase().includes(this.filtro.toLowerCase()));
    },
    itemsFiltradosPaginados() {
      const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
      const fim = inicio + this.itensPorPagina;
      return this.itemsFiltrados.slice(inicio, fim);
    },
  },
  filters: {
    dateFormat: function (date) {
        return moment(date).format("DD/MM");
    },
  }
};
</script>

<style lang="scss" src="./style.scss" scoped />
