<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="users">
					<div class="users-header create-stock">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'stock-collator-index' }"> <font-awesome-icon :icon="['fas', 'arrow-left']" /> Agrupador de estoque</b-breadcrumb-item>
							<b-breadcrumb-item active> Novo agrupador de estoque</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<h3>Novo agrupador de estoque</h3>
						<b-card class="card-add-name">
							<b-row>
								<b-col md="1">
									<label for="">Nome</label>
								</b-col>

								<b-col md="11">
									<b-form-input placeholder="Digite aqui" v-model="dataObj.name"></b-form-input>
								</b-col>
							</b-row>
						</b-card>

						<b-row>
							<b-col md="5">
								<b-card class="card-stocks">
									<p>Depósito ERP</p>
									<div class="form">
										<b-form-input placeholder="Procurar" v-model="searchAdd"></b-form-input>
										<button type="submit">Search</button>
									</div>

									<div>
										<b-form-checkbox
											id="checkbox-1"
											v-model="ungrouped"
											name="checkbox-1"
											value="true"
											unchecked-value="false"
											@change=changeStock()
											style="font-size:0.8rem"
										>
											Exibir somente depósitos não agrupados
										</b-form-checkbox>			
									</div>

									<div class="list-stocks">
										<ul class="topics">
											<li v-for="item in filteredListStock" :key="item.id">

												<input type="checkbox" :id="item.id" :value="item" v-model="selected" />
												<label :for="item.id">
													<font-awesome-icon :icon="['fas', 'layer-group']" />{{ item.code }} - {{ item.name }}</label>
											</li>
										</ul>
									</div>
								</b-card>
							</b-col>

							<b-col md="2" class="btn-actions">
								<div>
									<b-button @click="addAll" :disabled="isDisableAll">
										<font-awesome-icon :icon="['fas', 'angle-double-right']" />
									</b-button>
								</div>

								<div>
									<b-button @click="addOne" :disabled="isDisableOne">
										<font-awesome-icon :icon="['fas', 'angle-right']" />
									</b-button>
								</div>

								<div>
									<b-button @click="toGoBackOne">
										<font-awesome-icon :icon="['fas', 'angle-left']" />
									</b-button>
								</div>

								<div>
									<b-button @click="toGoBackAll">
										<font-awesome-icon :icon="['fas', 'angle-double-left']" />
									</b-button>
								</div>
							</b-col>

							<b-col md="5">
								<b-card class="card-stocks">
									<p>Depósitos agrupados</p>
									<div class="form">
										<b-form-input placeholder="Procurar" v-model="search"></b-form-input>
										<button type="submit">Search</button>
									</div>
									<small class="text-muted" v-if="this.newStock == ''">Selecione os depósitos que deseja agrupar.</small>

									<div class="list-stocks">
										<ul class="topics">
											<li v-for="item in filteredList" :key="item.id">

												<input type="checkbox" :id="item.id" :value="item" v-model="selected" />
												<label :for="item.id">
													<font-awesome-icon :icon="['fas', 'layer-group']" />{{ item.code }} - {{ item.name }}</label>
											</li>
										</ul>
									</div>
								</b-card>
							</b-col>

							<b-col md="6"></b-col>
							<b-col md="6" class="btn-salve">
								<b-button class="save button-padrao-add" @click="saveStock" v-if="!this.dataObj.id">salvar</b-button>
								<b-button class="save button-padrao-add" @click="updateStock" v-else>Atualizar</b-button>
								<br />
								<b-button class="button-padrao-cancel" :to="{ name: 'stock-collator-index' }">cancelar</b-button>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
	name: "StockCollatorCreateComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			dataObj: {
				stock: [],
				name: "",
			},

			selected: [],

			newStock: [],
			isDisableAll: false,
			isDisableOne: false,
			stocks: [],
			obj: this.$route.params.dataObj,
			searchAdd: "",
			search: "",
			ungrouped: false
		};
	},

	mounted() {
		this.getStocksAceitos();
		this.editStock();
	},

	methods: {
		//TODOS OS ESTOQUES DISPONIVEIS E ACEITOS
		async getStocksAceitos() {
			await this.$http
				.get(`/stocks/all/accepteds`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.stocks = response.data.stocks_accepted;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		// SALVAR AGRUPADOR DE ESTOQUE
		async saveStock() {
			if (this.dataObj.name == "" || this.dataObj.stock == []) {
				this.goError("Preencha todos os campos");
			} else {
				let formData = new FormData();
				formData.append("name", this.dataObj.name);
				await this.$http
					.post(`/stockCollator/store`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							var network_id = response.data.stock_collator.id;
							this.dataObj.stock.map((stock) => {
								this.storeItems(network_id, stock);
							});

						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async storeItems(network_id, file) {
			console.log(file)
			let formData = new FormData();
			formData.append("stock_collator", network_id);
			formData.append("stock", file.id);
			formData.append("code_stock", file.code);
			await this.$http
				.post(`/stockCollator/item/store`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						// this.goSuccess("Agrupador de estoque cadastrado com successo");
						// this.$router.push("/stock-collator");
						let rota = `/stock-collator`;

						if (this.$route.path !== rota) {
							this.$router.push(rota);
							this.goSuccess("Agrupador de estoque cadastrado com successo");
						}

						this.dataObj.stock = [];
						this.dataObj.name = "";
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		// editar dados do agrupador
		editStock() {
			this.dataObj = this.obj.dataObj;
			this.getStocksId(this.dataObj.id);
		},

		async getStocksId(id) {
			await this.$http
				.get(`/stockCollator/stocks/${id}`)

				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						response.data.stocks.forEach((a) => {
							this.newStock.push(a.stock);
						});

						this.newStock.forEach((a) => {
							this.stocks = this.stocks.filter((item) => {
								return item.id !== a.id;
							});
						});
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async updateStock() {
			if (this.dataObj.name == "" || this.newStock == []) {
				this.goError("Preencha todos os campos");
			} else {
				let formData = new FormData();
				formData.append("name", this.dataObj.name);
				formData.append("id", this.dataObj.id);
				await this.$http
					.post(`/stockCollator/update`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							var network_id = response.data.stock_collator.id;

							if (this.newStock != []) {
								this.newStock.map((stock) => {
									this.storeItems(network_id, stock);
								});
							}

						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		// SELECIONAR TODOS OS ESTOQUES
		addAll() {
			this.stocks.forEach((item) => {
				this.newStock.push(item);
			});
			this.stocks.forEach((a) => {
				this.stocks = this.stocks.filter((item) => {
					return item !== a;
				});
			});
			this.dataObj.stock = this.newStock;

			this.isDisableAll = true;
			this.isDisableOne = true;
		},

		// RETORNAR TODOS OS ESTOQUES
		toGoBackAll() {
			this.newStock.forEach((item) => {
				this.stocks.push(item);
			});
			this.newStock.forEach((a) => {
				this.newStock = this.newStock.filter((item) => {
					return item !== a;
				});
			});

			this.dataObj.stock = [];

			this.isDisableOne = false;
			this.isDisableAll = false;
		},

		// SELECIONAR UM ESTOQUE POR VEZ
		addOne() {
			console.log(this.selected)
			// var stockArray = this.selected;
			var stockObjc = this.selected;
			this.selected.map((file) => {
				this.newStock.push(file);
			});
			stockObjc.forEach((a) => {
				this.stocks = this.stocks.filter((item) => {
					return item !== a;
				});
			});

			// this.newStock.push(stockArray);
			// this.newStock.map((file) => {
			// 	this.newStock = file;
			// });
			console.log(this.newStock)

			this.dataObj.stock = this.newStock;

			this.selected = [];
			// this.isDisableOne = true;
		},

		// RETORNAR UM ESTOQUE POR VEZ
		toGoBackOne() {
			var stockObjc = this.selected;
			stockObjc.map((file) => {
				this.stocks.push(file);
			});

			stockObjc.forEach((a) => {
				this.newStock = this.newStock.filter((item) => {
					return item.id !== a.id;
				});
			});

			stockObjc.forEach((a) => {
				this.dataObj.stock = this.dataObj.stock.filter((item) => {
					return item.id !== a.id;
				});
			});

			this.isDisableOne = false;
			this.isDisableAll = false;
			this.selected = [];
		},

		async changeStock() {
			let formData = new FormData();

			formData.append("ungrouped", this.ungrouped);
			await this.$http
				.post(`/stockCollator/ungrouped`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.stocks = response.data.stocks_ungrouped;
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		}
	},

	computed: {
		filteredList() {
			return this.newStock.filter(stock => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase()) || stock.code.toLowerCase().includes(this.search.toLowerCase())
			})
		},
		filteredListStock() {
			return this.stocks.filter(stock => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase()) || stock.code.toLowerCase().includes(this.searchAdd.toLowerCase())
			})
		}
	}
};
</script>

<style lang="scss" src="./style.scss" scoped />