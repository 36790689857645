<template>
	<div class="main">
		<PageComponent :title_nav="'Integrações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'products-index' }"> <font-awesome-icon :icon="['fas', 'arrow-left']" /> Listagem de produtos</b-breadcrumb-item>
							<b-breadcrumb-item active>Est. seg</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row class="product-est">
							<b-col md="12">
								<h5>{{dataObj.code}}  - {{dataObj.name}}</h5>

                <span>Informar o tempo em meses para o est.seg total do produto <strong> {{dataObj.name}} </strong></span>
							</b-col>

              <b-col md="6">
                <b-card>
                  <label for="">Código</label>
                    <b-form-input
                      id="input-1"
                      type="text"
											v-model="dataObj.code"
											readonly
											class="input-readonly"
                    ></b-form-input>

                  <label for="">Nome</label>
                    <b-form-input
                      id="input-1"
                      type="text"
											v-model="dataObj.name"
											readonly
											class="input-readonly"
                    ></b-form-input>

                  <label for="">Quantidade em mês</label>
                    <b-form-input
                      id="input-1"
                      type="text"
											v-model="dataObj.qtd"
											onkeypress='return event.charCode >= 48 && event.charCode <= 57'
                    ></b-form-input>

                </b-card>
              </b-col>

							<b-col md="12"></b-col>
							<b-col md="6"></b-col>
							<b-col md="6" class="btn-salve">
								<b-button
									class="save button-padrao-add"
									@click="saveEst"
									>salvar</b-button
								>
								<br />
								<b-button class="button-padrao-cancel" :to="{ name: 'products-index' }"
									>cancelar</b-button
								>
							</b-col>
						</b-row>

					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import data from "./jsondata.json";

export default {
	name: "EstSegComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			dragging: false,
			obj: this.$route.id,
			dataObj: {
				name: "",
				code: "",
				qtd: "",
			},
			isDisabled: true,
			selectMode: "single",
			selected: {},
			leadTimes: [],
      products: data.products,
			currentRouteName: "white",
      isView: true,
      isActive: false,
      styleObject:{},
		};
	},

	mounted() {
		this.dataEst();
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async dataEst(){
			await this.$http
				.get(`/products/${this.$route.params.id}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.dataObj = response.data.product;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async saveEst(){
			if(this.dataObj.qtd == "" || this.dataObj.qtd == null){
				this.goError("Preencha todos os campos");
			} else {
				let formData = new FormData();
				formData.append("qtd", this.dataObj.qtd);
				formData.append("id", this.dataObj.id);
					await this.$http
					.post(`/products/est/store`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.$router.push("/products");
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		showModal() {
			this.$refs["my-modal"].show();
		},

		hideModal() {
			this.$refs["my-modal"].hide();
			this.products = [];
			this.selectedProduct = "";
		},

    view(item){
			if (item) this.currentRouteName = item;
      this.styleObject= {
        color: 'red',
        fontSize: '13px'
      }
      alert(this.currentRouteName)
    },

    noView(item){
      alert(item)
    }

	},

	computed: {
		filteredList() {
			return this.newStock.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
		fieldsProduct: function () {
			let fields = [
				{ key: "view", label: "" },

				{ key: "code", label: "Código do produto" },

				{
					key: "name",
					label: "Nome do produto",
				},

				{
					key: "derivation",
					label: "Derivação do produto",
				},

				{
					key: "line",
					label: "Linha do produto",
				},

				{
					key: "actions",
					label: "",
				},
			];
			return fields;
		},

		fields: function () {
			let fields = [
				{
					key: "name",
					label: "Nome",
				},
				{ key: "sort", label: "Ordem" },
				{ key: "observation", label: "Observação" },
				{ key: "action", label: "" },
			];
			return fields;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
