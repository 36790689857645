<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Listagem de fatores</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row>
							<b-col md="6">
								<h5>Listagem de fatores</h5>
							</b-col>

							<b-col md="6" class="col-add-fator">
								<span class="">
									<font-awesome-icon :icon="['fas', 'eye']" class="" />
									Exibir somente ativos?
								</span>

								<label class="switch">
									<input type="checkbox" v-model="checkbox" @click="toggleCheckbox" />
									<div class="slider round"></div>
								</label>

								<b-button class="add-fator" :to="{ name: 'factor-create' }"
									v-if="userLoggedPermission.permissionfactorm == 1 || userLogged.role == 'Administrador'">
									<font-awesome-icon :icon="['fas', 'plus']" class="" />
									Novo fator
								</b-button>
							</b-col>

							<b-col md="12" class="col-search">
								<div class="form">
									<b-form-input placeholder="Pesquisar" v-model="search"></b-form-input>
									<button type="submit">Search</button>
								</div>
							</b-col>

							<div class="col-md-12 card">
								<b-table :items="itemsFiltradosPaginados" :fields="fieldsProduct" responsive="" class="table-factors">
									<template #cell(date_initial)="data">
										{{ data.item.date_initial | dateFormat }}
									</template>

									<template #cell(date_final)="data">
										{{ data.item.date_final | dateFormat }}
									</template>

									<template #cell(active)="">
										Sim
									</template>

									<template #cell(actions)="data">
										<b-button @click="editFactor(data.item)" style="text-decoration: none; color: black"
											v-if="userLoggedPermission.permissionfactorm == 1 || userLogged.role == 'Administrador'">
											<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
										</b-button>

										<b-button @click="removeFactor(data.item)"
											style="text-decoration: none; color: black"
											v-if="userLoggedPermission.permissionfactorm == 1 || userLogged.role == 'Administrador'">
											<h6><font-awesome-icon :icon="['fas', 'trash-alt']" /></h6>
										</b-button>
									</template>
								</b-table>

								<p class="line"></p>
							</div>

							<b-col md="1"></b-col>

							<b-col md="6"></b-col>
						</b-row>

						<b-row class="paginations-options1 mt-3">
							<b-col md="12" class="mt-3">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
							</b-col>

						</b-row>

					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";

export default {
	name: "FactorIndexComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			factors: [],
			checkbox: true,
			perPage: 6,
			currentPage: 1,
			search: '',
			userLoggedPermission: localStorage.getItem("userPermission")
				? JSON.parse(localStorage.getItem("userPermission"))
				: null,
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,	
			paginaAtual: 1,
			itensPorPagina: 5,
		};
	},

	mounted() {
		this.getFactors();
	},

	methods: {
		toggleCheckbox() {
			this.checkbox = !this.checkbox;
			this.$emit("setCheckboxVal", this.checkbox);
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getFactors() {
			await this.$http
				.get(`/factors`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.factors = response.data.factors;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		editFactor(factor) {
			this.$router.push({
				name: "factor-create",
				params: {
					dataObj: { factor },
				},
			});
		},

		async removeFactor(item) {
			let formData = new FormData();
			formData.append("id", item.id);
			await this.$swal
				.fire({
					text:
						"Você está excluindo o fator " + item.factor + " como o código " + item.code,
					title: "Confirmar exclusão de fator?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "SIM, EXCLUIR",
					cancelButtonText: `CANCELAR`,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#d33",
					customClass: {
						cancelButton:
							"btn btn-light border border-secondary rounded-pill px-md-5",
						confirmButton: "btn btn-danger rounded-pill px-md-5",
					},
					buttonsStyling: false,
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$http
							.post(`/factors/delete`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.goSuccess(response.data.message);
									this.getFactors();
								}
							})
							.catch((error) => {
								this.goError(error.response.data.message);
								console.log(error.response.data.message);
							});
					}
				});
		}
	},

	computed: {
		fieldsProduct: function () {
			let fields = [
				{ key: "code", label: "Código do produto" },

				{
					key: "name",
					label: "Nome do produto",
				},

				{
					key: "line_product",
					label: "Linha",
				},

				{
					key: "factor",
					label: "Fator",
				},

				{
					key: "date_initial",
					label: "Data inícial",
				},

				{
					key: "date_final",
					label: "Data final",
				},

				{
					key: "active",
					label: "Ativo atual",
				},

				{
					key: "actions",
					label: "",
				},
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.factors.slice(start, end);
		},

		filteredList() {
			return this.factors.filter((factor) => {
				const nameMatch = factor.name.toLowerCase().includes(this.search.toLowerCase());
				const codeMatch = factor.code.toString().toLowerCase().includes(this.search.toLowerCase());
				// Se você deseja filtrar por ambos name e code, use && (E lógico)
				// Se você deseja filtrar por name OU code, use || (OU lógico)
				return nameMatch || codeMatch;
			});
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.factors.filter(item => item.code.toLowerCase().includes(this.search.toLowerCase()) || item.name.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD.MM.YYYY");
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
