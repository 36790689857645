<template>
	<div class="main">
		<PageComponent :title_nav="'Budget/ForeCast'">
			<div slot="slot-pages" class="content-pages">
				<div class="budget">
					<div class="budget-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'budget' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Budget e ForeCast
							</b-breadcrumb-item>

							<b-breadcrumb-item active> Budget </b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
						<b-row>
							<b-col md="4" class="col-title">
								<p><strong> Budget {{ budgetYear ? budgetYear : '' }} </strong></p>
							</b-col>

							<b-col md="10" class="col-btns">
								<b-button class="btn-export" @click="getDownload()">
									<font-awesome-icon :icon="['fas', 'file-download']" /> Download
									planilha
								</b-button>
							</b-col>
							<b-col md="2">
								<div class="input-file-container">
									<input type="file" id="csv_file" name="csv_file" :disabled="disabledImport" :style="fieldStyle"
										class="form-control input-file btn-import" @change="loadCSV($event)" />
									<label for="csv_file" class="control-label input-file-trigger" :style="fieldStyle">
										<font-awesome-icon :icon="['fas', 'file-upload']" />Importar
										planilha</label>
								</div>
							</b-col>
							<b-col md="12">
								<b-form-input class="input-search" placeholder="Pesquisar" v-model="search"></b-form-input>
							</b-col>
							<b-col md="12">
								<div class="panel">
									<div class=" fix-width">
										<table class="table table-responsive">
											<thead>
												<tr>
													<th>Ações</th>
													<th>Código</th>
													<th>Descrição</th>
													<th class="th-label">Forecast</th>
													<th class="th-label">Jan</th>
													<th class="th-label">Fev</th>
													<th class="th-label">Mar</th>
													<th class="th-label">Abr</th>
													<th class="th-label">Mai</th>
													<th class="th-label">Jun</th>
													<th class="th-label">Jul</th>
													<th class="th-label">Ago</th>
													<th class="th-label">Set</th>
													<th class="th-label">Out</th>
													<th class="th-label">Nov</th>
													<th class="th-label">Dez</th>
												</tr>
											</thead>
											<tbody>
												<tr v-for="(csv, index) in itemsFiltradosPaginados" :key="index" :class="{ active: active === index }"
													style="cursor: pointer;">

													<td class="td-label">
														<b-button style="text-decoration: none; color: black"
															@click="editProduct(index, index, column, csv)" v-if="editingIndex !== index"
															class="btn-edit">
															<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
														</b-button>

														<b-button class="btn-check-circle" style="text-decoration: none; color: black"
															@click="saveItem(csv, index)" v-else>
															<h6><font-awesome-icon :icon="['fas', 'check-circle']" /></h6>
														</b-button>

														<b-button class="btn-circle" style="text-decoration: none; color: black" @click="cancelEdit()"
															v-if="editingIndex === index">
															<h6><font-awesome-icon :icon="['fas', 'times-circle']" /></h6>
														</b-button>

													</td>

													<td colspan="" @click="findProduct(csv)">
													<!-- <td colspan=""> -->
														<div class="accordion">
															<div header-tag="header" class="p-1" role="tab">
																<b-button block v-b-toggle="'accordion-' + index" variant="info" class="info-csv"
																	@click="toggleItem(index)">
																	<font-awesome-icon :icon="['fas', itemIsOpen(index) ? 'arrow-up' : 'arrow-down']" />
																	{{ csv.Código }}
																</b-button>
															</div>
															<div class="infos_product" accordion="my-accordion" v-if="itemIsOpen(index)">
																<b-card-body>
																	<b-card-text class="title">Derivação</b-card-text>
																	<p class="derivacao" v-for="item in derivations" :key="item.id">{{ item.derivation }}
																	</p>
																</b-card-body>
															</div>
														</div>
													</td>
													<td><label for="" class="descricao">{{ csv.Descrição }}</label>

														<div class="infos_product" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<b-card-text class="title">%</b-card-text>
																<p class="per" v-for="item in derivations" :key="item.id">
																	<b-form-input v-model="per">

																	</b-form-input>
																</p>

															</b-card-body>
														</div>

													</td>
													<td>
														<label for="">
															<span v-if="editingIndex !== index">{{ csv.Forecast ? csv.Forecast : '0' }}</span>
															<b-form-input v-else v-model="csv.Forecast" @input="calcule(csv.Forecast, index)"></b-form-input>
														</label>

														<div class="infos_product" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<b-card-text class="title">Qtde</b-card-text>
																<p class="qtde" v-for="item in derivations" :key="item.id">
																	<b-form-input v-model="result"></b-form-input>
																</p>
															</b-card-body>
														</div>
													</td>
													<td>
														<label for="">
															<span v-if="editingIndex !== index">{{ csv.Jan ? csv.Jan : '0' }}</span>
															<b-form-input v-else v-model="csv.Jan" @input="calcule(csv.Jan, index)"></b-form-input>
														</label>

														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_jan ? calcule_jan: 0 }}</span>
																</p>
															</b-card-body>
														</div>

													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Fev ? csv.Fev : '0' }}</span>
															<b-form-input v-else v-model="csv.Fev" @input="calcule(csv.Fev, index)"></b-form-input></label>

														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_fev ? calcule_fev : 0 }}</span>
																</p>
															</b-card-body>
														</div>

													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Mar ? csv.Mar : '0' }}</span>
															<b-form-input v-else v-model="csv.Mar" @input="calcule(csv.Mar, index)"></b-form-input></label>
														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_mar ? calcule_mar : 0 }}</span>
																</p>
															</b-card-body>
														</div>

													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Abr ? csv.Abr : '0' }}</span>
															<b-form-input v-else v-model="csv.Abr" @input="calcule(csv.Abr, index)"></b-form-input></label>
														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_abr ? calcule_abr : 0 }}</span>
																</p>
															</b-card-body>
														</div>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Mai ? csv.Mai : '0' }}</span>
															<b-form-input v-else v-model="csv.Mai" @input="calcule(csv.Mai, index)"></b-form-input></label>
														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_mai ? calcule_mai : 0 }}</span>
																</p>
															</b-card-body>
														</div>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Jun ? csv.Jun : '0' }}</span>
															<b-form-input v-else v-model="csv.Jun" @input="calcule(csv.Jun, index)"></b-form-input></label>
														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_jun ? calcule_jun : 0 }}</span>
																</p>
															</b-card-body>
														</div>

													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Jul ? csv.Jul : '0' }}</span>
															<b-form-input v-else v-model="csv.Jul" @input="calcule(csv.Jul, index)"></b-form-input></label>
														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_jul ? calcule_jul : 0 }}</span>
																</p>
															</b-card-body>
														</div>

													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Ago ? csv.Ago : '0' }}</span>
															<b-form-input v-else v-model="csv.Ago" @input="calcule(csv.Ago, index)"></b-form-input></label>
														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_ago ? calcule_ago : 0 }}</span>
																</p>
															</b-card-body>
														</div>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Set ? csv.Set : '0' }}</span>
															<b-form-input v-else v-model="csv.Set" @input="calcule(csv.Set, index)"></b-form-input></label>

														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_set ? calcule_set : 0 }}</span>
																</p>
															</b-card-body>
														</div>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Out ? csv.Out : '0' }}</span>
															<b-form-input v-else v-model="csv.Out" @input="calcule(csv.Out, index)"></b-form-input></label>
														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_out ? calcule_out : 0 }}</span>
																</p>
															</b-card-body>
														</div>
													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Nov != 'null' || csv.Nov != '' ? csv.Nov : '0' }}</span>
															<b-form-input v-else v-model="csv.Nov" @input="calcule(csv.Nov, index)"></b-form-input></label>

														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_nov ? calcule_nov : 0 }}</span>
																</p>
															</b-card-body>
														</div>

													</td>
													<td>
														<label for=""> <span v-if="editingIndex !== index">{{ csv.Dez != 'undefined' ? csv.Dez : '0' }}</span>
															<b-form-input v-else v-model="csv.Dez" @input="calcule(csv.Dez, index)"></b-form-input></label>
														<div class="infos_product_values" accordion="my-accordion" v-if="itemIsOpen(index)">
															<b-card-body>
																<p class="values" v-for="item in derivations" :key="item.id">
																	<span>{{ calcule_dez ? calcule_dez : 0 }}</span>
																</p>
															</b-card-body>
														</div>

													</td>

												</tr>
											</tbody>
										</table>
									</div>
								</div>
							</b-col>

						</b-row>

						<b-row class="paginations-options1 mt-4">
							<b-col md="12" class="mt-3">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
							</b-col>
						</b-row>

					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
	name: "ExportBudgetComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			perPage: 6,
			currentPage: 1,
			data: "",
			collapsed: true,
			channel_name: "",
			channel_fields: [],
			channel_entries: [],
			parse_header: [],
			parse_csv: [],
			sortOrders: {},
			sortKey: "",
			budgetYear: '',
			active: null,
			editingIndex: -1,
			disabled: false,
			openIndex: null,
			derivations: [],
			budgetId: '',
			itemsPerPage: 5, // Quantidade de itens por página
			result: 0,
			per: 0,
			disabledImport: false,
			budget_id: this.$route.params.id,
			jan: '',
			fev: '',
			mar: '',
			abr: '',
			mai: '',
			jun: '',
			jul: '',
			ago: '',
			set: '',
			out: '',
			nov: '',
			dez: '',
			search: '',
			calcule_jan: 0,
			calcule_fev: 0,
			calcule_mar: 0,
			calcule_abr: 0,
			calcule_mai: 0,
			calcule_jun: 0,
			calcule_jul: 0,
			calcule_ago: 0,
			calcule_set: 0,
			calcule_out: 0,
			calcule_nov: 0,
			calcule_dez: 0,
			paginaAtual: 1,
			itensPorPagina: 5,

		}

	},

	mounted() {
		// if (this.$route.params) {
		// 	this.budgetYear = this.$route.params.budget.budget.year
		// 	this.budgetId = this.$route.params.budget.budget.id
		// }

		this.getBudgets();
		this.findBudget();
	},

	methods: {
		async findBudget() {
			if (this.budget_id) {
				await this.$http
					.get(`/budget/${this.budget_id}`)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							let budgetId = response.data.budget;

							this.budgetYear = budgetId.year;
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},


		toggleItem(index) {
			this.openIndex = this.openIndex === index ? null : index;
		},
		itemIsOpen(index) {
			return this.openIndex === index;
		},

		editItem(index) {
			this.editingIndex = index;
		},

		editProduct(item, index, column, prod) {
			this.editingIndex = index;
			this.disabled = true;
			console.log(prod);
		},

		cancelEdit() {
			this.editingIndex = -1;
			this.disabled = false;
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async validationYear() {
			await this.$http
				.get(`/budget/getYear`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.getYear = response.data.year;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async getDownload() {
			await this.$http
				.get(`/budget/downloadCsv`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						// const data = response.data;
						const dataWithCustomColumns = this.addCustomColumns(response.data);
						this.downloadCSV(dataWithCustomColumns);

						console.log(response.data);
						this.data = response.data;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		downloadCSV(data) {
			const csvData = this.convertToCSV(data);
			const blob = new Blob([csvData], { type: "text/csv;charset=utf-8;" });
			const link = document.createElement("a");
			link.setAttribute("href", URL.createObjectURL(blob));
			link.setAttribute("download", "BUDGET - Planilha para importação.csv");
			document.body.appendChild(link);
			link.click();
			document.body.removeChild(link);
		},

		convertToCSV(data) {
			const csvArray = [];
			const columnHeaders = Object.keys(data[0]);
			csvArray.push(columnHeaders.join(";"));

			data.forEach((item) => {
				const values = columnHeaders.map((header) => item[header]);
				csvArray.push(values.join(";"));
			});

			return csvArray.join("\n");
		},

		addCustomColumns(data) {
			return data.map((item) => ({
				...item,
				Forecast: ``,
				Jan: ``,
				Fev: ``,
				Mar: ``,
				Abr: ``,
				Mai: ``,
				Jun: ``,
				Jul: ``,
				Ago: ``,
				Set: ``,
				Out: ``,
				Nov: ``,
				Dez: ``,
			}));
		},

		closeAll() {
			this.$refs.panel.forEach((panel) => {
				panel.style.maxHeight = null;
			});
		},

		toggleCollapse(index) {
			this.collapsed = !this.collapsed; // Alterna o estado do colapso ao clicar no botão
			this.active = this.active === index ? null : index;
		},

		async findProduct(item) {
			this.getAllBudgetDers(item)
		},

		async createBudgetItems(der, item) {
			const formData = new FormData();
			formData.append("code", der.code);
			formData.append("derivation", der.derivation);
			formData.append("forecast_value", item.Forecast);
			formData.append("jan", 0);
			formData.append("fev", 0);
			formData.append("mar", 0);
			formData.append("abr", 0);
			formData.append("mai", 0);
			formData.append("jun", 0);
			formData.append("jul", 0);
			formData.append("ago", 0);
			formData.append("set", 0);
			formData.append("out", 0);
			formData.append("nov", 0);
			formData.append("dez", 0);
			formData.append("id_budget", this.budget_id);
			formData.append("id_product", item.id);

			formData.append("user_id", this.userLogged.id);

			console.log(item)
			await this.$http
				.post(`/budget/store/product/derivations`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						// this.derivations = response.data.derivations;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async getAllBudgetDers(item) {
			const formData = new FormData();
			formData.append("code", item.Código);

			console.log(item.Código)
			await this.$http
				.post(`/budget/product`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						console.log("der", response.data.derivations)

						this.derivations = response.data.derivations;
						let derivations = response.data.derivations;


						derivations.forEach((der)  => {
							console.log("der", der)
							this.createBudgetItems(der, item)
						})

						this.sumForecast(item.Forecast, this.derivations.length)
						this.sumForecast(item.Forecast, this.derivations.length, item.Jan, item.Fev, item.Mar, item.Abr, item.Mai, item.Jun, item.Jul, item.Ago, item.Set, item.Out,
							item.Nov, item.Dez)

					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		sortBy: function (key) {
			var vm = this;
			vm.sortKey = key;
			vm.sortOrders[key] = vm.sortOrders[key] * -1;
		},

		csvJSON(csv) {
			var vm = this;
			var lines = csv.split("\n");
			var result = [];
			var headers = lines[0].split(";");
			vm.parse_header = lines[0].split(";");
			lines[0].split(";").forEach(function (key) {
				vm.sortOrders[key] = 1;
			});

			lines.map(function (line, indexLine) {
				if (indexLine < 1) return;

				var obj = {};
				var currentline = line.split(";");

				headers.map(function (header, indexHeader) {
					obj[header] = currentline[indexHeader];
				});

				result.push(obj);
			});

			result.pop();

			console.log("result")
			console.log(result)

			result.forEach((item) => {
				let formData = new FormData();

				formData.append("code", item.Código);
				formData.append("name", item.Descrição);
				formData.append("forecast", item.Forecast);
				formData.append("jan", item.Jan);
				formData.append("fev", item.Fev);
				formData.append("mar", item.Mar);
				formData.append("abr", item.Abr);
				formData.append("mai", item.Mai);
				formData.append("jun", item.Jun);
				formData.append("jul", item.Jul);
				formData.append("ago", item.Ago);
				formData.append("set", item.Set);
				formData.append("out", item.Out);
				formData.append("nov", item.Nov);
				formData.append("dez", item.Dez);
				formData.append("id_budget", this.budget_id);
				formData.append("user_id", this.userLogged.id);

				this.$http
					.post(`/budget/store/product`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {

							this.getBudgets();
							this.getAllBudgetDers(item)
							// this.goSuccess(response.data.message);
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			})

			this.goSuccess("Planilha importada com sucesso!!");


			return result;
		},
		loadCSV(e) {
			var vm = this;
			if (window.FileReader) {
				var reader = new FileReader();
				reader.readAsText(e.target.files[0]);
				// Handle errors load
				reader.onload = function (event) {
					var csv = event.target.result;
					vm.parse_csv = vm.csvJSON(csv);
				};
				reader.onerror = function (evt) {
					if (evt.target.error.name == "NotReadableError") {
						alert("Canno't read file !");
					}
				};
			} else {
				alert("FileReader are not supported in this browser.");
			}
		},

		async getBudgets() {
			await this.$http
				.get(`/budget/products_budget/${this.budget_id}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						if (response.data.products_budget.length > 0) {
							this.disabledImport = true;
						} else {
							this.disabledImport = false;
						}
						this.parse_csv = response.data.products_budget;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async saveItem(item) {
			this.editingIndex = -1;

			this.disabled = false;

			console.log(item)
			let formData = new FormData();

			formData.append("id_budget", item.id_budget);
			formData.append("id_product", item.id);
			formData.append("code", item.Código);
			formData.append("name", item.Descrição);
			formData.append("forecast", item.Forecast);
			formData.append("value_jan", item.Jan);
			formData.append("value_fev", item.Fev);
			formData.append("value_mar", item.Mar);
			formData.append("value_abr", item.Abr);
			formData.append("value_mai", item.Mai);
			formData.append("value_jun", item.Jun);
			formData.append("value_jul", item.Jul);
			formData.append("value_ago", item.Ago);
			formData.append("value_set", item.Set);
			formData.append("value_out", item.Out);
			formData.append("value_nov", item.Nov);
			formData.append("value_dez", item.Dez);
			formData.append("user_id", this.userLogged.id);
			formData.append("derivations", this.derivations.length);

			// this.derivations.forEach((der) => {
			// 	this.createBudgetItems(der, item)
			// })
			//this.getBudgets();

			this.$http
				.post(`/budget/update/product`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.getBudgets();
					}
				})
				.catch(function (error) {
					console.log(error);
				});

		},

		changePage(page) {
			this.currentPage = page;
		},

		sumForecast(forecast, derivation, jan, fev, mar, abr, mai, jun, jul, ago, set, out, nov, dez) {
			this.result = forecast ? Math.ceil(parseInt(forecast) / parseInt(derivation)) : 0
			this.calcule_jan = jan ? Math.ceil(parseInt(jan) / parseInt(derivation)) : 0
			this.calcule_fev = fev ? Math.ceil(parseInt(fev) / parseInt(derivation)) : 0
			this.calcule_mar = mar ? Math.ceil(parseInt(mar) / parseInt(derivation)) : 0
			this.calcule_abr = abr ? Math.ceil(parseInt(abr) / parseInt(derivation)) : 0
			this.calcule_mai = mai ? Math.ceil(parseInt(mai) / parseInt(derivation)) : 0
			this.calcule_jun = jun ? Math.ceil(parseInt(jun) / parseInt(derivation)) : 0
			this.calcule_jul = jul ? Math.ceil(parseInt(jul) / parseInt(derivation)) : 0
			this.calcule_ago = ago ? Math.ceil(parseInt(ago) / parseInt(derivation)) : 0
			this.calcule_set = set ? Math.ceil(parseInt(set) / parseInt(derivation)) : 0
			this.calcule_out = out ? Math.ceil(parseInt(out) / parseInt(derivation)) : 0
			this.calcule_nov = nov ? Math.ceil(parseInt(nov) / parseInt(derivation)) : 0
			this.calcule_dez = dez ? Math.ceil(parseInt(dez) / parseInt(derivation)) : 0
			this.per = Math.ceil((100 / derivation))
		},

		calcule(value, index){
			this.result = Math.ceil(parseInt(this.filteredList[index].Forecast) / this.derivations.length);

			this.calcule_jan = Math.ceil(parseInt(this.filteredList[index].Jan) / this.derivations.length);
			this.calcule_fev = Math.ceil(parseInt(this.filteredList[index].Fev) / this.derivations.length);
			this.calcule_mar = Math.ceil(parseInt(this.filteredList[index].Mar) / this.derivations.length);
			this.calcule_abr = Math.ceil(parseInt(this.filteredList[index].Abr) / this.derivations.length);
			this.calcule_mai = Math.ceil(parseInt(this.filteredList[index].Mai) / this.derivations.length);
			this.calcule_jun = Math.ceil(parseInt(this.filteredList[index].Jun) / this.derivations.length);
			this.calcule_jul = Math.ceil(parseInt(this.filteredList[index].Jul) / this.derivations.length);
			this.calcule_ago = Math.ceil(parseInt(this.filteredList[index].Ago) / this.derivations.length);
			this.calcule_set = Math.ceil(parseInt(this.filteredList[index].Set) / this.derivations.length);
			this.calcule_out = Math.ceil(parseInt(this.filteredList[index].Out) / this.derivations.length);
			this.calcule_nov = Math.ceil(parseInt(this.filteredList[index].Nov) / this.derivations.length);
			this.calcule_dez = Math.ceil(parseInt(this.filteredList[index].Dez) / this.derivations.length);

		},		
	},

	computed: {
		totalPages() {
			return Math.ceil(this.parse_csv.length / this.itemsPerPage);
		},
		paginatedItems() {
			const startIndex = (this.currentPage - 1) * this.itemsPerPage;
			const endIndex = startIndex + this.itemsPerPage;
			return this.parse_csv.slice(startIndex, endIndex);
		},

		filteredList() {
			const startIndex = (this.currentPage - 1) * this.itemsPerPage;
			const endIndex = startIndex + this.itemsPerPage;
			let array = this.parse_csv.slice(startIndex, endIndex);

			return array.filter((product) => {
				const nameMatch = product.Código.toLowerCase().includes(this.search.toLowerCase());
				const codeMatch = product.Descrição.toLowerCase().includes(this.search.toLowerCase());
				// Se você deseja filtrar por ambos name e code, use && (E lógico)
				// Se você deseja filtrar por name OU code, use || (OU lógico)
				return nameMatch || codeMatch;
			});
		},

		fieldStyle() {
			return this.disabledImport ? { backgroundColor: 'lightgray !important', color: 'gray' } : {};
		},

		fieldsBudget: function () {
			let fields = [
				{
					key: "code",
					label: "Código",
				},

				{
					key: "description",
					label: "Descrição",
				},

				{
					key: "forecast",
					label: "Forecast",
				},

				{
					key: "jan",
					label: "Jan",
				},

				{
					key: "fev",
					label: "Fev",
				},

				{
					key: "mar",
					label: "Mar",
				},

				{
					key: "abr",
					label: "Abr",
				},

				{
					key: "mai",
					label: "Mai",
				},

				{
					key: "jun",
					label: "Jun",
				},

				{
					key: "jul",
					label: "Jul",
				},

				{
					key: "ago",
					label: "Ago",
				},

				{
					key: "set",
					label: "Set",
				},

				{
					key: "out",
					label: "Out",
				},

				{
					key: "nov",
					label: "Nov",
				},

				{
					key: "dez",
					label: "Dez",
				},

				{
					key: "actions",
					label: "Ações",
				},
			];
			return fields;
		},

		colSpan() {
			return this.parse_csv.length + 1;
		},
		numeroDePaginas() {
			return Math.ceil(this.parse_csv.length / this.perPage);
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.parse_csv.filter(item => item.Código.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},
	filters: {
		capitalize: function (str) {
			return str.charAt(0).toUpperCase() + str.slice(1);
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
