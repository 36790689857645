<template>
    <div class="main">
        <PageComponent :title_nav="'Integrações'">
            <div slot="slot-pages" class="content-pages">
                <div class="products">
                    <div class="products-header">
                        <!-- breadcrumb -->
                        <b-breadcrumb>
                            <b-breadcrumb-item :to="{ name: 'programming-index' }"> <font-awesome-icon :icon="['fas', 'arrow-left']" /> Listagem de
                                programação</b-breadcrumb-item>
                            <b-breadcrumb-item active>Importar programação</b-breadcrumb-item>
                        </b-breadcrumb>
                        <!-- breadcrumb -->
                        <b-row>
                            <b-col md="12">
                                <h5>Importar programação</h5>
                            </b-col>

                            <b-col md="12" class="col-add">
                                <p> Dados da programação </p>

                                <b-card class="info-create">
                                    <b-row>
                                        <b-col md="6">
                                            <label for="example-weekdays">Semana</label>
                                            <br>
                                            <input type="text" class="form-control" v-mask="'####'"
                                                placeholder="Digite a semana do mês" v-model="dataObj.weekday">
                                        </b-col>

                                        <b-col md="6">
                                            <label for="example-weekdays">Ano</label>
                                            <br>

                                            <input type="text" class="form-control" v-mask="'####'"
                                                placeholder="Digite o Ano" v-model="dataObj.year">

                                        </b-col>

                                        <b-col md="6" class="mt-3">
                                            <label for="example-weekdays">Data Inicial</label>
                                            <br>
                                            <b-form-datepicker v-model="dataObj.date_initial" class="mb-2"
                                                placeholder="Selecione" locale="pt-br"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                                        </b-col>

                                        <b-col md="6" class="mt-3">
                                            <label for="example-weekdays">Data Final</label>
                                            <br>
                                            <b-form-datepicker v-model="dataObj.date_final" class="mb-2"
                                                placeholder="Selecione" locale="pt-br"
                                                :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"></b-form-datepicker>
                                        </b-col>

                                    </b-row>
                                </b-card>

                            </b-col>

                            <b-col md="8"></b-col>
                            <b-col md="2" class="btn-cancel btn router-link-active mt-3">
                                <b-button :to="{ name: 'programming-index' }">Cancelar</b-button>
                            </b-col>

                            <b-col md="2" class="btn-save mt-4">
                                <b-button @click="save()" v-if="!this.dataObj.id">Continuar</b-button>
                                <b-button @click="update()" v-else>Continuar</b-button>
                            </b-col>

                        </b-row>

                    </div>
                </div>
            </div>
        </PageComponent>
    </div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";

export default {
    name: "ProgrammingAddInfoComponent",
    components: {
        PageComponent,
    },
    data() {
        return {
            userLogged: localStorage.getItem("userLogged")
                ? JSON.parse(localStorage.getItem("userLogged"))
                : null,
            dataObj: {
                id: null,
                weekday: 0,
                year: '',
                date_initial: '',
                date_final: ''
            },
            programming: this.$route.params.programming_id,
            budgetId: this.$route.params,
            obj: this.$route.params.id,

        };
    },

    mounted() {
        this.findProgramming()
    },

    methods: {
        toggleCheckbox() {
            this.checkbox = !this.checkbox;
            this.$emit("setCheckboxVal", this.checkbox);
        },

        goSuccess(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: false,
                timer: 4000,
                icon: "success",
                title: "Sucesso!",
                text: message ? message : "Cadastro Atualizado.",
            });
        },

        goError(message) {
            this.$swal({
                toast: true,
                position: "center",
                showConfirmButton: true,
                icon: "error",
                title: "Atenção!",
                text: message ? message : "Já existe um usuário com este e-mail.",
            });
        },

        async save() {
            let formData = new FormData();
            formData.append("weekday", this.dataObj.weekday);
            formData.append("year", this.dataObj.year);
            formData.append("date_initial", this.dataObj.date_initial);
            formData.append("date_final", this.dataObj.date_final);

            this.$http
                .post(`/programming/store`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.goSuccess(response.data.message);
                        let id = response.data.id;
                        this.$router.push(`/programming/${id}/create/export`);

                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async findProgramming() {
            await this.$http
                .get(`/programming/${this.obj}`)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        let data = response.data.programming;
                        console.log(data)
                        this.dataObj.id = data.id;
                        this.dataObj.year = data.year;
                        this.dataObj.weekday = data.weekday;
                        this.dataObj.date_initial = data.date_initial;
                        this.dataObj.date_final = data.date_final;
                        // dataObj
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        },

        async update() {
            let formData = new FormData();
            formData.append("weekday", this.dataObj.weekday);
            formData.append("year", this.dataObj.year);
            formData.append("date_initial", this.dataObj.date_initial);
            formData.append("date_final", this.dataObj.date_final);
            formData.append("id", this.dataObj.id);

            this.$http
                .post(`/programming/update`, formData)
                .then((response) => {
                    if (response.data.error) {
                        this.goError(response.data.message);
                    } else {
                        this.goSuccess(response.data.message);
                        this.$router.push(`/programming/${this.dataObj.id}/create/export`);
                    }
                })
                .catch(function (error) {
                    console.log(error);
                });
        }
    },

    computed: {
        fieldsProduct: function () {
            let fields = [
                {
                    key: "week",
                    label: "Semana",
                },

                {
                    key: "date_initial",
                    label: "Data Inicial",
                },

                {
                    key: "date_final",
                    label: "Data final",
                },

                {
                    key: "actions",
                    label: "",
                },
            ];
            return fields;
        },

        paginatedCards() {
            const { currentPage, perPage } = this;
            const start = (currentPage - 1) * perPage;
            const end = currentPage * perPage;

            return this.programming.slice(start, end);
        },

        filteredList() {
            return this.programming.filter((dolar) => {
                return dolar.user_name.toLowerCase().includes(this.search.toLowerCase());
            });
        },
    },

    filters: {
        dateFormat: function (date) {
            return moment(date).format("DD.MM.YYYY");
        },
    },
};
</script>

<style lang="scss" src="./style.scss" scoped />
