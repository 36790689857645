<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Etapas lead time</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->
<!-- {{ selected }} -->
						<b-row>
							<b-col md="6">
								<h5>Etapas lead time</h5>
							</b-col>

							<div class="col-md-12">
								<b-row class="tbl-lead">
									<b-col md="5">Nome</b-col>
									<b-col md="2">Ordem</b-col>
									<b-col md="5">Observação</b-col>
								</b-row>
								<draggable
									tag="ul"
									:list="leadTimes"
									class="list-group handle"
									v-model="leadTimes"
									@change="orderRedeStatus(leadTimes)"
								>
									<li
										class="list-group-item"
										v-for="(element, idx) in leadTimes"
										:key="element.id"
										@click="selectRow(element, idx)"
										:class="{ 'menu-active': currentRouteName == element.order }"
									>
										<b-row>
											<b-col md="5" class="order">
												<font-awesome-icon :icon="['fas', 'bars']" class="mt-2" />
												<input type="text" class="form-control" v-model="element.name" />
											</b-col>

											<b-col md="2">
												<input type="text" class="form-control" :value="idx + 1" readonly/>
											</b-col>

											<b-col md="5" class="order-rmv">
												<input
													type="text"
													class="form-control"
													v-model="element.observation"
												/>
												<font-awesome-icon
													:icon="['fas', 'trash-alt']"
													class="btn-remove"
													@click="deleteLead(idx, element.id)" v-if="userLoggedPermission.permissionleadTimem == 1 || userLogged.role == 'Administrador'"
												/>
											</b-col>
										</b-row>
									</li>
									<p class="line"></p>
								</draggable>
							</div>

							<b-col md="12" class="col-table" v-if="userLoggedPermission.permissionleadTimem == 1 || userLogged.role == 'Administrador'">
								<b-button
									type="button"
									ref="submitBtn"
									class="newItem"
									@click="AddItem"
								>
									<font-awesome-icon :icon="['fas', 'plus']" />
									Adicionar Etapa
								</b-button>
							</b-col>
							<b-col md="6"></b-col>
							<b-col md="6" class="btn-salve" v-if="userLoggedPermission.permissionleadTimem == 1 || userLogged.role == 'Administrador'">
								<b-button
									class="save button-padrao-add"
									@click="saveLeadTime"
									v-if="!this.selected.id"
									>salvar</b-button
								>
								<b-button class="save button-padrao-add" @click="updateStock" v-else
									>Atualizar</b-button
								>
								<br />
								<b-button class="button-padrao-cancel" :to="{ name: 'dashboard' }"
									>cancelar</b-button
								>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";
import draggable from "vuedraggable";
export default {
	name: "LeadTimeComponent",
	components: {
		PageComponent,
		draggable,
	},
	data() {
		return {
			dragging: false,
			dataObj: {
				name: "",
				order: "",
				observation: "",
			},
			isDisabled: true,
			selectMode: "single",
			selected: "",
			leadTimes: [],
			currentRouteName: "white",
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
		};
	},

	mounted() {
		this.getLeadTimes();
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		showModal() {
			this.$refs["my-modal"].show();
		},

		hideModal() {
			this.$refs["my-modal"].hide();
			this.products = [];
			this.selectedProduct = "";
		},

		AddItem() {
			this.leadTimes.push({
				name: "",
				order: this.leadTimes.length+1,
				observation: "",
			});
		},
		removeItem(item) {
			this.leadTimes.splice(item, 1);
		},

		async deleteLead(index, id) {
			if (id == undefined) {
				this.leadTimes.splice(index, 1);
			} else {
			let formData = new FormData();
			formData.append("id", id);
			this.$swal
				.fire({
					text:
						"Ao excluir uma etapa do lead time, ela é excluída automaticamente de todos os produtos que esteja cadastrada.",
					title: "Deseja continuar?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "SIM, EXCLUIR",
					cancelButtonText: `NÃO EXCLUIR`,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#d33",
					customClass: {
						cancelButton:
							"btn btn-light border border-secondary rounded-pill px-md-5",
						confirmButton: "btn btn-danger rounded-pill px-md-5",
					},
					buttonsStyling: false,
				})
				.then((result) => {
					/* Read more about isConfirmed, isDenied below */
					if (result.isConfirmed) {
						this.$http
							.post(`/leadTime/delete`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.goSuccess(response.data.message);
									this.leadTimes.splice(index, 1);
									location.reload();
								}
							})
							.catch((error) => {
								this.goError(error.response.data.message);
								console.log(error.response.data.message);
							});
					} else if (result.isDenied) {
						this.$swal.fire("Changes are not saved", "", "info");
					}
				});
			}

		},

		onRowSelected(items) {
			this.selected = items[0];
		},

		async getLeadTimes() {
			await this.$http
				.get(`/leadTime`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.leadTimes = response.data.leadTimes;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async saveLeadTime() {
			if (
				this.selected == ""
			) {
				// this.$router.push({
				// 	path: `dashboard`,
				// });
				location.reload();
			} else {
				let formData = new FormData();
				formData.append("name", this.selected.name);
				formData.append("order", this.selected.order);
				formData.append("observation", this.selected.observation);
				await this.$http
					.post(`/leadTime/store`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							// this.$router.push({
							// 	path: `dashboard`,
							// });
							location.reload();
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async updateStock() {
			if (
				this.selected == "" 
			) {
				this.$router.push({
					path: `dashboard`,
				});
			} else {
				let formData = new FormData();
				formData.append("id", this.selected.id);
				formData.append("name", this.selected.name);
				formData.append("order", this.selected.order);
				formData.append("observation", this.selected.observation ? this.selected.observation : '');
				await this.$http
					.post(`/leadTime/update`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							this.goSuccess(response.data.message);
							// this.$router.push({
							// 	path: `dashboard`,
							// });
							location.reload();
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		selectRow(element) {
			if (element) this.currentRouteName = element.order;

			this.selected = element;
		},

		async orderRedeStatus(item) {
			if(this.userLoggedPermission.permissionleadTimem == 1 || this.userLogged.role == 'Administrador') {
				let oldIndex;
			let newIndex;

			item.splice(newIndex, 0, item.splice(oldIndex, 1)[0]);
			//atualizar a propriedade da ordem com base na posição na matriz
			item.forEach(function (item, index) {
				item.order = index;
			});

			var items_order = item.map(function (item) {
				return item.order;
			});

			var items_ids = item.map(function (item) {
				return item.id;
			});

			const formData = new FormData();
			formData.append("order", items_order);
			formData.append("ids", items_ids);

			await this.$http
				.post(`/leadTime/update/order`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						location.reload();
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
			}

		},
	},

	computed: {
		filteredList() {
			return this.newStock.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
		fieldsProduct: function () {
			let fields = [
				{ key: "code", label: "Código" },

				{
					key: "name",
					label: "Nome",
				},

				{
					key: "qtd",
					label: "Qtde. de produtos agrupados",
				},

				{
					key: "actions",
					label: "Ações",
				},
			];
			return fields;
		},

		fields: function () {
			let fields = [
				{
					key: "name",
					label: "Nome",
				},
				{ key: "sort", label: "Ordem" },
				{ key: "observation", label: "Observação" },
				{ key: "action", label: "" },
			];
			return fields;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
