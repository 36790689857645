/** @format */

import Vue from "vue";
import App from "./App.vue";
import VueRouter from "vue-router";
import { BootstrapVue, IconsPlugin, BadgePlugin } from "bootstrap-vue";
import { FontAwesomeIcon } from "@fortawesome/vue-fontawesome";
import { library } from "@fortawesome/fontawesome-svg-core";
import VueTheMask from "vue-the-mask";
import VueSweetalert2 from "vue-sweetalert2";
import Vuelidate from "vuelidate";

//Icons
import {
  faArrowDown,
  faArrowUp,
  faBars,
  faEyeSlash,
} from "@fortawesome/free-solid-svg-icons";
import { faChartBar } from "@fortawesome/free-solid-svg-icons";
import { faCloud } from "@fortawesome/free-solid-svg-icons";
import { faHeart } from "@fortawesome/free-solid-svg-icons";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { faPlus } from "@fortawesome/free-solid-svg-icons";
import { faLink } from "@fortawesome/free-solid-svg-icons";
import { faBook } from "@fortawesome/free-solid-svg-icons";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { faChalkboardTeacher } from "@fortawesome/free-solid-svg-icons";
import { faSearch } from "@fortawesome/free-solid-svg-icons";
import { faExternalLinkAlt } from "@fortawesome/free-solid-svg-icons";
import { faSmile } from "@fortawesome/free-solid-svg-icons";
import { faMicrophoneAlt } from "@fortawesome/free-solid-svg-icons";
import { faPaperPlane } from "@fortawesome/free-solid-svg-icons";
import { faChevronCircleRight } from "@fortawesome/free-solid-svg-icons";
import { faBirthdayCake } from "@fortawesome/free-solid-svg-icons";
import { faCommentAlt } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleUp } from "@fortawesome/free-solid-svg-icons";
import { faArrowAltCircleDown } from "@fortawesome/free-solid-svg-icons";
import { faPlayCircle } from "@fortawesome/free-solid-svg-icons";
import { faPauseCircle } from "@fortawesome/free-solid-svg-icons";
import { faPlusCircle } from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowCircleUp } from "@fortawesome/free-solid-svg-icons";
import { faMinusCircle } from "@fortawesome/free-solid-svg-icons";
import { faClock } from "@fortawesome/free-solid-svg-icons";
import { faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { faBell } from "@fortawesome/free-solid-svg-icons";
import { faQuestionCircle } from "@fortawesome/free-solid-svg-icons";
import { faSortUp } from "@fortawesome/free-solid-svg-icons";
import { faCalendarAlt } from "@fortawesome/free-solid-svg-icons";
import { faEdit } from "@fortawesome/free-solid-svg-icons";
import { faVolumeUp } from "@fortawesome/free-solid-svg-icons";
import { faUser } from "@fortawesome/free-solid-svg-icons";
import { faBuilding } from "@fortawesome/free-solid-svg-icons";
import { faRocket } from "@fortawesome/free-solid-svg-icons";
import { faStar } from "@fortawesome/free-solid-svg-icons";
import { faGem } from "@fortawesome/free-solid-svg-icons";
import { faFileAlt } from "@fortawesome/free-solid-svg-icons";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { faTrashAlt } from "@fortawesome/free-solid-svg-icons";
import { faCogs } from "@fortawesome/free-solid-svg-icons";
import { faStopCircle } from "@fortawesome/free-solid-svg-icons";
import { faSignOutAlt } from "@fortawesome/free-solid-svg-icons";
import { faCopy } from "@fortawesome/free-solid-svg-icons";
import { faChevronLeft } from "@fortawesome/free-solid-svg-icons";

import { faTimesCircle } from "@fortawesome/free-solid-svg-icons";
import { faPause } from "@fortawesome/free-solid-svg-icons";
import { faVideo } from "@fortawesome/free-solid-svg-icons";
import { faShare } from "@fortawesome/free-solid-svg-icons";
import { faListUl } from "@fortawesome/free-solid-svg-icons";
import { faFilePdf } from "@fortawesome/free-solid-svg-icons";
import { faExclamationCircle } from "@fortawesome/free-solid-svg-icons";

import { faExclamationTriangle } from "@fortawesome/free-solid-svg-icons";

import { faFacebook } from "@fortawesome/free-brands-svg-icons";
import { faInstagram } from "@fortawesome/free-brands-svg-icons";
import { faTwitter } from "@fortawesome/free-brands-svg-icons";
import { faYoutube } from "@fortawesome/free-brands-svg-icons";
import { faLinkedin } from "@fortawesome/free-brands-svg-icons";

import { faMap } from "@fortawesome/free-solid-svg-icons";
import { faPaperclip } from "@fortawesome/free-solid-svg-icons";
import { faFileUpload } from "@fortawesome/free-solid-svg-icons";
import { faFolder } from "@fortawesome/free-solid-svg-icons";
import { faFolderOpen } from "@fortawesome/free-solid-svg-icons";

import { faEllipsisV } from "@fortawesome/free-solid-svg-icons";
import { faFileArchive } from "@fortawesome/free-solid-svg-icons";
import { faCloudDownloadAlt } from "@fortawesome/free-solid-svg-icons";
import { faFileInvoice } from "@fortawesome/free-solid-svg-icons";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { faLockOpen } from "@fortawesome/free-solid-svg-icons";
import { faUsers } from "@fortawesome/free-solid-svg-icons";
import { faEye } from "@fortawesome/free-solid-svg-icons";
import { faFileAudio } from "@fortawesome/free-solid-svg-icons";
import { faHome } from "@fortawesome/free-solid-svg-icons";
import { faDonate } from "@fortawesome/free-solid-svg-icons";
import { faWallet } from "@fortawesome/free-solid-svg-icons";
import { faThLarge } from "@fortawesome/free-solid-svg-icons";
import { faFile } from "@fortawesome/free-solid-svg-icons";
import { faBriefcase } from "@fortawesome/free-solid-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faPen } from "@fortawesome/free-solid-svg-icons";
import { faShoppingCart } from "@fortawesome/free-solid-svg-icons";
import { faChevronUp } from "@fortawesome/free-solid-svg-icons";
import { faSortAmountDown } from "@fortawesome/free-solid-svg-icons";
import { faChevronDown } from "@fortawesome/free-solid-svg-icons";
import { faArrowLeft } from "@fortawesome/free-solid-svg-icons";
import { faBorderAll } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleRight } from "@fortawesome/free-solid-svg-icons";
import { faAngleDoubleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleLeft } from "@fortawesome/free-solid-svg-icons";
import { faAngleRight } from "@fortawesome/free-solid-svg-icons";
import { faHourglass } from "@fortawesome/free-solid-svg-icons";
import { faHistory } from "@fortawesome/free-solid-svg-icons";
import { faSort } from "@fortawesome/free-solid-svg-icons";
import { faFilter } from "@fortawesome/free-solid-svg-icons";
import { faEllipsisH } from "@fortawesome/free-solid-svg-icons";
import { faSyncAlt } from "@fortawesome/free-solid-svg-icons";
import { faPlay } from "@fortawesome/free-solid-svg-icons";
import { faClone } from "@fortawesome/free-solid-svg-icons";
import { faSlidersH } from "@fortawesome/free-solid-svg-icons";
import { faFileDownload } from "@fortawesome/free-solid-svg-icons";
import { faCaretRight } from "@fortawesome/free-solid-svg-icons";
import { faCaretDown } from "@fortawesome/free-solid-svg-icons";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";

// Library Icons
library.add(faBars);
library.add(faChartBar);
library.add(faCloud);
library.add(faHeart);
library.add(faTimes);
library.add(faPlus);
library.add(faLink);
library.add(faBook);
library.add(faLayerGroup);
library.add(faChalkboardTeacher);
library.add(faSearch);
library.add(faExternalLinkAlt);
library.add(faSmile);
library.add(faMicrophoneAlt);
library.add(faPaperPlane);
library.add(faChevronCircleRight);
library.add(faBirthdayCake);
library.add(faCommentAlt);
library.add(faArrowAltCircleUp);
library.add(faArrowAltCircleDown);
library.add(faPlayCircle);
library.add(faPauseCircle);
library.add(faPlusCircle);
library.add(faArrowCircleDown);
library.add(faArrowCircleUp);
library.add(faMinusCircle);
library.add(faClock);
library.add(faEnvelope);
library.add(faBell);
library.add(faQuestionCircle);
library.add(faSortUp);
library.add(faCalendarAlt);
library.add(faEdit);
library.add(faVolumeUp);
library.add(faUser);
library.add(faBuilding);
library.add(faRocket);
library.add(faStar);
library.add(faGem);
library.add(faFileAlt);
library.add(faCheckCircle);
library.add(faTrashAlt);
library.add(faCogs);
library.add(faStopCircle);
library.add(faSignOutAlt);
library.add(faCopy);
library.add(faTimesCircle);
library.add(faPause);
library.add(faVideo);
library.add(faShare);
library.add(faListUl);
library.add(faFilePdf);
library.add(faChevronLeft);

library.add(faFacebook);
library.add(faInstagram);
library.add(faTwitter);
library.add(faYoutube);
library.add(faLinkedin);

library.add(faExclamationCircle);
library.add(faExclamationTriangle);
library.add(faMap);
library.add(faPaperclip);
library.add(faFileUpload);
library.add(faFolder);
library.add(faFolderOpen);
library.add(faEllipsisV);
library.add(faFileArchive);
library.add(faCloudDownloadAlt);
library.add(faFileInvoice);
library.add(faCheck);
library.add(faLockOpen);
library.add(faUsers);
library.add(faEye);
library.add(faEyeSlash);
library.add(faFileAudio);
library.add(faHome);
library.add(faDonate);
library.add(faWallet);
library.add(faThLarge);
library.add(faFile);
library.add(faPhone);
library.add(faBriefcase);
library.add(faPen);
library.add(faShoppingCart);
library.add(faChevronUp);
library.add(faSortAmountDown);
library.add(faArrowUp);
library.add(faArrowDown);
library.add(faChevronDown);
library.add(faArrowLeft);
library.add(faBorderAll);
library.add(faAngleDoubleLeft);
library.add(faAngleDoubleRight);
library.add(faAngleRight);
library.add(faAngleLeft);
library.add(faHourglass);
library.add(faHistory);
library.add(faSort);
library.add(faFilter);
library.add(faEllipsisH);
library.add(faSyncAlt);
library.add(faPlay);
library.add(faClone);
library.add(faSlidersH);
library.add(faFileDownload);
library.add(faCaretRight);
library.add(faCaretDown);
library.add(faSpinner);

import "./api/axios";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "sweetalert2/dist/sweetalert2.min.css";
import "vue-select/dist/vue-select.css";

//PAGES
import LoginComponent from "./pages/login/LoginComponent";
import ForgotPasswordComponent from "./pages/forgot-password/ForgotPasswordComponent";
import RecoveryPasswordComponent from "./pages/recovery-password/RecoveryPasswordComponent";
import DashboardComponent from "./pages/dashboard/DashboardComponent";
import UserComponent from "./pages/user/index/UserComponent";
import UserRegisterComponent from "./pages/user/create/UserRegisterComponent";
import StockCollator from "./pages/stock_collator/index/StockCollatorComponent";
import StockCollatorCreateComponent from "./pages/stock_collator/create/StockCollatorCreateComponent";

import StockTotalizerCreateComponent from "./pages/stock_totalizer/create/StockTotalizerCreateComponent";
import StockTotalizerComponent from "./pages/stock_totalizer/index/StockTotalizerComponent";

import ProdutGrouperIndexComponent from "./pages/product_grouper/index/ProdutGrouperIndexComponent";
import ProdutGrouperCreateComponent from "./pages/product_grouper/create/ProdutGrouperCreateComponent";

import LeadTimeComponent from "./pages/lead_time/LeadTimeComponent";

import ProductIndexComponent from "./pages/products/index/ProductIndexComponent";
import EstSegComponent from "./pages/products/index/EstSegComponent";
import ConfigLeadProductComponent from "./pages/products/index/ConfigLeadProductComponent";

import SupplierIndexComponent from "./pages/supplier/index/SupplierIndexComponent";
import SupplierViewComponent from "./pages/supplier/index/SupplierViewComponent";

import ParametersComponent from "./pages/parameters/ParametersComponent";

import FactorIndexComponent from "./pages/factor/index/FactorIndexComponent";
import FactorCreateComponent from "./pages/factor/create/FactorCreateComponent";

import DolarIndexComponent from "./pages/dolar/index/DolarIndexComponent";
import DolarCreateComponent from "./pages/dolar/create/DolarCreateComponent";

//programação
import ProgrammingIndexComponent from "./pages/programming/index/ProgrammingIndexComponent";
import ProgrammingAddInfoComponent from "./pages/programming/create/ProgrammingAddInfoComponent";
import ProgrammingCreateComponent from "./pages/programming/create/ProgrammingCreateComponent";

import BatchProcessesIndexComponent from "./pages/batch_processes/index/BatchProcessesIndexComponent";
import ImportProcessComponent from "./pages/batch_processes/index/ImportProcessComponent";
import ImportStockComponent from "./pages/batch_processes/index/ImportStockComponent";
import ImportSellComponent from "./pages/batch_processes/index/ImportSellComponent";
import ImportPurchaseComponent from "./pages/batch_processes/index/ImportPurchaseComponent";
import CostIndexComponent from "./pages/cost/index/CostIndexComponent";

//Cenários e simulações
import ScenariosIndexComponent from "./pages/scenarios/ScenariosIndexComponent";
import ScenariosCreateComponent from "./pages/scenarios/ScenariosCreateComponent";
//parametros de simulação
import ParamsSimulationComponent from "./pages/scenarios/ParamsSimulationComponent";

//budget
import BudgetComponent from "./pages/budget/BudgetComponent";
import BudgetCreateComponent from "./pages/budget/create_budget/BudgetCreateComponent";
import ExportBudgetComponent from "./pages/budget/create_budget/ExportBudgetComponent";

//Forecast
import CreateForecastComponent from "./pages/forecast/CreateForecastComponent";

//S&op
import SeopComponent from "./pages/s&op/S&opComponent";


import SimulationSeopComponent from "./pages/simulation_seop/SimulationSeopComponent";

//STOCK
import StockComponent from "./pages/stock/index/StockComponent";
import StockCreateComponent from "./pages/stock/create/StockCreateComponent";

import ParametersSystemComponent from "./pages/parameters_system/ParametersSystemComponent";

Vue.config.productionTip = false;
Vue.component("font-awesome-icon", FontAwesomeIcon);
Vue.use(VueRouter);
Vue.use(BootstrapVue);
Vue.use(IconsPlugin);
Vue.use(VueTheMask);
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(BadgePlugin);

const routes = [
  { path: "/", name: "login", component: LoginComponent },
  {
    path: "/forgot-password",
    name: "forgot-password",
    component: ForgotPasswordComponent,
  },
  {
    path: "/recovery-password",
    name: "recovery-password",
    component: RecoveryPasswordComponent,
  },
  { path: "/dashboard", name: "dashboard", component: DashboardComponent },
  { path: "/users", name: "users", component: UserComponent },
  {
    path: "/users/register",
    name: "register",
    component: UserRegisterComponent,
  },

  {
    path: "/stock-collator",
    name: "stock-collator-index",
    component: StockCollator,
  },
  {
    path: "/stock-collator/register",
    name: "stock-collator-register",
    component: StockCollatorCreateComponent,
  },

  {
    path: "/stock-totalizer",
    name: "stock-totalizer-index",
    component: StockTotalizerComponent,
  },
  {
    path: "/stock-totalizer/register",
    name: "stock-totalizer-register",
    component: StockTotalizerCreateComponent,
  },
  // product-grouper
  {
    path: "/product-grouper",
    name: "product-grouper-index",
    component: ProdutGrouperIndexComponent,
  },
  {
    path: "/product-grouper/register",
    name: "product-grouper-register",
    component: ProdutGrouperCreateComponent,
  },

  { path: "/lead-time", name: "lead-time-index", component: LeadTimeComponent },

  {
    path: "/products",
    name: "products-index",
    component: ProductIndexComponent,
  },
  {
    path: "/products/:id/est-seg",
    name: "products-est",
    component: EstSegComponent,
  },
  {
    path: "/products/:id/config/lead",
    name: "products-config",
    component: ConfigLeadProductComponent,
  },

  //TEMP
  // { path: "/cost", name: "cost", component: DashboardComponent },

  {
    path: "/suppliers",
    name: "supplier-index",
    component: SupplierIndexComponent,
  },
  { path: "/supplier/:id", name: "supplier", component: SupplierViewComponent },

  {
    path: "/s&op/parameters",
    name: "s&op-parameters",
    component: ParametersComponent,
  },

  { path: "/", name: "", component: ParametersSystemComponent },

  {
    path: "/parameters",
    name: "parameters",
    component: ParametersSystemComponent,
  },

  { path: "/factors", name: "factor-index", component: FactorIndexComponent },
  {
    path: "/factors/create",
    name: "factor-create",
    component: FactorCreateComponent,
  },

  { path: "/dolar", name: "dolar-index", component: DolarIndexComponent },
  {
    path: "/dolar/create",
    name: "dolar-create",
    component: DolarCreateComponent,
  },

  {
    path: "/processes",
    name: "batch_processes-index",
    component: BatchProcessesIndexComponent,
  },
  {
    path: "/processes/import/:id/process",
    name: "import-process",
    component: ImportProcessComponent,
  },
  {
    path: "/processes/import/stock",
    name: "import-stock",
    component: ImportStockComponent,
  },
  {
    path: "/processes/import/:id/sells",
    name: "import-sells",
    component: ImportSellComponent,
  },
  {
    path: "/processes/import/:id/stocks",
    name: "import-stocks",
    component: ImportStockComponent,
  },

  {
    path: "/processes/import/:id/purchases",
    name: "import-purchases",
    component: ImportPurchaseComponent,
  },

  { path: "/cost", name: "cost-index", component: CostIndexComponent },

  //Cenários e simulações
  {
    path: "/scenarios/simulations",
    name: "scenarios-simulations-index",
    component: ScenariosIndexComponent,
  },

  {
    path: "/scenarios/simulations/create/:id",
    name: "scenarios-simulations-create",
    component: ScenariosCreateComponent,
  },

  {
    path: "/scenarios/simulations/edit/:id",
    name: "scenarios-simulations-edit",
    component: ScenariosCreateComponent,
  },

  {
    path: "/scenarios/simulations/create",
    name: "scenarios-simulations-create",
    component: ScenariosCreateComponent,
  },

  {
    path: "/scenarios/simulations/edit",
    name: "scenarios-simulations-edit",
    component: ScenariosCreateComponent,
  },

  {
    path: "/scenarios/:id/create/simulations",
    name: "scenarios-simulations-params",
    component: ParamsSimulationComponent,
  },

  {
    path: "/scenarios/:id/create/simulation/:id_simulation",
    name: "scenarios-simulations-params-edit",
    component: ParamsSimulationComponent,
  },

  //budgte
  { path: "/budget", name: "budget", component: BudgetComponent },
  {
    path: "/budget/:id",
    name: "budget-create",
    component: BudgetCreateComponent,
  },
  {
    path: "/budget/:id/:operation",
    name: "budget-create",
    component: BudgetCreateComponent,
  },
  {
    path: "/budget/:id/create/export",
    name: "budget-create-export",
    component: ExportBudgetComponent,
  },

  // forecast
  {
    path: "/forecast/:id/create",
    name: "forecast-create",
    component: CreateForecastComponent,
  },

  //s&op
  { path: "/s&op", name: "s&op", component: SeopComponent },


  //seop simulation
  { path: "/scenarios/:id/simulations/:simulationId/s&op", name: "scenarios-simulations-seop", component: SimulationSeopComponent },

  //PROGRAMAÇÃO
  {
    path: "/programming",
    name: "programming-index",
    component: ProgrammingIndexComponent,
  },
  {
    path: "/programming/create",
    name: "programming-create",
    component: ProgrammingAddInfoComponent,
  },
  {
    path: "/programming/:id/create/export",
    name: "programming-create-export",
    component: ProgrammingCreateComponent,
  },
  {
    path: "/programming/:id/edit",
    name: "programming-edit",
    component: ProgrammingAddInfoComponent,
  },

  //stock
  { path: "/stock", name: "stock-index", component: StockComponent },
  {
    path: "/stock/create",
    name: "stock-create",
    component: StockCreateComponent,
  },

  {
    path: "/stock/:id/edit",
    name: "stock-edit",
    component: StockCreateComponent,
  },
];

const router = new VueRouter({
  routes,
});

export const eventBus = new Vue();

/* eslint-disable no-new */
new Vue({
  router,
  render: (h) => h(App),
}).$mount("#app");
