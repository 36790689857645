<template>
	<div class="main">
		<PageComponent :title_nav="'Integrações'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Lista de Fornecedores</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row class="mt-4">
							<b-col md="3">
								<h5>Fornecedores</h5>
							</b-col>

							<b-col md="2">
								<b-button class="tab-list" :class="[inactive, 'my-btn']" @click="changeList" :disabled="isDisabledCard">
									<font-awesome-icon :icon="['fas', 'border-all']" />
								</b-button>

								<b-button class="tab-list" :class="[active, 'my-btn']" @click="changeList" :disabled="isDisabledList">
									<font-awesome-icon :icon="['fas', 'bars']" />
								</b-button>
							</b-col>

							<b-col md="3" class="col-search">
								<div class="form">
									<b-form-input placeholder="Procurar" v-model="search"></b-form-input>
									<button type="submit">Search</button>
								</div>
							</b-col>

							<b-col class="icon-sort" md="1">
								<b-button v-b-toggle.filters>
									<font-awesome-icon :icon="['fas', 'filter']" />
								</b-button>
							</b-col>

							<b-col md="3">
								<b-form-select @change="filterSupplier" class="select-sort" v-model="selectedSort"
									:options="options"></b-form-select>
							</b-col>

							<b-col md="12" class="col-add" v-if="userLoggedPermission.permissionsyncsupplierm == 1 || userLogged.role == 'Administrador'">
								<!-- <b-button class="button-padrao-add" @click="sincronizedSuppliers()"> -->
								<b-button class="button-padrao-add" @click="showModal()">
									Importar Fornecedores
								</b-button>
							</b-col>


							<TabCardComponent :hidden="hiddenCard" :users="filteredList" />

							<b-col md="12" class="col-table" :hidden="hiddenList">
								<b-card>
									<b-table :items="itemsFiltradosPaginados" :fields="fieldsProduct" responsive="" class="table-participants"
										:per-page="perPage" :current-page="currentPage">
										<template #cell(code)="data">
											<p>
												{{ data.item.code }}
											</p>
										</template>

										<template #cell(document)="data">
											<p>
												{{ data.item.document }}
											</p>
										</template>

										<template #cell(name)="data">
											<p>
												{{ data.item.name }}
											</p>
										</template>

										<template #cell(line_business)="data">
											<p>
												{{ data.item.line_business }}
											</p>
										</template>

										<template #cell(view)="data">
											<b-button @click="viewSupplier(data.item)">
												<font-awesome-icon :icon="['fas', 'eye']" />
											</b-button>
										</template>
									</b-table>
								</b-card>
							</b-col>
						</b-row>

						<b-rown class="paginations-options1 mt-4" :hidden="hiddenList">
							<!-- <b-col md="6">
								<span class="mt-3">Resultados {{ currentPage }} de {{ Math.ceil(perPage) }}</span>
							</b-col> -->
							<b-col md="12 mt-4">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
							</b-col>
						</b-rown>

						<b-modal ref="my-modal" hide-footer title="Filtrar importação" hide-header-close>
							<b-row>
								<!-- <b-col md="12">
									<b-form-group label="Filtrar" v-slot="{ ariaDescribedby }">
										<b-form-radio-group
											id="radio-group-2"
											v-model="integration"
											:aria-describedby="ariaDescribedby"
											plain
											@change="integrationDate"
										>
											<b-form-radio value="T">Todos</b-form-radio>
											<b-form-radio value="D"
												>Somente com data igual ou superior</b-form-radio
											>
										</b-form-radio-group>
									</b-form-group>
								</b-col> -->
								<!-- 
								<b-col md="6" class="mt-3">
									<label for="example-datepicker">Período</label>
									<b-form-datepicker
										placeholder=""
										id="example-datepicker"
										v-model="date"
										class="mb-2"
										locale="pt"
										:date-format-options="{
											year: 'numeric',
											month: 'numeric',
											day: 'numeric',
										}"
									></b-form-datepicker>
								</b-col> -->

								<b-col md="6" class="mt-3">
									<b-form-group id="input-group-1" label="Código da Empresa:" label-for="input-1">
										<b-form-input v-model="codeCompany" placeholder="Código da Empresa"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col md="6" class="mt-3">
									<b-form-group id="input-group-1" label="Código da Filial:" label-for="input-1">
										<b-form-input v-model="codeFil" placeholder="Código da Filial"></b-form-input>
									</b-form-group>
								</b-col>

								<b-col md="12"> </b-col>

								<b-col md="6">
									<b-button class="mt-3" variant="outline-danger" block @click="hideModal">Cancelar</b-button>
								</b-col>

								<b-col md="6">
									<b-button class="mt-3" variant="outline-success" @click="saveImport()"
										style="float: right">Importar</b-button>
								</b-col>
							</b-row>
						</b-modal>
					</div>
				</div>
			</div>
		</PageComponent>
		<b-sidebar right id="filters" title="" backdrop ref="filters" aria-label="Sidebar with custom footer" no-header
			shadow>
			<template #default="{ hide }">
				<div class="p-3">
					<b-button class="btn-close-modal" block @click="hide">
						<font-awesome-icon :icon="['fas', 'times']" />
					</b-button>

					<div class="img-info">
						<div class="info-profile">
							<h3 class="name-user">Filtrar lista de fornecedores</h3>

							<p>
								<input type="text" class="form-control" v-model="cod_supplier" placeholder="Código de fornecedor" />
							</p>

							<p>
								<input type="text" class="form-control" placeholder="CNPJ" />
							</p>

							<p>
								<b-form-select class="select-sort" v-model="activities_supplier" :options="activities"></b-form-select>
							</p>

							<b-form-group label="" v-slot="{ ariaDescribedby }">
								<b-form-checkbox-group v-model="locality" :options="options_locality" :aria-describedby="ariaDescribedby"
									name="flavour-1a" class="checkbox_locality" plain></b-form-checkbox-group>
							</b-form-group>

							<p>
								<b-form-select class="select-sort" v-model="origem_supplier" :options="origem"></b-form-select>
							</p>

							<p>
								<input type="text" class="form-control" placeholder="Digite aqui o ponto de origem"
									v-model="point_origin" />
							</p>

							<b-button class="filter" @click="filterSupplier"> Filtrar </b-button>
							<b-button class="clear" @click="clearFilter"> Limpar </b-button>
						</div>
					</div>
					<b-modal ref="import-sell-progress" hide-footer title="Download" hide-header-close id="ModalProgress">
						<!-- <div v-if="progresso < 100"> -->
						<div>
							<p> <span class="loader"></span> Processando...</p>
							<div class="progress-bar" style="background-color:rgb(156, 157, 160)">
								<div :style="{ width: progresso + '%' }" style="background-color:rgb(75, 114, 221)"> <span
										class="progress-label" style="margin: 0 !important"> {{ progresso
										}}% </span> </div>
							</div>
						</div>
					</b-modal>

				</div>
			</template>
		</b-sidebar>
	</div>
</template>
<style>
.loader {
	width: 20px !important;
	height: 20px !important;
	border: 5px solid rgb(105, 68, 240) !important;
	border-bottom-color: rgb(243, 243, 243) !important;
	border-radius: 50%;
	display: inline-block;
	box-sizing: border-box;
	animation: rotation 1s linear infinite;
}

/* Estilo para a barra de progresso */
.progress-bar {
	width: 100%;
	height: 20px;
	background-color: #f0f0f0;
	border: 1px solid #ccc;
	border-radius: 5px;
	margin-top: 10px;
}

.progress-label {
	align-items: center;
	justify-content: center;
	text-align: left !important;
	margin: 0 !important;
}

/* Estilo para a parte preenchida da barra de progresso */
.progress-bar div {
	height: 100%;
	background-color: #007bff;
	border-radius: 5px;
	transition: width 0.3s ease-in-out;
	color: white;
}

@keyframes rotation {
	0% {
		transform: rotate(0deg);
	}

	100% {
		transform: rotate(360deg);
	}
}
</style>
<script>
import PageComponent from "../../page/PageComponent.vue";
import TabCardComponent from "./card/SupplieIndexCardComponent.vue";

export default {
	name: "SupplierIndexComponent",
	components: {
		PageComponent,
		TabCardComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			dragging: false,
			dataObj: {
				name: "",
				order: "",
				observation: "",
			},
			isDisabled: true,
			selectMode: "single",
			selected: {},
			suppliers: [],

			perPage: 10,
			currentPage: 1,
			search: "",
			teste: [],
			active: "tab-active",
			inactive: "tab-inactive",
			isDisabledList: true,
			isDisabledCard: false,
			hiddenCard: true,
			hiddenList: false,
			codeCompany: 10,
			typeIntegration: false,
			integration: "",
			date: "",
			options: [
				{ value: null, text: "Ordenar por" },
				{ value: "desc", text: "Mais recentes" },
				{ value: "asc", text: "Mais antigos" },
			],
			selectedSort: null,

			activities_supplier: null,
			activities: [
				{ text: "Ramo de atividade", value: null },
				{ text: "Comércio", value: "Comércio" },
				{ text: "Indústria", value: "Indústria" },
			],

			origem_supplier: null,
			origem: [{ text: "Porto de origem", value: null }],
			locality: [],
			options_locality: [
				{ text: "Nacional", value: "Nacional" },
				{ text: "Internacional", value: "Internacional" },
			],
			cod_supplier: '',
			cnpj_supplier: '',
			point_origin: '',
			sincronizedSupplier: [],
			codeFil: 1,
			progresso: 0,
			itensProcessados: [],
			processando: false,
			importId: '',
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
			paginaAtual: 1,
			itensPorPagina: 10,
		};
	},

	mounted() {
		this.getSuppliers();
	},

	methods: {
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		showModal() {
			this.$refs["my-modal"].show();
		},

		hideModal() {
			this.$refs["my-modal"].hide();
			this.products = [];
			this.selectedProduct = "";
		},

		sendEst(item) {
			this.$router.push(`/products/${item.id}/est-seg`);
		},

		sendLeadProduct(item) {
			this.$router.push(`/products/${item.id}/config/lead`);
		},

		integrationDate() {
			if (this.integration == "D") {
				this.typeIntegration = true;
			}

			if (this.integration == "T") {
				this.typeIntegration = false;
			}
		},

		async getSuppliers() {
			await this.$http
				.get(`/suppliers`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.suppliers = response.data.suppliers;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		viewSupplier(item) {
			this.$router.push(`/supplier/${item.id}`);
		},

		changeList() {
			if (this.active === "tab-active") {
				this.active = "tab-inactive";
				this.inactive = "tab-active";

				this.isDisabledList = false;
				this.isDisabledCard = true;

				this.hiddenCard = false;
				this.hiddenList = true;
			} else {
				this.active = "tab-active";
				this.inactive = "tab-inactive";

				this.isDisabledCard = false;
				this.isDisabledList = true;

				this.hiddenCard = true;
				this.hiddenList = false;
			}
		},
		showModalSellImpo() {
			this.$refs["import-sell-progress"].show();
		},

		async saveImport() {
			this.sincronizedSuppliers();
		},

		async sincronizedSuppliers() {
			const formData = new FormData();
			formData.append("codEmp", this.codeCompany);
			formData.append("codFil", this.codeFil);
			formData.append("tipoIntegracao", this.integration);
			formData.append("datBas", this.date);
			this.$refs["my-modal"].hide();
			this.showModalSellImpo();

			if (this.codeCompany == "") {
				this.goError("Insira o código da empresa.");
			} else {
				if (this.codeFil == "") {
					this.goError("Insira o código da filial.");
				}

				this.$http
					.post(`/suppliers/sincronized/fornecedores`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response);
							this.$swal.hideLoading();
						} else {
							this.sincronizedSupplier = response.data.Body.Exportar_7Response.result;
							if (this.sincronizedSupplier.tipoRetorno == "0") {
								console.log(response.data.Body.Exportar_7Response.result)

								this.progresso = 0;
								this.itensProcessados = [];
								this.processando = true;

								const totalItens = this.sincronizedSupplier.fornecedor.length;

								const processarItem = (index) => {
									if (index >= totalItens) {
										this.progresso = 100;
										this.processando = false;
										return;
									}

									setTimeout(() => {
										this.itensProcessados.push(this.sincronizedSupplier.fornecedor[index]);
										this.progresso = Math.round(((index + 1) / totalItens) * 100);
										processarItem(index + 1);
										console.log(this.progresso)
										if (this.progresso == 100) {
											this.goSuccess(this.sincronizedSupplier.mensagemRetorno);
											location.reload();
										}
									}, 1000);
								};

								processarItem(0);

								for (let i = 0; i < this.sincronizedSupplier.fornecedor.length; i++) {
									const sell = this.sincronizedSupplier.fornecedor[i];
									this.downloadSuppliers(sell);
								}

							} else {
								this.goError(this.sincronizedSupplier.mensagemRetorno);
							}

						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async downloadSuppliers(suppliers) {
			console.log(suppliers)
			const formData = new FormData();

			formData.append(
				"apeFor",
				suppliers.apeFor ? suppliers.apeFor : "-"
			);
			formData.append(
				"cidFor",
				suppliers.cidFor ? suppliers.cidFor : "-"
			);

			formData.append(
				"codFor",
				suppliers.codFor ? suppliers.codFor : "-"
			);
			formData.append(
				"codPai",
				suppliers.codPai ? suppliers.codPai : "-"
			);
			formData.append(
				"nomFor",
				suppliers.nomFor ? suppliers.nomFor : "-"
			);

			formData.append(
				"nomPai",
				suppliers.nomPai ? suppliers.nomPai : "-"
			);

			formData.append(
				"sigUfs",
				suppliers.sigUfs ? suppliers.sigUfs : "-"
			);

			formData.append(
				"sitFor",
				suppliers.sitFor ? suppliers.sitFor : "-"
			);

			formData.append(
				"tipMer",
				suppliers.tipMer ? suppliers.tipMer : "-"
			);

			formData.append(
				"tipInt",
				suppliers.tipInt ? suppliers.tipInt : "-"
			);
			formData.append(
				"tipFor",
				suppliers.tipFor ? suppliers.tipFor : "-"
			);
			formData.append(
				"intNet",
				suppliers.intNet ? suppliers.intNet : "-"
			);
			formData.append(
				"fonFor",
				suppliers.fonFor ? suppliers.fonFor : "-"
			);
			formData.append(
				"endFor",
				suppliers.endFor ? suppliers.endFor : "-"
			);
			formData.append(
				"emaNfe",
				suppliers.emaNfe ? suppliers.emaNfe : "-"
			);
			formData.append(
				"cidFor",
				suppliers.cidFor ? suppliers.cidFor : "-"
			);
			formData.append(
				"cgcCpf",
				suppliers.cgcCpf ? suppliers.cgcCpf : "-"
			);
			formData.append(
				"cepFor",
				suppliers.cepFor ? suppliers.cepFor : "-"
			);
			formData.append(
				"baiFor",
				suppliers.baiFor ? suppliers.baiFor : "-"
			);
			formData.append("import_id", this.importId ? this.importId : "-");

			this.$http
				.post(`/suppliers/download`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						// this.teste = response;
						console.log(response.data);
						// this.$refs["my-modal"].hide();
						// this.getSuppliers();
						// setTimeout(this.getSuppliers(), 5000);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async filterSupplier() {
			const formData = new FormData();
			formData.append("cod_supplier", this.cod_supplier ? this.cod_supplier : '');
			formData.append("cnpj_supplier", this.cnpj_supplier ? this.cnpj_supplier : '');
			formData.append("activity_supplier", this.activities_supplier ? this.activities_supplier : '');
			formData.append("origem_supplier", this.origem_supplier ? this.origem_supplier : '');
			formData.append("locality", this.locality ? this.locality : '');
			formData.append("point_origin", this.point_origin ? this.point_origin : '');
			formData.append("order", this.selectedSort);

			this.$http
				.post(`/suppliers/filters`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response);
					} else {
						console.log(response.data);
						this.goSuccess(response.data.message);
						this.suppliers = response.data.suppliers;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		clearFilter() {
			this.cod_supplier = "";
			this.cnpj_supplier = "";
			this.activities_supplier = "";
			this.origem_supplier = "";
			this.locality = "";
			this.point_origin = "";
			this.$refs["filters"].hide();
			this.getSuppliers();
		}

	},

	computed: {
		filteredList() {
			return this.suppliers.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
		fieldsProduct: function () {
			let fields = [
				{ key: "code", label: "Código do Fornecedor" },

				{
					key: "document",
					label: "CNPJ",
				},

				{
					key: "name",
					label: "Nome Fantasia",
				},

				{
					key: "line_business",
					label: "Ramo de Atividade",
				},

				{ key: "view", label: "" },
			];
			return fields;
		},

		fields: function () {
			let fields = [
				{
					key: "name",
					label: "Nome",
				},
				{ key: "sort", label: "Ordem" },
				{ key: "observation", label: "Observação" },
				{ key: "action", label: "" },
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.suppliers.slice(start, end);
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.suppliers.filter(item => item.name.toLowerCase().includes(this.search.toLowerCase()) || item.document.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
