<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Listagem de Dólar</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row>
							<b-col md="7">
								<h5>Dólar</h5>
							</b-col>

							<b-col md="" class="filter-select">
								<b-form-select v-model="filterSelect" @change="filter()" size="sm" class="mt-3">
									<b-form-select-option :value="null">Filtrar por</b-form-select-option>
									<b-form-select-option :value="'desc'">Mais recente</b-form-select-option>
									<b-form-select-option :value="'asc'">Mais Antigos</b-form-select-option>
								</b-form-select>
							</b-col>
							<b-col md="" class="col-add-dolar" v-if="userLoggedPermission.permissiondolarm == 1 || userLogged.role == 'Administrador'">
								<b-button class="add-fator" :to="{ name: 'dolar-create' }">
									<font-awesome-icon :icon="['fas', 'plus']" class="" />
									Nova taxa de dólar
								</b-button>
							</b-col>

							<b-col md="12" class="col-search">
								<div class="form">
									<b-form-input placeholder="Pesquisar" v-model="search">
									</b-form-input>
									<button type="submit">Search</button>
								</div>
							</b-col>

							<div class="col-md-12 card">
								<b-table :items="itemsFiltradosPaginados" :fields="fieldsProduct" responsive="" class="table-factors">
									<template #cell(tax)="data">
										R$ {{ data.item.tax }}
									</template>

									<template #cell(user)="data">
										{{ data.item.user_name }}
									</template>

									<template #cell(date_initial)="data">
										{{ data.item.date_initial | dateFormat }}
									</template>

									<template #cell(date_final)="data">
										{{ data.item.date_final | dateFormat }}
									</template>

									<template #cell(date_register)="data">
										{{ data.item.created_ate | dateFormat }}
									</template>

									<template #cell(automatically_changed)="">
										Sim
									</template>

									<template #cell(actions)="data">
										<b-button @click="editDolar(data.item)" style="text-decoration: none; color: black" v-if="userLoggedPermission.permissiondolarm == 1 || userLogged.role == 'Administrador'">
											<h6><font-awesome-icon :icon="['fas', 'edit']" /></h6>
										</b-button>

										<b-button @click="removeDolar(data.item)" style="text-decoration: none; color: black" v-if="userLoggedPermission.permissiondolarm == 1 || userLogged.role == 'Administrador'">
											<h6><font-awesome-icon :icon="['fas', 'trash-alt']" /></h6>
										</b-button>
									</template>
								</b-table>

								<p class="line"></p>
							</div>

							<b-col md="1"></b-col>

							<b-col md="6"></b-col>
						</b-row>

						<b-row class="paginations-options1 mt-3">
							<b-col md="12">
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>
							</b-col>

						</b-row>

					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";

export default {
	name: "DolarIndexComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
			dolars: [],
			checkbox: true,
			perPage: 6,
			currentPage: 1,
			search: '',
			filterSelect: null,
			next: '',
			previous: '',
			optionSelect: null,
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
			paginaAtual: 1,
			itensPorPagina: 5,
		};
	},

	mounted() {
		this.getDolar();
	},

	methods: {
		toggleCheckbox() {
			this.checkbox = !this.checkbox;
			this.$emit("setCheckboxVal", this.checkbox);
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getDolar() {
			await this.$http
				.get(`/dolar`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.dolars = response.data.dolars;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async filter() {
			let formData = new FormData();
			formData.append("order", this.filterSelect);
			await this.$http
				.post(`/dolar/filter`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.dolars = response.data.dolars;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		editDolar(factor) {
			this.$router.push({
				name: "dolar-create",
				params: {
					dataObj: { factor },
				},
			});
		},

		async getNextPrevious(item) {
			let formData = new FormData();
			formData.append("id", item.id);
			await this.$http
				.post(`/dolar/next_previous`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.next = response.data.next;
						this.previous = response.data.previous;
						this.removeDolar(item)

					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});
		},

		async removeDolar(item) {
			let formData = new FormData();
			formData.append("id", item.id);
			// let next = this.next ? moment(this.next.created_at).format('DD/MM/YYYY') + " - R$ " + this.next.tax : 'Não possui taxa'
			// let previous = this.previous ? moment(this.previous.created_at).format('DD/MM/YYYY') + " - R$ " + this.previous.tax : 'Não possui taxa'

			// let id_next = this.next ? this.next.tax : null
			// let id_previous = this.previous ? this.previous.tax : null
			await this.$swal
				.fire({
					title: "Confirmar exclusão de taxa?",
					html: "Você está excluindo a taxa de dólar de " + item.tax + " do período de " +
						moment(item.date_initial).format('DD/MM/YYYY') + " à " + moment(item.date_final).format('DD/MM/YYYY'),

					// html: 'Você está excluindo a taxa de dólar de ' + item.tax + ' do período de ' +  moment(item.date_initial).format('DD/MM/YYYY') + " à " + moment(item.date_final).format('DD/MM/YYYY')
					// +  '</br> </br> <strong>É necessário substituir a taxa atual: </strong> </br> </br>' +
					// '<span> <input type="radio" v-model="optionSelect"  value="' +id_previous+ '"/>  '+ previous +' <span/> ' + "  "+ 
					// ' <span> <input type="radio" v-model="optionSelect" value="' +id_next+ '" /> '+ next +'  </span>',
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "SIM, EXCLUIR",
					cancelButtonText: `CANCELAR`,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#d33",
					customClass: {
						cancelButton:
							"btn btn-light border border-secondary rounded-pill px-md-5",
						confirmButton: "btn btn-danger rounded-pill px-md-5",
					},
					buttonsStyling: false,
				})
				.then((result) => {
					if (result.isConfirmed) {
						console.log(this.optionSelect)
						this.$http
							.post(`/dolar/delete`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.goSuccess(response.data.message);
									this.getDolar();
								}
							})
							.catch((error) => {
								this.goError(error.response.data.message);
								console.log(error.response.data.message);
							});
					}
				});
		},
	},

	computed: {
		fieldsProduct: function () {
			let fields = [
				{
					key: "date_initial",
					label: "Data inícial",
				},

				{
					key: "date_final",
					label: "Data final",
				},

				{
					key: "tax",
					label: "Taxa",
				},

				{
					key: "user",
					label: "Usuário",
				},

				{
					key: "date_register",
					label: "Data de cadastro",
				},

				{
					key: "automatically_changed",
					label: "Alterado automático",
				},

				{
					key: "actions",
					label: "",
				},
			];
			return fields;
		},

		paginatedCards() {
			const { currentPage, perPage } = this;
			const start = (currentPage - 1) * perPage;
			const end = currentPage * perPage;

			return this.dolars.slice(start, end);
		},

		filteredList() {
			return this.dolars.filter((dolar) => {
				return dolar.user_name.toLowerCase().includes(this.search.toLowerCase());
			});
		},

		totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.dolars.filter(item => item.user_name.toLowerCase().includes(this.search.toLowerCase()) || item.tax.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
	},

	filters: {
		dateFormat: function (date) {
			return moment(date).format("DD.MM.YYYY");
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
