<template>
	<div class="main">
		<PageComponent :title_nav="'S&OP'">
			<div slot="slot-pages" class="content-pages">
				<div class="products">
					<div class="products-header">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Início
							</b-breadcrumb-item>
							<b-breadcrumb-item active>Configurações S&OP</b-breadcrumb-item>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<b-row>
							<b-col md="6">
								<h5>Configurações S&OP</h5>
								<p> Defina a ordem das colunas a serem exibidas na S&OP </p>
							</b-col>
								<b-col md="6" class="btn-salve">
									<b-button class="save button-padrao-add" @click="saveParameters" v-if="userLoggedPermission.permissionseopconfigm == 1 || userLogged.role == 'Administrador'">Salvar</b-button>
									<b-button class="button-padrao-cancel" :to="{ name: 'dashboard' }">cancelar</b-button>
								</b-col>
							<b-col md="12"></b-col>

							<div class="col-md-6">
								<b-row class="tbl-lead">
									<b-col md="5">Nome da Coluna</b-col>
									<b-col md="5"></b-col>
								</b-row>
								<!-- {{ parameters }} -->
								<draggable class="list-group" :list="parameters" tag="ul" v-model="parameters" v-bind="dragOptions"
									@start="isDragging = true" @end="isDragging = false">
									<transition-group type="transition" name="flip-list">
										<li class="list-group-item" v-for="(element, idx) in parameters" :key="element.id"
											@click="getShow(idx)" :style="element.visible == 1 ? 'color:gray' : ''">
											<i :class="element.fixed ? 'fa fa-anchor' : 'glyphicon glyphicon-pushpin'
												" @click="element.fixed = !element.fixed" aria-hidden="true"></i>
											<font-awesome-icon :icon="['fas', 'bars']" class="" />

											<!-- <span> {{ element.name }}</span> -->

											<label for="">
												<span v-if="editingIndex !== idx">{{ element.name
												}}</span>
												<b-form-input v-else v-model="element.name" style="margin-left: 10px;"></b-form-input>
											</label>

											<b-button class="order-rmv" v-if="editingIndex !== idx && userLogged.role == 'Administrador' || userLoggedPermission.permissionseopconfigm == 1" @click="editParams(element, idx)"
												style="margin-left: 5px;" >
												<font-awesome-icon :icon="['fas', 'edit']" class="btn-remove" />
											</b-button>

											<b-button class="btn-check-circle order-rmv" @click="saveItem(element, idx)"
												style="margin-left: 5px;" v-else-if="editingIndex == idx">
												<h6><font-awesome-icon :icon="['fas', 'check-circle']" /></h6>
											</b-button>

											<b-button class="btn-circle order-rmv" @click="cancelEdit()" style="margin-left: 5px;"
												v-if="editingIndex === idx && userLogged.role == 'Administrador' || userLoggedPermission.permissionseopconfigm == 1">
												<h6><font-awesome-icon :icon="['fas', 'times-circle']" /></h6>
											</b-button>

											<b-button class="order-rmv" @click="changeVisible(element)" v-if="userLoggedPermission.permissionseopconfigm == 1 || userLogged.role == 'Administrador'">
												<font-awesome-icon v-if="element.visible == 1" :icon="['fas', 'eye-slash']" class="btn-remove" />

												<font-awesome-icon v-else :icon="['fas', 'eye']" class="btn-remove" />

											</b-button>
										</li>
									</transition-group>
								</draggable>
								<p class="line"></p>
							</div>

							<b-col md="1"></b-col>
							<b-col md="" class="list-parameters">
								<p class="order">Ordem</p>
								<p v-for="(item) in parameters" :key="item.order">
									<span :class="{ 'marcado': item.visible == 1 }" > {{ item.name }} </span> 
								</p>
							</b-col>

							<b-col md="6"></b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>
	</div>
</template>

<script>
import PageComponent from "../page/PageComponent.vue";
import draggable from "vuedraggable";

export default {
	display: "Transition",
	name: "ParametersComponent",
	components: {
		PageComponent,
		draggable,
	},
	data() {
		return {
			parameters: [],
			items_ids: [],
			items_order: [],
			show: true,
			backgroundRed: null,
			editingIndex: -1,
			disabled: false,
			userLoggedPermission: localStorage.getItem("userPermission")
            ? JSON.parse(localStorage.getItem("userPermission"))
            : null, 
			userLogged: localStorage.getItem("userLogged")
				? JSON.parse(localStorage.getItem("userLogged"))
				: null,
		};
	},

	mounted() {
		this.getParameters();
	},

	methods: {
		editItem(index) {
			this.editingIndex = index;
		},

		cancelEdit() {
			this.editingIndex = -1;
			this.disabled = false;
		},

		getShow(id) {
			this.backgroundRed = id
		},
		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		async getParameters() {
			await this.$http
				.get(`/parameters`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.parameters = response.data.parameters;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		async saveParameters() {
			const formData = new FormData();
			let oldIndex;
			let newIndex;
			var item = this.parameters;

			item.splice(newIndex, 0, item.splice(oldIndex, 1)[0]);
			//atualizar a propriedade da ordem com base na posição na matriz
			item.forEach(function (item, index) {
				item.order = index;
			});


			var items_order = item.map(function (item) {
				return item.order;
			});

			var items_ids = item.map(function (item) {
				return item.id;
			});

			var items_visible = item.map(function (item) {
				return item.visible;
			});



			console.log(items_visible)
			formData.append("order", items_order);
			formData.append("ids", items_ids);
			formData.append("visible", items_visible);

			await this.$swal
				.fire({
					text:
						"Esta alteração modificará as colunas da tela de S&OP para todos os usuários",
					title: "Deseja continuar?",
					icon: "warning",
					showCancelButton: true,
					confirmButtonText: "SIM, CONTINUAR",
					cancelButtonText: `CANCELAR`,
					confirmButtonColor: "#d33",
					cancelButtonColor: "#d33",
					customClass: {
						cancelButton:
							"btn btn-light border border-secondary rounded-pill px-md-5",
						confirmButton: "btn btn-success rounded-pill px-md-5",
					},
					buttonsStyling: false,
				})
				.then((result) => {
					if (result.isConfirmed) {
						this.$http
							.post(`/parameters/update/order`, formData)
							.then((response) => {
								if (response.data.error) {
									this.goError(response.data.message);
								} else {
									this.goSuccess(response.data.message);
									location.reload();
								}
							})
							.catch((error) => {
								this.goError(error.response.data.message);
								console.log(error.response.data.message);
							});
					}
				});
		},

		changeVisible(element) {
			console.log(element)
			element.visible = !element.visible

			if (element.visible == 1) {
				element.visible = 1
			} else {
				element.visible = 0
			}
			console.log(element.visible)
		},

		async editParams(item, index) {
			this.editingIndex = index;
			this.disabled = true;

			// this.editingIndex = -1;
			// this.disabled = false;

			// alert("s")
		},

		async saveItem(item) {
			this.editingIndex = -1;

			this.disabled = false;

			console.log(item)
			let formData = new FormData();

			formData.append("name", item.name);
			formData.append("id", item.id);
			await this.$http
				.post(`/parameters/update`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						location.reload();
					}
				})
				.catch((error) => {
					this.goError(error.response.data.message);
					console.log(error.response.data.message);
				});

		},

	},

	computed: {
		dragOptions() {
			return {
				animation: 0,
				group: "description",
				disabled: false,
				ghostClass: "ghost"
			};
		},

	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
