<template>
  <div class="main">
    <PageComponent>
      <div slot="slot-pages" class="content-pages">
        <div class="dashboard">
          <div class="dashboard-header">
             <TabComponent :stock="stock" :sell="sell">
             </TabComponent>

          </div>
        </div>
      </div>
    </PageComponent>
  </div>
</template>

<script>
import PageComponent from "../page/PageComponent";
import TabComponent from "../../components/tabs/TabComponent.vue";
import StockComponentVue from './stock/StockComponent.vue';
import SellComponent from './sell/SellComponent.vue';

export default {
  name: "DashboardComponent",
  components: {
    PageComponent,
    TabComponent,
  },
  data() {
    return {
      stock: StockComponentVue,
      sell: SellComponent,
    };
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
