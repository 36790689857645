<template>
  <div class="main">
    <PageComponent :title_nav="'Integrações'">
      <div slot="slot-pages" class="content-pages">
        <div class="products">
          <div class="products-header">
            <!-- breadcrumb -->
            <b-breadcrumb>
              <b-breadcrumb-item :to="{ name: 'batch_processes-index' }">
                <font-awesome-icon :icon="['fas', 'arrow-left']" />
                Listagem de Processos
              </b-breadcrumb-item>
              <b-breadcrumb-item active>Processos em lote - Vendas</b-breadcrumb-item>
            </b-breadcrumb>
            <!-- breadcrumb -->
            <b-row>
              <b-col md="9">
                <h5 class="import">Importar processo - Vendas</h5>
              </b-col>
              <!-- {{ lists }} -->
							<b-col md="8"></b-col>
              <b-col md="4" class="col-search">
                  <label class="position-relative d-block">
                    <b-icon icon="search" class="position-absolute h-100 text-muted" style="margin-left:15px;"></b-icon>
                    <b-form-input placeholder="Buscar por Código do produto.." v-model="search"></b-form-input>
                  </label>
              </b-col>

              <div class="col-md-12 card">
                <table class="table-import table" :per-page="perPage" :current-page="currentPage">
                  <!-- Table headers -->
                  <thead>
                    <tr>
                      <th>Código do produto</th>
                      <th>Derivação</th>
                      <th>Número Pedido</th>
                      <th>Status</th>
                      <th>Quantidade</th>
                      <th>Valor Líquido</th>
                      <th>Data Emissão</th>
                      <th></th>
                      <th></th>
                    </tr>
                  </thead>

                  <!-- Table rows -->
                  <tbody>
                    <tr v-for="(item) in itemsFiltradosPaginados" :key="item.id">
                      <td>
                        {{ item.codPro }}
                      </td>
                      <td>{{ item.codDer }}</td>
                      <td>{{ item.numPed }}</td>
                      <td>{{ item.staPed }}</td>
                      <td>{{ item.qtdPed }}</td>
                      <td>{{ item.vlrLnf | formatValue }}</td>
                      <td> {{ item.datEmi }} </td>
                    </tr>
                  </tbody>
                </table>

                <p class="line"></p>
              </div>

            </b-row>

            <b-row class="paginations-options1 mt-4">
              <!-- <b-col md="6">
                <span class="mt-3">Resultados {{ currentPage }} de {{ numeroDePaginas }}</span>
              </b-col> -->
              <b-col md="12">
                <!-- <b-pagination pills v-model="currentPage" :total-rows="totalRows" :per-page="perPage">
                </b-pagination> -->
								<b-pagination v-model="paginaAtual" :total-rows="totalItensFiltrados" :per-page="itensPorPagina" @change="paginaMudada" style="float: right;"></b-pagination>

              </b-col>
            </b-row>

            <b-row>
              <b-col md="2" class="date_import">
                <label for="">Data importação</label>
                <input type="text" v-model="date_import" disabled />
              </b-col>

              <b-col md="2" class="date_import">
                <label for="">Usuário</label>
                <input type="text" v-model="user_import" disabled />
              </b-col>

              <b-col md="12"></b-col>

              <b-col md="2" class="date_import">
                <label for="">Data aprovação</label>
                <b-form-datepicker id="example-datepicker" v-model="date_aproved" class="mb-2" @input="changeDate()"
                  :date-format-options="{ year: 'numeric', month: 'numeric', day: 'numeric' }"
                  placeholder=""></b-form-datepicker>

              </b-col>

              <b-col md="2" class="date_import">
                <label for="">Usuário</label>
                <input type="text" v-model="user_aproved" disabled />
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6"></b-col>
              <b-col md="6" class="btn-salve">
                <b-button class="button-padrao-cancel" :to="{ name: 'batch_processes-index' }">cancelar</b-button>

                <b-button class="save button-padrao-add" @click="saveStock()">salvar</b-button>
              </b-col>
            </b-row>
          </div>
        </div>
      </div>
    </PageComponent>
  </div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";
import moment from "moment";

export default {
  name: "ImportSellComponent",
  components: {
    PageComponent,
  },
  data() {
    return {
      userLogged: localStorage.getItem("userLogged")
        ? JSON.parse(localStorage.getItem("userLogged"))
        : null,
      perPage: 10,
      currentPage: 1,
      search: "",
      processItems: [],

      leadTimes: [],
      disabled: false,
      items: [
        { id: 1, text: "Item 1" },
        { id: 2, text: "Item 2" },
        // ...
      ],
      editingIndex: -1,
      data: 0,
      campos: ["Código do produto", "Nome", ""],
      currentDate: new Date(),
      date_import: "",
      user_import: "",
      user_aproved: "",
      date_aproved: "",
      obj: this.$route.id,
      saveLead: [],
      paginaAtual: 1,
			itensPorPagina: 5,
    };
  },

  mounted() {
    this.getProcessItems();
  },

  methods: {
    changeDate() {
      this.user_aproved = this.userLogged.name
    },

    goSuccess(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: false,
        timer: 4000,
        icon: "success",
        title: "Sucesso!",
        text: message ? message : "Cadastro Atualizado.",
      });
    },

    goError(message) {
      this.$swal({
        toast: true,
        position: "center",
        showConfirmButton: true,
        icon: "error",
        title: "Atenção!",
        text: message ? message : "Já existe um usuário com este e-mail.",
      });
    },

    async getProcessItems() {
      await this.$http
        .get(`/processes/sell/${this.$route.params.id}/items`)
        .then((response) => {
          if (response.data.error) {
            this.goError(response.data.message);
          } else {
            this.processItems = response.data.processItems;
            this.date_import = moment(this.processItems[0].dt_import).format(
              "DD/MM/YYYY HH:mm"
            );
            let date_aproved = this.processItems[0].dt_approved;
            if (date_aproved != '') {
              this.user_aproved = this.processItems[0].name_user
              this.date_aproved = moment(this.processItems[0].dt_approved).format(
                "YYYY-MM-DD"
              );
            }

            this.user_import = this.processItems[0].name_user
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },
    async saveStock() {
      const formData = new FormData();
      let date = this.date_aproved + ' ' + moment().format("HH:mm")
      console.log(date)

      formData.append("useImp", this.userLogged.id ? this.userLogged.id : "");
      formData.append("datAppr", this.date_aproved ? moment(date).format("YYYY-MM-DD HH:mm") : "");
      formData.append("importId", this.$route.params.id ? this.$route.params.id : "");

      await this.$http
        .post(`/processes/update/date_aproved`, formData)
        .then((response) => {
          if (response.data.error) {
            this.goError(response);
          } else {
            this.goSuccess("Processo de Estoque Aprovado!");
          }
        })
        .catch(function (error) {
          console.log(error);
        });
    },

  },

  computed: {
    fieldsImportProcess: function () {
      let fields = [
        {
          key: "code_product",
          label: "Código do produto",
        },

        {
          key: "derivation",
          label: "Derivação",
        },

        {
          key: "staPed",
          label: "Status",
        },
      ];
      return fields;
    },
    lists() {
      const items = this.processItems;
      const startIndex = (this.currentPage - 1) * this.perPage;
      const endIndex = startIndex + this.perPage;
      let array = items.slice(startIndex, endIndex);

      return array.filter((product) => {
        const codeMatch = product.codPro.includes(this.search);
        return codeMatch;
      });
    },
    totalRows() {
      return this.processItems.length;
    },
    paginatedCards() {
      const { currentPage, perPage } = this;
      const start = (currentPage - 1) * perPage;
      const end = currentPage * perPage;

      return this.processItems.slice(start, end);
    },

    numeroDePaginas() {
      return Math.ceil(this.processItems.length / this.perPage);
    },

    totalItensFiltrados() {
			return this.itemsFiltrados.length;
		},
		itemsFiltrados() {
		// Filtrar a lista de itens com base no texto inserido no campo de filtro
			return this.processItems.filter(item => item.codPro.toLowerCase().includes(this.search.toLowerCase()));
		},
		itemsFiltradosPaginados() {
			const inicio = (this.paginaAtual - 1) * this.itensPorPagina;
			const fim = inicio + this.itensPorPagina;
			return this.itemsFiltrados.slice(inicio, fim);
		},
  },

  filters: {
    dateFormat: function (date) {
      return moment(date).format("DD.MM.YYYY HH:mm");
    },

    formatValue: function (value) {
      const formatter = new Intl.NumberFormat('pt-BR', {
        style: 'currency',
        currency: 'BRL'
      });
      return formatter.format(value);
    }
  },
};
</script>

<style lang="scss" src="./style.scss" scoped />
