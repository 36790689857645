<template>
	<div class="main">
		<PageComponent :title_nav="'Cadastros'">
			<div slot="slot-pages" class="content-pages">
				<div class="users">
					<div class="users-header create-stock">
						<!-- breadcrumb -->
						<b-breadcrumb>
							<b-breadcrumb-item :to="{ name: 'dashboard' }">
								<font-awesome-icon :icon="['fas', 'arrow-left']" />
								Cadastros
							</b-breadcrumb-item>
							<b-breadcrumb-item :to="{ name: 'stock-totalizer-index' }"
								>Totalizador de estoque</b-breadcrumb-item
							>
							<b-breadcrumb-item active>
								Novo totalizador de estoque</b-breadcrumb-item
							>
						</b-breadcrumb>
						<!-- breadcrumb -->

						<h3>Novo totalizador de estoque</h3>
						<b-card class="card-add-name">
							<b-row>
								<b-col md="1">
									<label for="">Nome</label>
								</b-col>

								<b-col md="11">
									<b-form-input
										placeholder="Digite aqui"
										v-model="dataObj.name"
									></b-form-input>
								</b-col>
							</b-row>
						</b-card>

						<b-row>
							<b-col md="5">
								<b-card class="card-stocks">
									<p>Todos Agrupadores</p>
									<div class="form">
										<b-form-input
											placeholder="Procurar"
											v-model="searchAdd"
										></b-form-input>
										<button type="submit">Search</button>
									</div>

									<div class="list-stocks">
										<ul class="topics">
											<li v-for="item in filteredListStock" :key="item.id">
												<!-- <font-awesome-icon :icon="['fas', 'layer-group']" @click="viewStock(item)"/> -->
												<input
													type="checkbox"
													:id="item.id"
													:value="item"
													v-model="selected"
												/>
												<label :for="item.id">
													<b-button @click="viewStock(item)">
														<font-awesome-icon :icon="['fas', 'eye']" />
													</b-button>
													{{ item.name }}</label>
											</li>
										</ul>
									</div>
								</b-card>
							</b-col>

							<b-col md="2" class="btn-actions">
								<div>
									<b-button @click="addAll" :disabled="isDisableAll">
										<font-awesome-icon :icon="['fas', 'angle-double-right']" />
									</b-button>
								</div>

								<div>
									<b-button @click="addOne" :disabled="isDisableOne">
										<font-awesome-icon :icon="['fas', 'angle-right']" />
									</b-button>
								</div>

								<div>
									<b-button @click="toGoBackOne">
										<font-awesome-icon :icon="['fas', 'angle-left']" />
									</b-button>
								</div>

								<div>
									<b-button @click="toGoBackAll">
										<font-awesome-icon :icon="['fas', 'angle-double-left']" />
									</b-button>
								</div>
							</b-col>

							<b-col md="5">
								<b-card class="card-stocks">
									<p>Agrupados</p>
									<div class="form">
										<b-form-input placeholder="Procurar" v-model="search"></b-form-input>
										<button type="submit">Search</button>
									</div>
									<small class="text-muted" v-if="this.newStock == ''"
										>Selecione os agrupadores que deseja totalizar.</small
									>

									<div class="list-stocks">
										<ul class="topics">
											<li v-for="item in filteredList" :key="item.id">
												
												<input
													type="checkbox"
													:id="item.id"
													:value="item"
													v-model="selected"
												/>
												<label :for="item.id">
													<b-button @click="viewStock(item)">
														<font-awesome-icon :icon="['fas', 'eye']" />
													</b-button> {{ item.name }}</label>
											</li>
										</ul>
									</div>
								</b-card>
							</b-col>

							<b-col md="6"></b-col>
							<b-col md="6" class="btn-salve">
								<b-button
									class="save button-padrao-add"
									@click="saveStock"
									v-if="!this.dataObj.id"
									>salvar</b-button
								>
								<b-button class="save button-padrao-add" @click="updateStock" v-else
									>Atualizar</b-button
								>
								<br />
								<b-button
									class="button-padrao-cancel"
									:to="{ name: 'stock-collator-index' }"
									>cancelar</b-button
								>
							</b-col>
						</b-row>
					</div>
				</div>
			</div>
		</PageComponent>

		<b-modal
			id="modalInfo"
			content-class="your-class"
			ref="my-modal"
			hide-footer
			title=""
		>
			<template #modal-header="{ close }">
				<!-- Emulate built in modal header close button action -->
				<b-button size="sm" @click="close()">
					<font-awesome-icon :icon="['fas', 'times']" />
				</b-button>
			</template>

			<div class="d-block text-left">
				<h3>{{ selectedStock.name }}</h3>
			</div>

			<div class="d-block text-center">
				<b-table
					:items="stocks"
					:fields="fields"
					responsive=""
					class="table-stocks"
				>
					<template #cell(name)="data">
						<p>
							{{ data.item.name }}
						</p>
					</template>
					<template #cell(code)="data">
						<p>
							{{ data.item.code }}
						</p>
					</template>
				</b-table>
			</div>
			<div class="d-block text-center">
				<b-button class="button-padrao-cancel" size="sm" @click="hideModal"
					>Fechar</b-button
				>
			</div>
		</b-modal>
	</div>
</template>

<script>
import PageComponent from "../../page/PageComponent.vue";

export default {
	name: "StockTotalizerCreateComponent",
	components: {
		PageComponent,
	},
	data() {
		return {
			dataObj: {
				collator: [],
				name: "",
			},
			selected: [],
			newStock: [],
			isDisableAll: false,
			isDisableOne: false,
			stocks: [],
			searchAdd: "",
			search: "",
			stockCollators: [],
			selectedStock: "",
			obj: this.$route.params.dataObj,
		};
	},

	mounted() {
		this.getCollaters();
		this.editStock();
	},

	methods: {
		//TODOS OS AGRUPADORES DISPONIVEIS
		async getCollaters() {
			await this.$http
				.get(`/stockCollator`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.stockCollators = response.data.stock_collators;
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		goSuccess(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: false,
				timer: 4000,
				icon: "success",
				title: "Sucesso!",
				text: message ? message : "Cadastro Atualizado.",
			});
		},

		goError(message) {
			this.$swal({
				toast: true,
				position: "center",
				showConfirmButton: true,
				icon: "error",
				title: "Atenção!",
				text: message ? message : "Já existe um usuário com este e-mail.",
			});
		},

		// SALVAR AGRUPADOR DE ESTOQUE
		async saveStock() {
			console.log(this.dataObj);
			if (this.dataObj.name == "" || this.dataObj.collator == []) {
				this.goError("Preencha todos os campos");
			} else {
				let formData = new FormData();
				formData.append("name", this.dataObj.name);
				await this.$http
					.post(`/stockTotalizer/store`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							var totalizer_id = response.data.stock_totalizer.id;
							this.dataObj.collator.map((stock_collator) => {
								this.storeItems(stock_collator, totalizer_id);
							});
						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},

		async storeItems(stock_collator_id, totalizer) {
			let formData = new FormData();
			formData.append("stock_collator", stock_collator_id.id);
			formData.append("totalizer_id", totalizer);
			await this.$http
				.post(`/stockTotalizer/item/store`, formData)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						this.goSuccess(response.data.message);
						this.$router.push("/stock-totalizer");
						this.dataObj.collator = [];
						this.dataObj.name = "";
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		// editar dados do totalizador
		editStock() {
			this.dataObj = this.obj.dataObj;
			this.getStocksId(this.dataObj.id);
		},

		//atualizar totalizador
		async updateStock() {
			if (this.dataObj.name == "" || this.dataObj.collator == []) {
				this.goError("Preencha todos os campos");
			} else {
				let formData = new FormData();
				formData.append("name", this.dataObj.name);
				formData.append("id", this.dataObj.id);
				await this.$http
					.post(`/stockTotalizer/update`, formData)
					.then((response) => {
						if (response.data.error) {
							this.goError(response.data.message);
						} else {
							var totalizer_id = response.data.stock_totalizer.id;
							console.log(this.newStock)

							if(this.newStock != []){
								this.newStock.map((collator) => {
								this.storeItems(collator, totalizer_id);
								});
							}

						}
					})
					.catch(function (error) {
						console.log(error);
					});
			}
		},
		//traz lista de agrupadores
		async getStocksId(id) {
			await this.$http
				.get(`/stockTotalizer/stockCollator/${id}`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						response.data.stocks.forEach((a) => {
							this.newStock.push(a.stock_collator);
						});

						this.newStock.forEach((a) => {
							this.stockCollators = this.stockCollators.filter((item) => {
								return item.id !== a.id;
							});
						});
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},

		// SELECIONAR TODOS OS ESTOQUES
		addAll() {
			this.stockCollators.forEach((item) => {
				this.newStock.push(item);
			});
			this.stockCollators.forEach((a) => {
				this.stockCollators = this.stockCollators.filter((item) => {
					return item !== a;
				});
			});
			this.dataObj.collator = this.newStock;

			this.isDisableAll = true;
			this.isDisableOne = true;
		},

		// RETORNAR TODOS OS ESTOQUES
		toGoBackAll() {
			this.newStock.forEach((item) => {
				this.stockCollators.push(item);
			});
			this.newStock.forEach((a) => {
				this.newStock = this.newStock.filter((item) => {
					return item !== a;
				});
			});

			this.dataObj.collator = [];

			this.isDisableOne = false;
			this.isDisableAll = false;
		},

		// SELECIONAR UM ESTOQUE POR VEZ
		addOne() {
			console.log(this.selected);
			// var stockArray = this.selected;
			var stockObjc = this.selected;
			this.selected.map((file) => {
				this.newStock.push(file);
			});
			stockObjc.forEach((a) => {
				this.stockCollators = this.stockCollators.filter((item) => {
					return item !== a;
				});
			});

			this.dataObj.collator = this.newStock;

			this.selected = [];
		},

		// RETORNAR UM ESTOQUE POR VEZ
		toGoBackOne() {
			var stockObjc = this.selected;
			stockObjc.map((file) => {
				this.stockCollators.push(file);
			});

			stockObjc.forEach((a) => {
				this.newStock = this.newStock.filter((item) => {
					return item.id !== a.id;
				});
			});

			stockObjc.forEach((a) => {
				this.dataObj.collator = this.dataObj.collator.filter((item) => {
					return item.id !== a.id;
				});
			});

			this.isDisableOne = false;
			this.isDisableAll = false;
			this.selected = [];
		},

		//visualizar stocks dentro do agrupador
		viewStock(obj) {
			this.selectedStock = obj;
			this.showModal();
			this.getStocks(obj.id);
		},
		showModal() {
			this.$refs["my-modal"].show();
		},
		hideModal() {
			this.$refs["my-modal"].hide();
			this.stocks = [];
			this.selectedStock = "";
		},

		//visualizar stocks dentro do agrupador
		async getStocks(id) {
			await this.$http
				.get(`/stockCollator/${id}/stocks`)
				.then((response) => {
					if (response.data.error) {
						this.goError(response.data.message);
					} else {
						response.data.stocks.forEach((a) => {
							this.stocks.push(a.stock);
						});
						console.log(this.stocks);
					}
				})
				.catch(function (error) {
					console.log(error);
				});
		},
	},

	computed: {
		filteredList() {
			return this.newStock.filter((stock) => {
				return stock.name.toLowerCase().includes(this.search.toLowerCase());
			});
		},
		filteredListStock() {
			return this.stockCollators.filter((stock) => {
				return stock.name.toLowerCase().includes(this.searchAdd.toLowerCase());
			});
		},
		fields: function () {
			let fields = [
				{ key: "code", label: "Código" },

				{
					key: "name",
					label: "Nome",
				},
			];
			return fields;
		},
	},
};
</script>

<style lang="scss" src="./style.scss" scoped />
